import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { ComponentsModule } from '~app/components/components.module';

import { ComplianceComponent } from './compliance.component';
import { ComplianceRoutingModule } from './compliance-routing.module';

@NgModule({
  declarations: [
    ComplianceComponent,
  ],
  imports: [
    CommonModule,
    ComplianceRoutingModule,
    TranslateModule,
    ComponentsModule,
    CardModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    DropdownModule,
    ReactiveFormsModule,
  ],
})
export class ComplianceModule { }
