export const FILE_CATEGORIES = [
  {
    name: 'MARKET_REPORT',
    value: 'MARKET_REPORT',
  },
  {
    name: 'PRODUCT_INFO',
    value: 'PRODUCT_INFO',
  },
  {
    name: 'OTHER',
    value: 'OTHER',
  },
];

export const FILE_INFORMATION_TYPES = [
  {
    name: 'DFI',
    value: 'DFI',
  },
  {
    name: 'LEGAL',
    value: 'LEGAL',
  },
  {
    name: 'COMMERCIAL',
    value: 'COMMERCIAL',
  },
];
