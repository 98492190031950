import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Faqs } from '~app/models/faqs';
import { FaqsService } from '~app/services/faqs/faqs.service';

@Component({
  selector: 'gpta-manage-faqs-modal',
  templateUrl: './manage-faqs-modal.component.html',
  styleUrls: ['./manage-faqs-modal.component.scss'],
})
export class ManagFaqsModalComponent implements OnInit, OnChanges {
  @Input() visible = false;

  @Input() faq: Faqs | null = null;

  title = '';

  form!: FormGroup;

  isUpdate = false;

  loading = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() update = new EventEmitter<null>();

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private faqsService: FaqsService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      answer: ['', Validators.required],
      question: ['', [Validators.required]],
    });

    this.faqsService.needReloadTable$.subscribe((res) => {
      this.loading = false;
      if (res) {
        this.onClose();
      }
    });
  }

  ngOnChanges(): void {
    if (this.visible) {
      if (this.faq) {
        this.title = this.translateService.instant('ADMIN.FAQ.EDIT_TITLE');
        this.isUpdate = true;
        this.loadFormWithUserData();
      } else {
        this.title = this.translateService.instant('ADMIN.FAQ.ADD');
        this.isUpdate = false;
      }
    }
  }

  loadFormWithUserData() {
    if (this.faq) {
      this.form.controls['answer'].setValue(this.faq.answer);
      this.form.controls['question'].setValue(this.faq.question);
    }
  }

  onClose(): void {
    this.form.reset();
    this.isUpdate = false;
    if (this.isUpdate) this.update.emit();
    this.visibleChange.emit(false);
  }

  onCreate(): void {
    this.loading = true;
    const parsedFaq: any = {
      question: this.form.controls['question'].value,
      answer: this.form.controls['answer'].value,
    };

    this.faqsService.createFaq(parsedFaq);
  }

  onUpdate(): void {
    this.loading = true;
    const parsedFaq: any = {
      question: this.form.controls['question'].value,
      answer: this.form.controls['answer'].value,
    };
    // eslint-disable-next-line no-underscore-dangle
    this.faqsService.updateFaq(parsedFaq, this.faq?._id);
  }
}
