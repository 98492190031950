import {
  Component, EventEmitter, Input, OnChanges,
  Output, SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ComponentAction } from '~app/models/component-action';
import { createDoAction } from '~app/services/helpers/componentAction.helper';
import { TextOption } from '~models/text-option';

@Component({
  selector: 'gpta-chat-selector',
  templateUrl: './chat-selector.component.html',
  styleUrls: ['./chat-selector.component.scss'],
})
export class ChatSelectorComponent implements OnChanges {
  @Input() textOptions: TextOption[] | undefined;

  @Input() messageId: string | undefined;

  @Input() isDisabled = true;

  @Input() selectedInput: any;

  @Output() optionSelected = new EventEmitter();

  public form: FormGroup;

  _minSelections?: any;

  _maxSelections?: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedInput']) {
      if (!this.selectedInput) return;
      const selectedOptionsIndex = this.selectedInput.metadata.selectedOptions;
      if (!selectedOptionsIndex) return;
      const optionsSelected: string[] = this.textOptions
        ? selectedOptionsIndex.map((index: number) => (this.textOptions
          ? this.textOptions[index]?.text
          : undefined)).filter(Boolean)
        : [];
      this.form.controls['selection'].patchValue(optionsSelected);
    }
  }

  get minSelections(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._minSelections;
  }

  @Input() set minSelections(value: any) {
    // eslint-disable-next-line no-underscore-dangle
    this._minSelections = value;
    this.form.controls['selection']?.addValidators([Validators.minLength(value)]);
    this.form.controls['selection']?.updateValueAndValidity();
  }

  get maxSelections(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._maxSelections;
  }

  @Input() set maxSelections(value: any) {
    // eslint-disable-next-line no-underscore-dangle
    this._maxSelections = value;
    this.form.controls['selection']?.addValidators([Validators.maxLength(value)]);
    this.form.controls['selection']?.updateValueAndValidity();
  }

  constructor(
    private readonly formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      selection: [[], [Validators.required]],
    });
  }

  submitForm() {
    const selection = this.form.get('selection')?.value;
    if (!selection?.length) return;
    const selectedOptions = selection.map(
      (selectedOption: string) => this.textOptions?.findIndex((option) => option.text.trim() === selectedOption.trim()),
    );
    const optionText = selection.join(', ');
    const action: ComponentAction = createDoAction({
      name: 'optionSelected',
      data: {
        optionText,
        metaData: { selectedOptions },
      },
    });
    this.optionSelected.next(action);
  }
}
