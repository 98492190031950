import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Disclaimer, Disclaimers } from '~app/models/disclaimer';
import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  #url: string;

  disclaimerListSubject = new BehaviorSubject<any>(null);

  disclaimerList$ = this.disclaimerListSubject.asObservable();

  constructor(private http: HttpClient) {
    this.#url = environment.APP_API_URL;
  }

  public getComplianceList(lang: any): any {
    const params = { language: lang };
    this.http.get<Disclaimers>(`${this.#url}/api/disclaimers`, { params }).subscribe((res) => {
      this.nextdisclaimerList(res);
    });
  }

  public updateDisclaimer(disclaimerId: string, body: any): Observable<Disclaimer> {
    return this.http.patch<Disclaimer>(`${this.#url}/api/disclaimers/${disclaimerId}`, body);
  }

  nextdisclaimerList(response: Disclaimers) {
    this.disclaimerListSubject.next(response);
  }
}
