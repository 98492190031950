import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ChatLogListResponse, SearchChatLogDto } from '~app/models/chat-log';
import { environment } from '~environments/environment';

import { buildQuery } from '../helpers/query-builder.helper';

@Injectable({
  providedIn: 'root',
})
export class ChatLogService {
  url: string;

  chatLogListSubject = new BehaviorSubject<any>(null);

  chatLogs$ = this.chatLogListSubject.asObservable();

  constructor(private http: HttpClient) {
    this.url = environment.APP_API_URL;
  }

  nextChatLogList(response: any) {
    this.chatLogListSubject.next(response);
  }

  getChatLogList(searchChatLogDto: SearchChatLogDto) {
    const params: {
      [param: string]: string;
    } = buildQuery(searchChatLogDto);
    this.http.get<ChatLogListResponse>(`${this.url}/api/analytics/chatlog`, { params }).subscribe((res) => {
      this.nextChatLogList(res);
    });
  }

  exportDocument(searchString?: string) {
    const params: {
      [param: string]: string;
    } = buildQuery({ searchString });
    return this.http.get(`${this.url}/api/analytics/chatlog/download`, { params, responseType: 'blob' });
  }
}
