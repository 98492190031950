<div
  class="pdf-viewer-container"
  [ngClass]="{ 'is-mobile': (windowProperties | async)?.isMobile }">
  <div
    *ngIf="isLoaded"
    class="controls">
    <span class="source-name">{{ source.filename }}</span>
    <div class="page-controls">
      <!-- eslint-disable @angular-eslint/template/elements-content-->
      <div class="page-indicator">
        <span>{{ page }} / {{ totalPages }}</span>
      </div>
      <button
        pButton
        type="button"
        class="p-button-text p-button-rounded p-button-secondary"
        icon="pi pi-angle-left"
        (click)="prevPage()"
        [disabled]="page === 1"></button>
      <button
        pButton
        type="button"
        class="p-button-text p-button-rounded p-button-secondary"
        icon="pi pi-angle-right"
        (click)="nextPage()"
        [disabled]="page === totalPages"></button>
    </div>
    <button
      pButton
      type="button"
      class="p-button-text p-button-rounded p-button-secondary close-button"
      icon="pi pi-times"
      (click)="closePdfViewer()"></button>
  </div>

  <pdf-viewer
    class="pdf-viewer"
    [src]="src"
    [rotation]="0"
    [original-size]="true"
    [fit-to-page]="true"
    [(page)]="page"
    [zoom]="1"
    [zoom-scale]="'page-width'"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    style="width: auto"
    (text-layer-rendered)="goToPage($event)"
    (after-load-complete)="getLastPage($event)"></pdf-viewer>
</div>
