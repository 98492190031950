/* eslint-disable no-continue */
export function buildQuery(filter: any) {
  const params: { [param: string]: any } = {};

  if (!filter) return params;

  const keys = Object.keys(filter);
  const total = keys.length;

  for (let i = 0; i < total; i += 1) {
    const k = keys[i];
    const data = filter[k];

    if (!data) continue;

    if (data.constructor === Array) {
      const totalItems = data.length;
      for (let j = 0; j < totalItems; j += 1) {
        const paramName = `${k}[]`;
        if (!params[paramName]) {
          params[paramName] = [data[j]];
        } else {
          params[paramName].push(data[j]);
        }
      }

      continue;
    }

    params[k] = data;
  }

  return params;
}
