import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { notLoggedGuard } from '~guards/not-logged.guard';

import { SignupComponent } from './signup.component';

const routes: Routes = [
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [notLoggedGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SignupRoutingModule { }
