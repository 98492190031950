<div
  class="loading-container"
  [ngClass]="{ backdrop: options!.showBackdrop, 'fade-in': !options!.avoidFade }">
  <i
    class="spinner pi pi-spin pi-spinner"
    [style]="options!.style"></i>
  <span
    class="message"
    *ngIf="options?.message"
    >{{ options.message }}</span
  >
</div>
