<p-tabMenu
  class="chat-list-tabs"
  [model]="tabs"
  (activeItemChange)="tabChangeHandler($event)"
  [activeItem]="activeTab"></p-tabMenu>

<p-listbox
  class="chat-list"
  *ngIf="chatList"
  [filter]="true"
  optionLabel="title"
  [options]="groupedChatList"
  emptyFilterMessage="{{ 'CHAT.SIDE_BAR.CONVERSATIONS_EMPTY' | translate }}"
  emptyMessage="{{ 'CHAT.SIDE_BAR.CONVERSATIONS_EMPTY' | translate }}"
  [group]="true"
  [(ngModel)]="selectedChat"
  [listStyle]="{ 'max-height': '250px' }"
  [style]="{ border: '0px', width: '100%', height: '100%' }"
  (onChange)="changeHandler($event)">
  <ng-template
    let-group
    pTemplate="group">
    <div class="chat-group">
      <span>{{ group.isToday ? ("CHAT.SIDE_BAR.TODAY" | translate) : (group.value | date: "dd/MM/yy") }}</span>
    </div>
  </ng-template>
  <ng-template
    let-chat
    pTemplate="item">
    <div
      *ngIf="chat._id !== actionChat?._id || !isRenameChatModalVisible"
      class="chat-item">
      <svg-icon src="../../../../assets/images/chat_empty.svg"></svg-icon>
      <div class="chat-title">{{ chat.title || ("CHAT.TITLE_PLACEHOLDER" | translate) }}</div>
      <i
        class="fav-icon pi pi-star-fill"
        *ngIf="chat.isFavourite"></i>
      <gpta-chat-list-item-context-menu
        [chat]="chat"
        [isLoading]="chat.isLoading"
        (doAction)="contextActionHandler($event)"></gpta-chat-list-item-context-menu>
    </div>
    <div
      *ngIf="chat._id === actionChat?._id && isRenameChatModalVisible"
      class="chat-item">
      <svg-icon src="../../../../assets/images/chat_empty.svg"></svg-icon>
      <gpta-chat-rename-form
        [isLoading]="isRenameChatModalLoading"
        [chat]="actionChat"
        (doAction)="renameChatHandler($event)"></gpta-chat-rename-form>
    </div>
  </ng-template>
</p-listbox>
<div
  class="loading-container"
  *ngIf="!chatList">
  <gpta-loading [options]="{ style: 'font-size: 2rem' }"></gpta-loading>
</div>
