import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ManageUser, ManageUserResponse, SearchUsersDTO } from '~app/models/manage-users';
import { environment } from '~environments/environment';
import { User } from '~models/user';

import { ApiService } from '../api/api.service';
import { buildQuery } from '../helpers/query-builder.helper';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  url: string;

  userListSubject = new BehaviorSubject<ManageUserResponse | null>(null);

  needReloadTableSubject = new BehaviorSubject<boolean | null>(null);

  userList$ = this.userListSubject.asObservable();

  needReloadTable$ = this.needReloadTableSubject.asObservable();

  constructor(public http: HttpClient, private apiService: ApiService) {
    this.url = environment.APP_API_URL;
  }

  public getUserList(searchUserDto: SearchUsersDTO) {
    const params: {
      [param: string]: string;
    } = buildQuery(searchUserDto);
    this.http.get<User[]>(`${this.url}/api/users`, { params }).subscribe((res) => {
      this.nextUserList(res);
    });
  }

  public createUser(user: ManageUser) {
    this.http.post<ManageUser>(`${this.url}/api/users`, user).subscribe({
      next: () => this.nextCorrectManageUser(true),
      error: () => this.nextCorrectManageUser(false),
    });
  }

  public updateUser(user: ManageUser, id = '') {
    this.http.patch<User>(`${this.url}/api/users/${id}`, user).subscribe({
      next: () => this.nextCorrectManageUser(true),
      error: () => this.nextCorrectManageUser(false),
    });
  }

  public removeUser(userId: string) {
    this.http.delete<User>(`${this.url}/api/users/${userId}`).subscribe({
      next: () => this.correctRemoveUser(userId),
      error: () => this.nextCorrectManageUser(false),
    });
  }

  private async correctRemoveUser(userId: string) {
    const currentUserData = await this.apiService.getUserData();
    // eslint-disable-next-line no-underscore-dangle
    if (currentUserData._id === userId) {
      this.apiService.signOut();
      window.location.reload();
    } else {
      this.nextCorrectManageUser(true);
    }
  }

  nextUserList(response: any) {
    this.userListSubject.next(response);
  }

  nextCorrectManageUser(isChanges: boolean) {
    this.needReloadTableSubject.next(isChanges);
  }
}
