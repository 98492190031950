import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Categories } from '~app/models/category';
import { OrganizationProduct, OrganizationProductElement, SearchProductsDto } from '~app/models/products';
import { environment } from '~environments/environment';

import { buildQuery } from '../helpers/query-builder.helper';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  #url: string;

  productListSubject = new BehaviorSubject<any>(null);

  productList$ = this.productListSubject.asObservable();

  categoryListSubject = new BehaviorSubject<any>(null);

  categoryList$ = this.categoryListSubject.asObservable();

  constructor(private http: HttpClient) {
    this.#url = environment.APP_API_URL;
  }

  nextProductList(response: any) {
    this.productListSubject.next(response);
  }

  nextCategoryList(response: any) {
    this.categoryListSubject.next(response);
  }

  getProductList(searchProductsDto: SearchProductsDto) {
    const params: {
      [param: string]: string;
    } = buildQuery(searchProductsDto);
    this.http.get<OrganizationProduct>(`${this.#url}/api/products`, { params }).subscribe((res) => {
      this.nextProductList(res);
    });
  }

  patchProductById(productId: string, body: any): Observable<OrganizationProductElement> {
    return this.http.patch<OrganizationProductElement>(`${this.#url}/api/products/${productId}`, body);
  }

  getCategoryList() {
    this.http.get<Categories>(`${this.#url}/api/categories`).subscribe((res) => {
      this.nextCategoryList(res);
    });
  }
}
