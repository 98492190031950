<div class="attachment-container">
  <span class="attachment-title bold">{{ "CHAT.ATTACHMENTS.TITLE" | translate }}</span>
  <div
    class="attachment"
    *ngFor="let attachment of attachments">
    <div
      class="pdf"
      *ngIf="attachment.filePath || attachment.url">
      <!-- eslint-disable @angular-eslint/template/interactive-supports-focus-->
      <!-- eslint-disable @angular-eslint/template/click-events-have-key-events-->
      <span (click)="onCLickSource(attachment)">
        {{ attachment.name }} {{ attachment.name && attachment.filename ? " - " : "" }}
        {{ attachment.filename | truncateLength: 35 }}
        <ng-container *ngIf="attachment.filename?.length && attachment.filename.length > 35">.pdf</ng-container>
      </span>
    </div>
    <div
      class="faq"
      *ngIf="!attachment.filePath && !attachment.url && attachment.name">
      <span>{{ attachment.name }}</span>
    </div>
  </div>
</div>
