import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

import { ComponentAction } from '~app/models/component-action';
import { Suggestion } from '~app/models/suggestion';
import { createDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-chat-suggestions',
  templateUrl: './chat-suggestions.component.html',
  styleUrls: ['./chat-suggestions.component.scss'],
})
export class ChatSuggestionsComponent {
  @Input() suggestions: Suggestion[] | undefined;

  @Output() optionSelected = new EventEmitter();

  showSuggestions = false;

  toggleSuggestions(suggestionsPanel: any) {
    this.showSuggestions = !this.showSuggestions;
    suggestionsPanel.toggle(new MouseEvent('click'));
  }

  submitSuggestion(option: Suggestion) {
    const action: ComponentAction = createDoAction({
      name: 'optionSelected',
      data: {
        optionText: option.text,
        metaData: { selectedOption: option.id },
      },
    });
    this.optionSelected.next(action);
  }
}
