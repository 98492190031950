import { Message } from './message';

export enum CONVERSATION_DATE_FIELDS {
  CREATION_DATE = 'creationDate',
  MODIFICATION_DATE = 'modificationDate',
}
export interface Conversation {
  _id: string;
  userId: string;
  title: string;
  [CONVERSATION_DATE_FIELDS.CREATION_DATE]: string;
  [CONVERSATION_DATE_FIELDS.MODIFICATION_DATE]: string;
  messages: Message[];
  isFavourite?: boolean;
}
