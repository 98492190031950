import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { hasUserDataGuard } from '~app/services/guard/has-user-data.guard';
import { isAdminGuard } from '~app/services/guard/is-admin.guard';
import { isLoggedGuard } from '~app/services/guard/is-logged.guard';

import { FaqsComponent } from './faqs.component';

const routes: Routes = [
  {
    path: 'admin/faqs',
    component: FaqsComponent,
    canActivate: [isLoggedGuard, hasUserDataGuard, isAdminGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class FaqsRoutingModule { }
