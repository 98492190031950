/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  set(key: string, stringData: string, expirationDate?: Date) {
    let expiration = '';
    if (!expirationDate) {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      expiration = date.toUTCString();
    } else {
      expiration = new Date(expirationDate).toUTCString();
    }

    let secure = '';
    let domain = `domain=.${window.location.hostname};`;
    if (window.location.protocol.includes('s')) secure = 'SameSite=None;Secure;';
    if (window.location.href.includes('localhost')) domain = 'domain=localhost;';

    document.cookie = `${key}=${stringData};expires=${expiration};${secure}${domain}path=/;`.trim();
  }

  get(key: string) {
    const cookies = document.cookie.split(';');
    let cookieFound = null;
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      if (cookie.trim().indexOf(key) !== -1) {
        cookieFound = cookie.trim();
        break;
      }
    }
    return cookieFound;
  }

  remove(key: string) {
    const cookieFound = this.get(key);
    if (!cookieFound) return;
    this.set(key, '', new Date());
  }

  getValue(key: string) {
    const cookie = this.get(key);
    if (!cookie) return '';
    const startIndex = cookie.indexOf('=') + 1;
    if (startIndex === 0) return '';
    const endIndex = cookie.indexOf(';');
    if (endIndex === -1) return cookie.substring(startIndex);
    return cookie.substring(startIndex, endIndex);
  }

  sessionGet(key: string): unknown | null {
    const itemString = sessionStorage.getItem(key);
    if (!itemString) return null;
    if (itemString.includes('{') || itemString.includes('[')) return JSON.parse(itemString);
    return itemString;
  }

  sessionSet(key: string, value: any): void {
    const type = typeof value;
    let itemString = value;
    if (type === 'object') itemString = JSON.stringify(value);
    sessionStorage.setItem(key, itemString);
  }

  sessionRemove(key: string): void {
    sessionStorage.removeItem(key);
  }

  localGet(key: string): any | null {
    const itemString = localStorage.getItem(key);
    if (!itemString) return null;
    try {
      if (itemString.includes('{') || itemString.includes('[')) return JSON.parse(itemString);
    } catch (e) {
      return itemString;
    }

    return itemString;
  }

  localSet(key: string, value: any): void {
    const type = typeof value;
    let itemString = value;
    if (type === 'object') itemString = JSON.stringify(value);
    localStorage.setItem(key, itemString);
  }

  localRemove(key: string): void {
    localStorage.removeItem(key);
  }
}
