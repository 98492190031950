import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '~models/user';
import { ApiService } from '~services/api/api.service';

@Component({
  selector: 'gpta-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup;

  private authData: {
    email: string,
    password: string,
  } = {
      email: '',
      password: '',
    };

  private redirectTo: string | undefined;

  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    const { redirectTo, hash } = this.activatedRoute.snapshot.queryParams;
    this.redirectTo = redirectTo;
    if (hash) {
      this.loginByUrl(hash);
    }
  }

  public submitLoginForm(): void {
    this.authData = {
      email: this.form.get('email')?.value,
      password: this.form.get('password')?.value,
    };
    this.signinApiCall();
  }

  private loginByUrl(hash: string): void {
    const urlDecodedHash = decodeURIComponent(hash);
    const base64DecodedHash = atob(urlDecodedHash);
    const [user, password] = base64DecodedHash.split('&');
    this.authData = {
      email: user.split('=')[1],
      password: password.split('=')[1],
    };
    this.signinApiCall();
  }

  private signinApiCall(): void {
    this.apiService.signin(this.authData).subscribe({
      next: (response: User) => {
        if (response) {
          const redirectTo = this.redirectTo || '/chat';
          this.router.navigateByUrl(redirectTo);
        }
      },
      error: (error: any) => {
        // eslint-disable-next-line no-console
        console.error(error);
      },
    });
  }
}
