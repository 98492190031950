import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { take } from 'rxjs';

import { ApiService } from '~app/services/api/api.service';

@Component({
  selector: 'gpta-chat-sources',
  templateUrl: './chat-sources.component.html',
  styleUrls: ['./chat-sources.component.scss'],
})
export class ChatSourcesComponent {
  @Input() sources: any;

  @Output() pdfSource: EventEmitter<any> = new EventEmitter<any>();

  constructor(public apiService: ApiService) {}

  async onCLickSource(source: any): Promise<void> {
    const existSource = await this.checkSource(source);
    if (existSource) {
      this.pdfSource.emit(source);
    }
  }

  checkSource(source: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiService.getSource(source.filePath).pipe(take(1)).subscribe({
        next: () => resolve(true),
        error: (error) => {
          resolve(false);
          reject(error);
        },
      });
    });
  }
}
