<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.USERS.TITLE" | translate }}
    </ng-container>
    <ng-container side>
      <button
        pButton
        class="p-button-rounded p-button-sm large-btn"
        (click)="createUser()">
        {{ "ADMIN.USERS.ADD" | translate }}
      </button>
    </ng-container>
    <ng-container content>
      <p-card class="table-height">
        <div class="table-content">
          <div class="table-header-filters">
            <span
              class="p-input-icon-left width"
              [ngClass]="{ 'p-input-icon-right': searchLoading }">
              <i class="pi pi-search"></i>
              <input
                pInputText
                class="search-table-input"
                type="text"
                [(ngModel)]="userFilter.searchString"
                (ngModelChange)="onSearch()"
                [placeholder]="'ADMIN.USERS.SEARCH_MEMBERS' | translate" />
              <i
                *ngIf="searchLoading"
                class="pi pi-spin pi-spinner"></i>
            </span>
            <div class="select-container">
              <div class="role-string">
                {{ "ADMIN.USERS.ROLE" | translate }}
              </div>
              <p-dropdown
                [(ngModel)]="selectedRol"
                class="width select filter-select"
                (ngModelChange)="updateRol()"
                [options]="roles"
                optionLabel="value"
                optionValue="id"
                [style]="{ width: '100%' }"></p-dropdown>
            </div>
          </div>
          <p-table
            [lazy]="true"
            [loading]="loading"
            [value]="users"
            [tableStyle]="{ 'min-width': '60rem' }"
            [paginator]="true"
            [rows]="userFilter.limit"
            [totalRecords]="totalRecords"
            (onLazyLoad)="onLazyLoadUsers($event)"
            [(first)]="page">
            <ng-template pTemplate="header">
              <tr>
                <th
                  pSortableColumn="name"
                  class="column-name">
                  {{ "ADMIN.USERS.NAME" | translate }}
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="email"
                  class="column-email">
                  {{ "ADMIN.USERS.EMAIL" | translate }}
                  <p-sortIcon field="email"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="role"
                  class="column-role">
                  {{ "ADMIN.USERS.ROLE" | translate }}
                  <p-sortIcon field="role"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="lastConnectionDate"
                  class="column-state">
                  {{ "ADMIN.USERS.STATE" | translate }}
                  <p-sortIcon field="lastConnectionDate"></p-sortIcon>
                </th>
                <th class="column-settings"></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-user>
              <tr>
                <td class="td-name">
                  {{ user.name }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td>
                  {{ user.role | titlecase }}
                </td>
                <td>
                  <ng-container *ngIf="user.lastConnectionDate">
                    {{ "ADMIN.USERS.LAST_CONNECTION" | translate }} {{ user.lastConnectionDate | date: "dd/MM/yyyy" }}
                  </ng-container>
                </td>
                <td>
                  <p-overlayPanel
                    #op
                    [style]="{ width: '125px' }">
                    <ng-template pTemplate="content">
                      <div
                        class="options-container"
                        (click)="onEditUser(user)">
                        <span> {{ "TABLE.OPTIONS.EDIT" | translate }} </span>
                      </div>
                      <div
                        class="options-container"
                        (click)="onRemoveUser(user)">
                        <span> {{ "TABLE.OPTIONS.REMOVE" | translate }} </span>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                  <button
                    pButton
                    class="lang-nav-btn p-button-text p-button-sm settings-btn ellipsis-settings-btn"
                    (click)="op.toggle($event)">
                    <i class="pi pi-ellipsis-v"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </ng-container>
  </gpta-admin-container>

  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
</div>
<gpta-manage-users-modal
  [(visible)]="showManageUserModal"
  [user]="editUser"
  (visibleChange)="clearUser()"></gpta-manage-users-modal>
