import { NgModule } from '@angular/core';

import { ConvertPercentagePipe } from './convert-percentage.pipe';
import { IsCopyableMsgPipe } from './is-copyable-msg.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { TruncateLengthPipe } from './truncate-length.pipe';

@NgModule({
  declarations: [
    TruncateLengthPipe,
    ConvertPercentagePipe,
    IsCopyableMsgPipe,
    LocalizedDatePipe,
  ],
  exports: [
    TruncateLengthPipe,
    ConvertPercentagePipe,
    IsCopyableMsgPipe,
    LocalizedDatePipe,
  ],
})
export class PipesModule { }
