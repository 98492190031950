const NEW_LINE_CHART = '\n';
function parseSpecialChars(text: string) {
  if (!text) {
    return text;
  }
  return encodeURIComponent(text);
}

function addParagraphToText(newParagraph: string | null, previousText: string) {
  if (!newParagraph || !newParagraph.trim()) {
    return previousText;
  }
  return `${previousText !== '' ? `${previousText}${NEW_LINE_CHART}` : ''}${newParagraph}`;
}

function addBlockToText(blockText: string | null, previousText: string) {
  const blockGroupText = blockText ? `${NEW_LINE_CHART}${blockText}` : blockText;
  return addParagraphToText(blockGroupText, previousText);
}

function addListItemToText(itemText: string | null, text: string) {
  if (!itemText) {
    return text;
  }
  return addParagraphToText(`* ${itemText}`, text);
}

export function getTextComponentText(element: HTMLElement) {
  let text = '';
  const paragraphs = element.querySelectorAll('p, li');
  Array.from(paragraphs).forEach((textRow) => {
    if (textRow.tagName === 'LI') {
      const { textContent } = textRow;
      text = addListItemToText(textContent, text);
      return;
    }
    const { textContent } = textRow;
    text = addParagraphToText(textContent, text);
  });
  return text;
}

export function elementToList(element: HTMLElement, rowSelector: string, cellSelector: string, plainList = false) {
  let text = '';
  const rows = element.querySelectorAll(rowSelector);
  Array.from(rows).forEach((row) => {
    let rowText = '';
    const cells = row.querySelectorAll(cellSelector);
    Array.from(cells).forEach((cell) => {
      const { textContent } = cell;
      rowText += `${textContent}\t`;
    });
    text = plainList ? addParagraphToText(rowText, text) : addListItemToText(rowText, text);
  });
  return text;
}
export function getTableComponentText(element: HTMLElement) {
  return elementToList(element, 'tr', 'td, th', true);
}
export function getChartLegendComponentText(element: HTMLElement) {
  return elementToList(element, '.chart-legend-item', 'div');
}

export function getWeightsComponentText(element: HTMLElement) {
  return elementToList(element, '.weights-list-item', 'div');
}

export function getCardsComponentText(element: HTMLElement) {
  return elementToList(element, '.card', ':scope > div > div');
}

export function getEmailText(element: any) {
  let text = '';
  const cloneElement = element.cloneNode(true) as HTMLElement;
  const textContainers = cloneElement.querySelectorAll('.copy-on-email');
  Array.from(textContainers).forEach((el) => {
    const textElement = el as HTMLElement;
    if (textElement.classList.contains('text-container')) {
      text = addBlockToText(getTextComponentText(textElement), text);
    } else if (textElement.classList.contains('table-container')) {
      text = addBlockToText(getTableComponentText(textElement), text);
    } else if (textElement.classList.contains('chart-legend')) {
      text = addBlockToText(getChartLegendComponentText(textElement), text);
    } else if (textElement.classList.contains('weights-container')) {
      text = addBlockToText(getWeightsComponentText(textElement), text);
    } else if (textElement.classList.contains('cards-container')) {
      text = addBlockToText(getCardsComponentText(textElement), text);
    } else {
      const { textContent } = textElement;
      text = addBlockToText(textContent, text);
    }
  });
  return text;
}

export function sendEmail(msgText: string, mailTo = '', subject?: string) {
  const url = `mailto:${mailTo}?body=${parseSpecialChars(msgText)}${subject ? `subject=${subject}&` : ''}`;
  window.open(url, '_self');
}

export function downloadEml(htmlContent: string, emailTo = '', subject = '') {
  let headBlock = '';
  if (emailTo) {
    headBlock += `
To: ${emailTo}
`;
  }
  if (subject) {
    headBlock += `
Subject: ${subject}
`;
  }
  const emlContent = `data:message/rfc822 eml;charset=utf-8,Content-Type: text/html;charset=utf-8${headBlock}
Mime-Version: 1.0
X-Unsent: 1
Content-Transfer-Encoding: quoted-printable

<html><head></head><body dir=3D"auto" style=3D"word-wrap: break-word; =
-webkit-nbsp-mode: space; line-break: after-white-space;">${htmlContent}</body></html>=
`;

  const encodedUri = encodeURI(emlContent);
  const a = document.createElement('a');

  const linkText = document.createTextNode('fileLink');
  a.appendChild(linkText);
  a.href = encodedUri;
  a.id = 'fileLink';
  a.download = 'GPT-advisor-Email.eml';
  document.body.appendChild(a);
  document.getElementById('fileLink')?.click();
  document.body.removeChild(a);
}
