<!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
<button
  pButton
  icon="pi pi-ellipsis-v"
  [ngClass]="{ blink: isLoading }"
  [disabled]="isLoading"
  styleClass="p-button-text"
  (click)="menu.toggle($event)"></button>
<p-menu
  #menu
  styleClass="pm-align-left"
  showTransitionOptions="0ms"
  [appendTo]="'body'"
  [model]="menuOptions"
  [popup]="true"></p-menu>
