<div
  *ngIf="conversation"
  class="gpta-chat">
  <div class="top-bar">
    <div class="chat-title">
      <gpta-chat-header></gpta-chat-header>
      <!-- <input pInputText #inputTitle type="text" [(ngModel)]="conversation.title"
        (blur)="isEdition = false" [ngClass]="{'hide': !isEdition}" /> -->
      <!-- <span *ngIf="!isEdition" class="title-container pointer" (click)="enableEdition(inputTitle)">
        <svg-icon src="../../../assets/images/comments-d.svg"></svg-icon>
        <span class="title">{{conversation.title || ('CHAT.NEW_CONVERSATION' | translate)}}</span>
        <svg-icon src="../../../assets/images/pencil.svg"></svg-icon>
      </span> -->
    </div>
  </div>
  <div class="conversation">
    <div class="conversation-container">
      <div
        id="messages-container"
        (scroll)="onMessagesContainerScroll($event)">
        <gpta-chat-placeholder *ngIf="!messages?.length"></gpta-chat-placeholder>
        <div
          class="messages"
          *ngIf="messages?.length">
          <div
            *ngFor="let message of messages; index as i; last as isLast"
            class="message"
            [ngClass]="{
              right: message.senderType !== SENDER_TYPE.AI,
              left: message.senderType === SENDER_TYPE.AI,
              'has-error': message.hasError,
              'is-mobile': (windowProperties | async)?.isMobile,
              'loading-message': message.loading
            }"
            [attr.id]="isLast ? 'last-message' : null">
            <div class="message-content">
              <div
                *ngIf="message.senderType === SENDER_TYPE.AI"
                [ngClass]="{ blink: message.loading }"
                class="miniature avoid-copy"></div>
              <div
                class="copy-container"
                *ngFor="
                  let item of this.currentAnswer._id === message._id &&
                  !message.hasError &&
                  this.message._id !== currentQuestion._id
                    ? (lastResponseContentObservable | async)
                    : message.content
                ">
                <div
                  class="text-container copy-on-email"
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.TEXT"
                  [innerHtml]="item.html || item.text"></div>
                <div
                  class="cards-list-container"
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.PRODUCT_CARDS">
                  <gpta-chat-info-card
                    *ngFor="let data of item.productCards"
                    [data]="data"
                    (optionSelected)="onOptionSelected($event)"></gpta-chat-info-card>
                </div>
                <div
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.CARDS"
                  class="cards-container copy-as-img copy-on-email">
                  <gpta-chat-cards
                    [cards]="item.cards"
                    [isAnimated]="this.currentAnswer._id === message._id"></gpta-chat-cards>
                </div>
                <div
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.SELECTOR"
                  class="selector-container avoid-copy">
                  <gpta-chat-selector
                    [messageId]="message._id"
                    [isDisabled]="i + 1 !== messages.length"
                    [textOptions]="item.textOptions"
                    [minSelections]="item.minSelections"
                    [maxSelections]="item.maxSelections"
                    [selectedInput]="messages[i + 1]"
                    (optionSelected)="onOptionSelected($event)"></gpta-chat-selector>
                </div>
                <div
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.CHART"
                  class="chart-container copy-as-img">
                  <gpta-chat-chart
                    [chartData]="item.chart"
                    [isAnimated]="this.currentAnswer._id === message._id"></gpta-chat-chart>
                </div>
                <div
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.WEIGHTS"
                  class="chart-container weights-container copy-as-img copy-on-email">
                  <gpta-chat-weights-list
                    [weightsData]="item.weights"
                    [isAnimated]="this.currentAnswer._id === message._id"></gpta-chat-weights-list>
                </div>
                <div
                  *ngIf="item.type === MESSAGE_CONTENT_TYPES.TABLE"
                  class="table-container copy-on-email">
                  <gpta-chat-table [tableData]="item.table"></gpta-chat-table>
                </div>
              </div>
              <div
                class="sources copy-on-email"
                *ngIf="message.attachments && message.attachments.length">
                <gpta-chat-attachments
                  [attachments]="message.attachments"
                  (pdfSource)="onSourceSelected($event)"></gpta-chat-attachments>
              </div>
              <gpta-chat-tools
                class="avoid-copy"
                *ngIf="message | isCopyableMsg"></gpta-chat-tools>
              <div
                *ngIf="message.metadata && message.metadata['sources']?.length"
                class="sources copy-on-email">
                <gpta-chat-sources
                  [sources]="message.metadata['sources']"
                  (pdfSource)="onSourceSelected($event)"></gpta-chat-sources>
              </div>
              <div
                *ngIf="message.news"
                class="sources">
                <gpta-chat-news
                  [news]="message.news"
                  (scrollToBottom)="scrollToBottom(isLast)"></gpta-chat-news>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container">
        <div
          class="suggestions"
          *ngIf="followUpList?.length && showSuggestions">
          <gpta-chat-suggestions
            [suggestions]="followUpList"
            (optionSelected)="onOptionSelected($event)"
            class="suggestions-component"></gpta-chat-suggestions>
        </div>
        <div class="new-message">
          <form>
            <span class="p-input-icon-right input-container">
              <input
                pInputText
                type="text"
                name="text"
                [(ngModel)]="text"
                (keydown)="keyDownFunction($event)"
                placeholder="{{ 'CHAT.WRITE_QUESTION' | translate }}" />
              <button
                class="invisible-button icon p-icon-wrapper"
                [ngClass]="{ disabled: !text.trim() }"
                (click)="handleSendMessage()">
                <svg-icon src="../../../assets/images/send.svg"></svg-icon>
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
    <div
      *ngIf="showPdfViewer"
      class="pdf-container">
      <gpta-chat-pdf-viewer
        [source]="selectedPdfSource"
        (closePdf)="showPdfViewer = false"></gpta-chat-pdf-viewer>
    </div>
  </div>
  <gpta-header-nav></gpta-header-nav>
  <gpta-chat-side-menu
    [currentChatId]="conversationId"
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
  <gpta-loading
    *ngIf="isChatLoading"
    [options]="{ showBackdrop: true, message: loadingMessage }"></gpta-loading>
</div>
