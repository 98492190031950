import { customStyleConf } from '~app/models/custom-style';

export const STYLES_CUSTOM_COLORS: customStyleConf[] = [
  {
    name: 'primaryColor',
    key: 'primaryColor',
    editable: true,
    value: '',
  },
  {
    name: 'primaryColorDark',
    key: 'primaryColorDark',
    editable: true,
    value: '',
  },
  {
    name: 'primaryColorLight',
    key: 'rightMessageBg',
    editable: true,
    value: '',
  },
  {
    name: 'chartLine',
    key: 'chartLine',
    editable: false,
    value: '',
  },
];

export const STYLES_CUSTOM_IMAGES: customStyleConf[] = [
  {
    name: 'logo',
    key: 'entityLogoUrl',
    editable: true,
    value: '',
  },
  {
    name: 'rightMessageLogo',
    key: 'rightMessageLogo',
    editable: true,
    value: '',
  },
  {
    name: 'welcomeImageUrl',
    key: 'welcomeImageUrl',
    editable: true,
    value: '',
  },
];
