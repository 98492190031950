import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Faqs, FaqsResponse, SearchFaqsDTO } from '~app/models/faqs';
import { environment } from '~environments/environment';

import { buildQuery } from '../helpers/query-builder.helper';

@Injectable({
  providedIn: 'root',
})
export class FaqsService {
  url: string;

  faqsListSubject = new BehaviorSubject<FaqsResponse | null>(null);

  needReloadTableSubject = new BehaviorSubject<boolean | null>(null);

  faqsList$ = this.faqsListSubject.asObservable();

  needReloadTable$ = this.needReloadTableSubject.asObservable();

  constructor(public http: HttpClient) {
    this.url = environment.APP_API_URL;
  }

  public getFaqList(searchFaqsDto: SearchFaqsDTO): any {
    const params: {
      [param: string]: string;
    } = buildQuery(searchFaqsDto);
    this.http.get<any[]>(`${this.url}/api/faqs`, { params }).subscribe({
      next: (res) => this.nextFaqList(res),
      error: () => this.nextCorrectManageFaq(false),
    });
  }

  public createFaq(faq: Faqs) {
    this.http.post<any>(`${this.url}/api/faqs`, faq).subscribe({
      next: () => this.nextCorrectManageFaq(true),
      error: () => this.nextCorrectManageFaq(false),
    });
  }

  public updateFaq(faq: Faqs, faqId = '') {
    this.http.patch<Faqs>(`${this.url}/api/faqs/${faqId}`, faq).subscribe({
      next: () => this.nextCorrectManageFaq(true),
      error: () => this.nextCorrectManageFaq(false),
    });
  }

  public removeFaq(faqId?: string) {
    this.http.delete<any>(`${this.url}/api/faqs/${faqId}`).subscribe({
      next: () => this.nextCorrectManageFaq(true),
      error: () => this.nextCorrectManageFaq(false),
    });
  }

  nextFaqList(response: any) {
    this.faqsListSubject.next(response);
  }

  nextCorrectManageFaq(isChanges: boolean) {
    this.needReloadTableSubject.next(isChanges);
  }
}
