<div class="page-container">
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.CHAT_LOG.TITLE" | translate }}
    </ng-container>
    <ng-container side>
      <button
        pButton
        class="p-button-rounded p-button-sm large-btn"
        (click)="onExportChatLogs()">
        {{ "ADMIN.CHAT_LOG.EXPORT" | translate }}
      </button>
    </ng-container>
    <ng-container content>
      <p-card class="chat-log-card">
        <div class="table-content">
          <div class="header-container">
            <div class="header-search-container">
              <div>
                <span
                  class="p-input-icon-left search-chat-log"
                  [ngClass]="{ 'p-input-icon-right': searchLoading }">
                  <i class="pi pi-search"></i>
                  <input
                    class="search-table-input"
                    pInputText
                    type="text"
                    [placeholder]="'ADMIN.CHAT_LOG.SEARCH_CHAT_LOG' | translate"
                    [(ngModel)]="filters.searchString"
                    (ngModelChange)="onSearch()" />
                  <i
                    *ngIf="searchLoading"
                    class="pi pi-spin pi-spinner"></i>
                </span>
              </div>
            </div>
          </div>
          <p-table
            #chatLogTable
            [lazy]="true"
            [loading]="loading"
            [value]="chatLogs"
            [tableStyle]="{ 'min-width': '60rem' }"
            [paginator]="true"
            [(first)]="page"
            (onLazyLoad)="onLazyLoad($event)"
            [rows]="filters.limit"
            [totalRecords]="total"
            [sortOrder]="-1">
            <ng-template pTemplate="header">
              <tr>
                <th
                  pSortableColumn="name"
                  class="column-name">
                  {{ "ADMIN.CHAT_LOG.NAME" | translate }} <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="reference"
                  class="column-reference">
                  {{ "ADMIN.CHAT_LOG.ID" | translate }} <p-sortIcon field="reference"></p-sortIcon>
                </th>
                <th
                  class="custom-filter column-date"
                  #customFilter>
                  <div class="filter-row">
                    {{ "ADMIN.CHAT_LOG.DATE" | translate }}
                    <p-overlayPanel #filterPanel>
                      <ng-template pTemplate="content">
                        <gpta-overlay-filter
                          [sortValue]="
                            filters.sortOrder && filters.sortField === 'creationDate' ? filters.sortOrder : 1
                          "
                          [sortOptions]="sortType"
                          [dateInput]="true"
                          [dateFilterOptionsSelected]="dateOptionsSelected"
                          (removeEmitter)="removeEmmiter($event, filterPanel, chatLogTable)"
                          (applyEmitter)="applyEmitter($event, filterPanel, chatLogTable)"></gpta-overlay-filter>
                      </ng-template>
                    </p-overlayPanel>
                    <button
                      class="p-ripple p-element p-button p-component p-button-secondary p-button-icon-only p-button-rounded p-button-text filter-btn"
                      pButton
                      type="button"
                      (click)="filterPanel.toggle($event)">
                      <i class="pi pi-filter"></i>
                    </button>
                  </div>
                </th>
                <th>
                  {{ "ADMIN.CHAT_LOG.TIME" | translate }}
                </th>
                <th class="column-chat"></th>
                <th class="column-sources"></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-log>
              <tr>
                <td>{{ log.user.name }}</td>
                <td>{{ log.user.reference }}</td>
                <td>{{ log.questionMessage.creationDate | date: "dd/MM/yyyy" }}</td>
                <td>{{ log.questionMessage.creationDate | date: "HH:mm" }}</td>
                <td>
                  <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
                  <a
                    class="modal-link"
                    (click)="showChatInfo(log)"
                    >{{ "ADMIN.CHAT_LOG.CHAT" | translate }}</a
                  >
                </td>
                <td>
                  <a
                    class="modal-link"
                    (click)="showSourcesInfo(log)"
                    >{{ "ADMIN.CHAT_LOG.SOURCES" | translate }}</a
                  >
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </ng-container>
  </gpta-admin-container>
  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
  <gpta-chat-modal
    [(visible)]="showChatModal"
    [chatLog]="selectedChatLog"
    (visibleChange)="visibleChange()">
  </gpta-chat-modal>
  <gpta-sources-modal
    [(visible)]="showSourcesModal"
    [chatLog]="selectedChatLog"
    (visibleChange)="visibleChange()">
  </gpta-sources-modal>
</div>
