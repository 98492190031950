import { Component } from '@angular/core';

@Component({
  selector: 'gpta-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {

}
