<div class="gpta-signin">
  <div class="logo"></div>
  <div class="title">{{ "SIGNIN.SIGNIN" | translate }}</div>
  <form
    class="form"
    [formGroup]="form"
    (ngSubmit)="submitLoginForm()">
    <div class="wrapper">
      <div class="input-content">
        <label
          class="label"
          for="email"
          >{{ "COMMON.EMAIL" | translate }}</label
        >
        <input
          pInputText
          type="email"
          id="email"
          formControlName="email"
          required />
      </div>
      <div class="input-content">
        <label
          class="label"
          for="password"
          >{{ "COMMON.PASSWORD" | translate }}</label
        >
        <p-password
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          formControlName="password"
          [toggleMask]="true"
          [feedback]="false"
          required></p-password>
      </div>
      <div class="button-content">
        <a [routerLink]="['/signup']">{{ "COMMON.SIGNUP_HERE" | translate }}</a>
        <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
        <button
          pButton
          [disabled]="!form.valid"
          label="{{ 'SIGNIN.SIGNIN_BUTTON' | translate }}"></button>
      </div>
    </div>
  </form>
</div>
