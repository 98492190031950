<ng-container *ngIf="(windowProperties | async)?.isMobile; else desktopTable">
  <div class="mobile-tables-container">
    <p-table
      *ngIf="tableData"
      [value]="[{}]">
      <ng-template pTemplate="body">
        <ng-container *ngFor="let block of tableData.slice(1)">
          <tr>
            <td
              class="t-header"
              colspan="2">
              {{ block[0] }}
            </td>
          </tr>
          <tr *ngFor="let cell of block.slice(1); index as i">
            <td class="left-column">{{ tableData[0][i + 1] }}</td>
            <td>{{ cell }}</td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<ng-template #desktopTable>
  <div class="table-container">
    <p-table
      *ngIf="tableData"
      [value]="[{}]">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let cell of tableData[0]">{{ cell }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr *ngFor="let row of tableData.slice(1)">
          <td *ngFor="let cell of row">{{ cell }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>
