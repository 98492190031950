import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { isAdminGuard } from '~app/services/guard/is-admin.guard';
import { hasUserDataGuard } from '~guards/has-user-data.guard';
import { isLoggedGuard } from '~guards/is-logged.guard';

import { AnalyticsComponent } from './analytics.component';

const routes: Routes = [
  {
    path: 'admin/analytics',
    component: AnalyticsComponent,
    canActivate: [isLoggedGuard, hasUserDataGuard, isAdminGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule { }
