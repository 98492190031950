import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { TableModule } from 'primeng/table';

import { ComponentsModule } from '../../components/components.module';
import { ManageUsersModalComponent } from './components/manage-users-modal/manage-users-modal.component';
import { UsersComponent } from './users.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  declarations: [
    UsersComponent,
    ManageUsersModalComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    TranslateModule,
    ComponentsModule,
    CardModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    OverlayPanelModule,
    DialogModule,
    PasswordModule,
    ReactiveFormsModule,
  ],
  providers: [TitleCasePipe],
})
export class UsersModule { }
