import { Component, Input } from '@angular/core';

interface Options {
  style?: string;
  showBackdrop?: boolean;
  avoidFade?: boolean;
  message?: string;
}

@Component({
  selector: 'gpta-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() options: Options = {};
}
