import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Observable, takeWhile } from 'rxjs';

import { SEARCH_DELAY } from '~app/config/constants/search';
import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { Faqs, FaqsResponse, SearchFaqsDTO } from '~app/models/faqs';
import { ApiService } from '~app/services/api/api.service';
import { FaqsService } from '~app/services/faqs/faqs.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit, OnDestroy {
  faqFilter: SearchFaqsDTO = {
    searchString: '', skip: 0, limit: 10, sortField: '',
  };

  faqs$: Observable<FaqsResponse | null>;

  needReloadTable$: Observable<boolean | null>;

  showManageUserFaq = false;

  isAlive = true;

  loading = false;

  editFaq: Faqs | null = null;

  typingTimer: any;

  faqs: Faqs[] = [];

  page = 0;

  search_delay = SEARCH_DELAY;

  totalRecords = 10;

  searchLoading = false;

  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private faqsService: FaqsService,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.faqs$ = this.faqsService.faqsList$;
    this.needReloadTable$ = this.faqsService.needReloadTable$;
  }

  ngOnInit(): void {
    this.faqs$.subscribe((res) => {
      if (res?.faqs) this.faqs = [...res.faqs];
      if (res?.total) this.totalRecords = res.total;
      this.loading = false;
    });
    this.needReloadTable$.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.getFaqList();
      }
      this.loading = false;
      this.searchLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  onSearch(): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchLoading = true;
      this.page = 0;
      this.getFaqList();
    }, this.search_delay);
  }

  onLazyLoadFaqs(event: any): void {
    this.faqFilter.skip = event.first;
    if (event.sortField) this.faqFilter.sortField = event.sortField as string;
    if (event.sortOrder) this.faqFilter.sortOrder = event.sortOrder;
    if (event.rows) this.faqFilter.limit = event.rows;
    this.getFaqList();
  }

  getFaqList(): void {
    this.loading = true;
    this.faqsService.getFaqList(this.faqFilter);
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.apiService.setNextConversation({});
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate(['/chat', conversation._id]);
    }
  }

  createNewQA(): void {
    this.showManageUserFaq = true;
  }

  clearFaq(): void {
    this.editFaq = null;
  }

  onEditDocument(faq: Faqs): void {
    this.editFaq = faq;
    this.showManageUserFaq = true;
  }

  onRemoveDocument(faq: Faqs): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('ADMIN.FAQ.REMOVE_TITLE'),
      message: this.translateService.instant('ADMIN.FAQ.ARE_YOU_SURE'),
      rejectLabel: this.translateService.instant('COMMON.CANCEL'),
      acceptLabel: this.translateService.instant('COMMON.DELETE'),
      acceptButtonStyleClass: 'p-button-rounded large-btn',
      rejectButtonStyleClass: 'p-button-rounded p-button-outlined large-btn admin-outline-btn',
      accept: () => {
        // eslint-disable-next-line no-underscore-dangle
        if (faq) this.faqsService.removeFaq(faq._id);
      },
    });
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
