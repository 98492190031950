<p-dialog
  [header]="title"
  [(visible)]="visible"
  (onHide)="onClose()"
  [modal]="true"
  [style]="{ width: '450px' }"
  [draggable]="false"
  [resizable]="false">
  <form
    class="form form-container"
    [formGroup]="form"
    (ngSubmit)="onActivate()"
    *ngIf="form">
    <div>
      <label
        htmlFor="name"
        class="font-weight-bold"
        >{{ "COMMON.NAME" | translate }}</label
      >
      <input
        pInputText
        id="name"
        aria-describedby="name"
        formControlName="name"
        class="input-form form-input" />
    </div>
    <div>
      <label
        htmlFor="role"
        class="font-weight-bold">
        {{ "ADMIN.USERS.ROLE" | translate }}
      </label>
      <p-dropdown
        [style]="{ width: '100%' }"
        formControlName="role"
        [options]="roles"
        class="input-form filter-select"></p-dropdown>
    </div>
    <ng-container *ngIf="isUpdate">
      <div>
        <label
          htmlFor="role"
          class="font-weight-bold">
          {{ "MENU.USER.LANGUAGE" | translate }}
        </label>
        <p-dropdown
          [style]="{ width: '100%' }"
          formControlName="language"
          [options]="languageList"
          class="filter-select"></p-dropdown>
      </div>
    </ng-container>
    <div>
      <label
        htmlFor="name"
        class="font-weight-bold">
        {{ "ADMIN.USERS.EMAIL" | translate }}
      </label>
      <input
        pInputText
        id="email"
        aria-describedby="email"
        formControlName="email"
        class="input-form form-input" />
    </div>
    <div>
      <label
        htmlFor="password"
        class="font-weight-bold">
        {{ "COMMON.PASSWORD" | translate }}
      </label>
      <p-password
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
        formControlName="password"
        [toggleMask]="true"
        [feedback]="false"
        required
        class="input-form form-input"></p-password>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="footer-content">
      <div>
        <button
          pButton
          label="{{ 'COMMON.CANCEL' | translate }}"
          class="p-button-rounded p-button-outlined large-btn"
          (click)="onClose()"
          [disabled]="loading"></button>
      </div>
      <div>
        <button
          pButton
          [disabled]="!form.valid"
          class="buttom-btn large-btn"
          (click)="onActivate()"
          label="{{ 'CHAT.COPY.CONFIRM.ACTIVE' | translate }}"
          [disabled]="loading || !form.valid"
          *ngIf="!isUpdate"></button>
        <button
          pButton
          [disabled]="!form.valid"
          class="buttom-btn large-btn"
          (click)="onUpdate()"
          label="{{ 'COMMON.UPDATE' | translate }}"
          [disabled]="loading || !form.valid"
          *ngIf="isUpdate"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
