import { Component } from '@angular/core';

// import default host conf
import {
  chatHeaderHtml,
  chatHeaderStyle,
} from '~config/host-confs';

@Component({
  selector: 'gpta-chat-header',
  templateUrl: chatHeaderHtml,
  styleUrls: [chatHeaderStyle],
})
export class ChatHeaderComponent {

}
