import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { OverlayFilterSelectOptions, OverlayFilterSort, TextOverlayFilter } from '~app/models/overlay-filter';

@Component({
  selector: 'gpta-overlay-filter',
  templateUrl: './overlay-filter.component.html',
  styleUrls: ['./overlay-filter.component.scss'],
})
export class OverlayFilterComponent {
  @Input() text: TextOverlayFilter = {
    sortTtile: 'COMMON.OVERLAY_FILTER.SORT',
    selectTitle: 'COMMON.OVERLAY_FILTER.FILTER',
    selectPlaceholder: 'COMMON.OVERLAY_FILTER.TYPE',
    dateTitle: 'COMMON.OVERLAY_FILTER.DATE',
    datePlaceholder: 'COMMON.OVERLAY_FILTER.DATE_PLACEHOLDER',
    clearBtn: 'COMMON.OVERLAY_FILTER.CLEAR',
    applyBtn: 'COMMON.OVERLAY_FILTER.APPLY',
  };

  @Input() sortValue = 1;

  @Input() sortOptions: OverlayFilterSort[] = [{ label: '', value: 1 }, { label: '', value: -1 }];

  @Input() options: OverlayFilterSelectOptions[] = [];

  @Input() optionsSelected : string[] = [];

  @Input() dateInput = false;

  @Input() dateFilterOptionsSelected: Date[] | undefined;

  @Output() removeEmitter = new EventEmitter();

  @Output() applyEmitter = new EventEmitter();

  optionsSelectedValue : string[] = [];

  constructor(private translateService: TranslateService) {
    if (this.sortOptions[0].label === '') {
      this.translateService.stream('COMMON.OVERLAY_FILTER.SORT_ASC')
        .subscribe((res: any) => {
          this.sortOptions[0].label = res;
        });
    }
    if (this.sortOptions[1].label === '') {
      this.translateService.stream('COMMON.OVERLAY_FILTER.SORT_DESC')
        .subscribe((res: any) => {
          this.sortOptions[1].label = res;
        });
    }
  }

  setTypeFilter(event:any) {
    const typeList = event.map(({ value }:any) => value);
    this.optionsSelectedValue = typeList;
  }

  removeFilter() {
    this.removeEmitter.next(true);
  }

  applyFilter() {
    const filter = {
      optionsSelected: this.optionsSelectedValue,
      typesSelected: this.optionsSelected,
      sortValue: this.sortValue,
      dateOptionsSelected: this.dateFilterOptionsSelected,
    };
    this.applyEmitter.next(filter);
  }

  onSelect(calendarFilter: any) {
    if (this.dateFilterOptionsSelected?.every((date) => date !== null)) {
      calendarFilter.hideOverlay();
    }
  }
}
