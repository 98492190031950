import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { New } from '~app/models/message';

@Component({
  selector: 'gpta-chat-news',
  templateUrl: './chat-news.component.html',
  styleUrls: ['./chat-news.component.scss'],
})
export class ChatNewsComponent {
  @Input() messagesElement: any;

  @Input() news: New[] = [];

  @Output() scrollToBottom = new EventEmitter();

  showNews = false;

  constructor(private translateService: TranslateService) {}

  toggleNews(newsPanel: any) {
    this.showNews = !this.showNews;
    newsPanel.toggle(new MouseEvent('click'));
    this.scrollToBottom.next(true);
  }

  get of() {
    return this.translateService.instant('COMMON.OF');
  }
}
