export enum COMPONENT_ACTION_TYPE {
  ERROR = 'error',
  CANCEL = 'cancel',
  DO = 'do',
}

export interface PartialComponentAction {
  name?: string;
  data?: any;
}

export interface ComponentAction extends PartialComponentAction {
  action: COMPONENT_ACTION_TYPE;
}
