import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { copyMessageData, getMessageCopyData, MessageCopyDataObj } from '~app/services/helpers/clipboard.helper';
import { downloadEml } from '~app/services/helpers/send-email.helper';
import { COPY_CLIPBOARD_RESULT } from '~models/copy-clipboard-resul.enum';

@Component({
  selector: 'gpta-chat-tools',
  templateUrl: './chat-tools.component.html',
  styleUrls: ['./chat-tools.component.scss'],
})
export class ChatToolsComponent {
  copyButtonLoading = false;

  createEmailButtonLoading = false;

  messageCopyData: MessageCopyDataObj | undefined;

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
  ) {}

  async copyToClipBoard() {
    this.copyButtonLoading = false;
    let result = COPY_CLIPBOARD_RESULT.ERROR;
    if (this.messageCopyData) {
      result = await copyMessageData(this.messageCopyData);
    }
    this.showCopyResult(result);
  }

  async copyMessage(event: any) {
    this.copyButtonLoading = true;

    if (this.messageCopyData) {
      this.copyToClipBoard();
      return;
    }

    // next tick after render
    setTimeout(async () => {
      const msgContainer = event.target.closest('.message-content');
      this.messageCopyData = await getMessageCopyData(msgContainer);
      const browserNeedsConfirm = this.messageCopyData.isSafari && this.messageCopyData.hasImgs;
      if (browserNeedsConfirm) {
        this.showCopyConfirm();
        return;
      }
      this.copyToClipBoard();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  shareByEmail(event: any) {
    this.createEmailButtonLoading = true;
    if (this.messageCopyData && this.messageCopyData.isHtmlSupported) {
      downloadEml(this.messageCopyData.html);
      this.createEmailButtonLoading = false;
      return;
    }
    setTimeout(async () => {
      const msgContainer = event.target.closest('.message-content');
      const messageCopyData = await getMessageCopyData(msgContainer, true);
      if (messageCopyData.isHtmlSupported) {
        this.messageCopyData = messageCopyData;
      }
      downloadEml(messageCopyData.html);
      this.createEmailButtonLoading = false;
    });
  }

  private showCopyConfirm() {
    this.confirmationService.confirm({
      header: this.translateService.instant('CHAT.COPY.CONFIRM.TITLE'),
      message: this.translateService.instant('CHAT.COPY.CONFIRM.MESSAGE'),
      rejectLabel: this.translateService.instant('CHAT.COPY.CONFIRM.CANCEL'),
      acceptLabel: this.translateService.instant('CHAT.COPY.CONFIRM.COPY'),
      acceptButtonStyleClass: 'gpta-button-success p-button-rounded',
      rejectButtonStyleClass: 'gpta-button-secondary p-button-rounded',
      accept: () => {
        this.copyToClipBoard();
      },
      reject: () => {
        this.messageCopyData = undefined;
        this.copyButtonLoading = false;
      },
    });
  }

  private showCopyResult(result: COPY_CLIPBOARD_RESULT) {
    let severity = 'success';
    let summary = this.translateService.instant('CHAT.COPY.SUCCESS.TITLE');
    let detail = this.translateService.instant('CHAT.COPY.SUCCESS.MESSAGE');
    if (result === COPY_CLIPBOARD_RESULT.ERROR) {
      severity = 'error';
      summary = this.translateService.instant('CHAT.COPY.ERROR.TITLE');
      detail = this.translateService.instant('CHAT.COPY.ERROR.MESSAGE');
    } else if (result === COPY_CLIPBOARD_RESULT.ONLY_TEXT_SUPPORTED) {
      severity = 'warn';
      summary = this.translateService.instant('CHAT.COPY.ONLY_TEXT.TITLE');
      detail = this.translateService.instant('CHAT.COPY.ONLY_TEXT.MESSAGE');
    }
    this.messageService.add({
      severity,
      life: 1000,
      summary,
      detail,
    });
  }
}
