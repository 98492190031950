<div
  class="chart-container chart-{{ chartData?.type }}"
  [ngClass]="{ 'is-mobile': (windowProperties | async)?.isMobile }">
  <div
    *ngIf="themedChartData && themedChartData.type === 'line' && themedChartData.data?.dates as dates"
    class="date-range">
    <div class="date-range-label">{{ dates[0] | localizedDate }}</div>
    <span class="icon">→</span>
    <div class="date-range-label">{{ dates[dates.length - 1] | localizedDate }}</div>
  </div>
  <div
    #chartTooltipsContainer
    class="chart-tooltips-container"
    (blur)="deleteTooltips()"
    (mouseout)="deleteTooltips()">
    <p-chart
      #chart
      *ngIf="themedChartData"
      [type]="themedChartData.type"
      [data]="themedChartData.data"
      [options]="themedChartData.options"></p-chart>
    <div
      *ngIf="currentTooltip"
      class="chart-tooltips">
      <div
        class="date-tooltip-line"
        [style]="{ left: currentTooltip.xPosition + 'px' }"></div>
      <div
        class="tooltip date-tooltip"
        [style]="{ left: currentTooltip.xPosition + 'px' }">
        <span>{{ currentTooltip.date }}</span>
      </div>
      <ng-container *ngFor="let point of currentTooltip.points; let i = index">
        <div
          class="date-tooltip-dot-border"
          [style]="{
            left: currentTooltip.xPosition + 'px',
            top: point.yPointPosition + 'px',
            backgroundColor: point.color
          }"></div>
        <div
          class="date-tooltip-dot"
          [style]="{
            left: currentTooltip.xPosition + 'px',
            top: point.yPointPosition + 'px',
            backgroundColor: point.color
          }"></div>
        <div
          class="tooltip data-tooltip"
          [ngClass]="point.class"
          [style]="{
            left: currentTooltip.xPosition + 'px',
            top: point.yCardPosition + 'px',
            maxWidth: point.maxWidth + 'px'
          }">
          <span
            class="data-label-color"
            [style]="{ backgroundColor: point.color }"></span>
          <span class="data-tooltip-text">
            {{ point.label || "-" }}
          </span>
          <span
            class="data-label-value"
            [style]="{ color: point.color }">
            {{ point.value | currency: currencyCode }}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="chart-legend copy-on-email"
    *ngIf="legendItems?.length">
    <div
      class="chart-legend-item"
      *ngFor="let item of legendItems">
      <div
        class="chart-legend-item-color"
        [style.background-color]="item.backgroundColor"></div>
      <div
        class="chart-legend-item-label"
        [innerHtml]="item.label"></div>
      <div class="chart-legend-item-value">{{ item.formattedValue }}</div>
    </div>
  </div>
</div>
