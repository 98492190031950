import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import config from '~config/constants';
import { DataService } from '~services/data/data.service';

const { TKN_KEY } = config.dataKeys;

export const isLoggedGuard: CanActivateFn = (currentRoute, state) => {
  const dataService = inject(DataService);
  const router = inject(Router);
  if (dataService.get(TKN_KEY)) {
    return true;
  }
  const redirectTo = state.url;
  router.navigate(
    ['/login'],
    { queryParams: { redirectTo } },
  );
  return false;
};
