import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { PipesModule } from '~pipes/pipes.module';

import { ChatModalComponent } from './admin/chat-logs/chat-modal/chat-modal.component';
import { SourcesModalComponent } from './admin/chat-logs/sources-modal/sources-modal.component';
import { ValueTileCardComponent } from './admin/value-tile-card/value-tile-card.component';
import { ChatListComponent } from './chat/chat-list/chat-list.component';
import {
  ChatListItemContextMenuComponent,
} from './chat/chat-list/chat-list-item-context-menu/chat-list-item-context-menu.component';
import { ChatAttachmentsComponent } from './chat/chat-message-parts/chat-attachments/chat-attachments.component';
import { ChatCardsComponent } from './chat/chat-message-parts/chat-cards/chat-cards.component';
import { ChatChartComponent } from './chat/chat-message-parts/chat-chart/chat-chart.component';
import { ChatInfoCardComponent } from './chat/chat-message-parts/chat-info-card/chat-info-card.component';
import { ChatSelectorComponent } from './chat/chat-message-parts/chat-selector/chat-selector.component';
import { ChatSourcesComponent } from './chat/chat-message-parts/chat-sources/chat-sources.component';
import { ChatTableComponent } from './chat/chat-message-parts/chat-table/chat-table.component';
import { ChatToolsComponent } from './chat/chat-message-parts/chat-tools/chat-tools.component';
import { ChatWeightsListComponent } from './chat/chat-message-parts/chat-weights-list/chat-weights-list.component';
import { ChatNewsComponent } from './chat/chat-news/chat-news.component';
import { ChatPdfViewerComponent } from './chat/chat-pdf-viewer/chat-pdf-viewer.component';
import { ChatPlaceholderComponent } from './chat/chat-placeholder/chat-placeholder.component';
import { ChatRenameFormComponent } from './chat/chat-rename-form/chat-rename-form.component';
import { ChatSideMenuComponent } from './chat/chat-side-menu/chat-side-menu.component';
import { ChatSuggestionsComponent } from './chat/chat-suggestions/chat-suggestions.component';
import { ColorPickerComponent } from './common/color-picker/color-picker.component';
import { ConfirmComponent } from './common/confirm/confirm.component';
import { LoadingComponent } from './common/loading/loading.component';
import { OverlayFilterComponent } from './common/overlay-filter/overlay-filter.component';
import { ToastComponent } from './common/toast/toast.component';
import { AdminContainerComponent } from './layout/admin-container/admin-container.component';
import { ChatHeaderComponent } from './layout/chat-header/chat-header.component';
import { HeaderNavComponent } from './layout/header-nav/header-nav.component';

@NgModule({
  declarations: [
    ChatHeaderComponent,
    ChatPlaceholderComponent,
    ChatCardsComponent,
    ChatSelectorComponent,
    ChatChartComponent,
    ChatTableComponent,
    ChatSideMenuComponent,
    ChatListComponent,
    LoadingComponent,
    HeaderNavComponent,
    ChatListItemContextMenuComponent,
    ToastComponent,
    ChatWeightsListComponent,
    ChatRenameFormComponent,
    ChatSourcesComponent,
    ChatPdfViewerComponent,
    ChatToolsComponent,
    ConfirmComponent,
    ChatAttachmentsComponent,
    ChatSuggestionsComponent,
    AdminContainerComponent,
    OverlayFilterComponent,
    ChatNewsComponent,
    ColorPickerComponent,
    ChatModalComponent,
    SourcesModalComponent,
    ValueTileCardComponent,
    ChatInfoCardComponent,
  ],
  imports: [
    CommonModule,
    ChartModule,
    TableModule,
    MenuModule,
    ProgressBarModule,
    SidebarModule,
    ButtonModule,
    ListboxModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    TranslateModule,
    TabMenuModule,
    DialogModule,
    InputTextModule,
    TooltipModule,
    ToastModule,
    PipesModule,
    PdfViewerModule,
    CheckboxModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    PanelModule,
    SelectButtonModule,
    MultiSelectModule,
    CardModule,
    CalendarModule,
  ],
  exports: [
    ChatHeaderComponent,
    ChatPlaceholderComponent,
    ChatCardsComponent,
    ChatSelectorComponent,
    ChatChartComponent,
    ChatTableComponent,
    ChatSideMenuComponent,
    ChatListComponent,
    LoadingComponent,
    HeaderNavComponent,
    ToastComponent,
    ChatWeightsListComponent,
    ChatRenameFormComponent,
    ChatSourcesComponent,
    ChatPdfViewerComponent,
    ChatToolsComponent,
    ConfirmComponent,
    ChatAttachmentsComponent,
    ChatSuggestionsComponent,
    AdminContainerComponent,
    OverlayFilterComponent,
    ChatNewsComponent,
    ColorPickerComponent,
    ChatModalComponent,
    SourcesModalComponent,
    ValueTileCardComponent,
    ChatInfoCardComponent,
  ],
})
export class ComponentsModule {}
