import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { User } from '~models/user';
import { ApiService } from '~services/api/api.service';

@Component({
  selector: 'gpta-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  public form: FormGroup;

  public hide = true;

  private authData: {
    email: string,
    password: string,
    hasAcceptedTerms: boolean,
  } = {
      email: '',
      password: '',
      hasAcceptedTerms: false,
    };

  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      hasAcceptedTerms: [false, Validators.requiredTrue],
    });
  }

  public submitSignupForm(): void {
    this.authData = {
      email: this.form.get('email')?.value,
      password: this.form.get('password')?.value,
      hasAcceptedTerms: this.form.get('hasAcceptedTerms')?.value,
    };
    this.apiService.signup(this.authData).subscribe((response: User) => {
      if (response) {
        this.router.navigate(['/chat']);
      }
    }, (error: any) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }
}
