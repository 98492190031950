import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { FILE_CATEGORIES, FILE_INFORMATION_TYPES } from '~app/config/constants/file-keys';
import { OrganizationDocumentElement } from '~app/models/document';
import { OrganizationProductElement, SearchProductsDto } from '~app/models/products';
import { DocumentsService } from '~app/services/documents/documents.service';
import { ProductsService } from '~app/services/products/products.service';
import { environment } from '~environments/environment';

@Component({
  selector: 'gpta-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss'],
})
export class FileUploadModalComponent implements OnInit, OnChanges {
  @ViewChild('fileUpload') fileUpload: any | undefined;

  @Input() visible = false;

  @Input() document: OrganizationDocumentElement | undefined;

  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() uploadFinish = new EventEmitter<boolean>();

  #url = environment.APP_API_URL;

  title = '';

  form!: FormGroup;

  options = FILE_CATEGORIES;

  productsInfo = FILE_INFORMATION_TYPES;

  products$: Observable<any>;

  products: OrganizationProductElement[] = [];

  productFilter: SearchProductsDto = {
    searchString: '', categories: [], skip: 0, limit: 0, sortField: '', sortOrder: 1,
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private translateService: TranslateService,
    private productsService: ProductsService,
    private documentsService: DocumentsService,
  ) {
    this.products$ = this.productsService.productList$;
    this.initForm();
    this.translateService.stream('ADMIN.DOCUMENTS.MODAL.FILE_CATEGORY')
      .subscribe((res: any) => {
        for (let i = 0; i < this.options.length; i += 1) {
          this.options[i].name = res[this.options[i].value];
        }
      });
    this.translateService.stream('ADMIN.DOCUMENTS.MODAL.FILE_INFORMATION_TYPE')
      .subscribe((res: any) => {
        for (let i = 0; i < this.productsInfo.length; i += 1) {
          this.productsInfo[i].name = res[this.productsInfo[i].value];
        }
      });
  }

  ngOnInit() {
    this.getProductsList();
    this.products$.subscribe(
      (
        res: {
          organizationProducts: OrganizationProductElement[],
          total: number
        },
      ) => {
        if (res?.organizationProducts) this.products = res.organizationProducts;
      },
    );
  }

  ngOnChanges(): void {
    if (this.visible) {
      this.setTitle();
      if (this.document) {
        this.loadFormWithDocumentData();
      }
    }
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      date: ['', [Validators.required]],
      type: ['', [Validators.required]],
      description: ['', [Validators.required]],
      additionalInfo: this.formBuilder.group({
        product: [''],
        typeInformation: [''],
      }),
    });
  }

  private getFormData() {
    return {
      name: this.form.value.name,
      fileDate: this.form.value.date,
      category: this.form.value.type.value,
      description: this.form.value.description,
      productCodes: Array.isArray(this.form.value.additionalInfo.product)
        ? this.form.value.additionalInfo.product.map(({ isin }:any) => isin) : undefined,
      informationType: this.form.value.additionalInfo.typeInformation
        ? this.form.value.additionalInfo.typeInformation.value : undefined,
    };
  }

  private getProducts() {
    return this.document?.productCodes ? this.products.filter(
      (product) => this.document?.productCodes?.some(
        (value) => product.isin.includes(value),
      ),
    ) : '';
  }

  private setTitle() {
    if (this.document) {
      this.title = this.translateService.instant('ADMIN.DOCUMENTS.MODAL.EDIT_DOCUMENT_TITLE');
    } else {
      this.title = this.translateService.instant('ADMIN.DOCUMENTS.MODAL.NEW_DOCUMENT_TITLE');
    }
  }

  private setFormValues(products: any) {
    if (this.document) {
      this.form.patchValue({
        name: this.document.name,
        date: this.document.fileDate ? new Date(this.document.fileDate) : '',
        type: FILE_CATEGORIES.find(({ value }) => value === this.document?.category),
        description: this.document.description,
        additionalInfo: {
          product: products,
          typeInformation: FILE_INFORMATION_TYPES.find(({ value }) => value === this.document?.informationType),
        },
      });
    }
  }

  loadFormWithDocumentData() {
    const products = this.getProducts();
    this.setFormValues(products);
  }

  setValidators() {
    if (this.form.get('type')?.value?.value === 'PRODUCT_INFO') {
      this.form.get('additionalInfo')?.get('product')?.setValidators(Validators.required);
      this.form.get('additionalInfo')?.get('typeInformation')?.setValidators(Validators.required);
    } else {
      this.form.get('additionalInfo')?.get('product')?.clearValidators();
      this.form.get('additionalInfo')?.get('typeInformation')?.clearValidators();
      this.form.get('additionalInfo')?.get('product')?.setValue('');
      this.form.get('additionalInfo')?.get('typeInformation')?.setValue('');
      this.form.get('additionalInfo')?.get('product')?.markAsUntouched();
      this.form.get('additionalInfo')?.get('typeInformation')?.markAsUntouched();
      this.form.get('additionalInfo')?.get('product')?.markAsPristine();
      this.form.get('additionalInfo')?.get('typeInformation')?.markAsPristine();
    }
    this.form.get('additionalInfo')?.get('product')?.updateValueAndValidity();
    this.form.get('additionalInfo')?.get('typeInformation')?.updateValueAndValidity();
  }

  getProductsList() {
    this.productsService.getProductList(this.productFilter);
  }

  setUpForm(event: any) {
    const data: any = this.getFormData();
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i += 1) {
          event.formData.append(`${key}[]`, data[key][i]);
        }
      } else if (data[key]) event.formData.append(key, data[key]);
    });
  }

  onUpload(fileUpload: any) {
    fileUpload.clear();
    this.uploadFinish.emit(true);
    this.onClose();
  }

  onEdit() {
    const data: any = this.getFormData();
    if (this.document) {
      // eslint-disable-next-line no-underscore-dangle
      this.documentsService.patchDocumentById(this.document._id, data).subscribe({
        next: () => {
          this.uploadFinish.emit(true);
          this.onClose();
        },
        error: () => {
          this.onClose();
        },
      });
    }
  }

  onClose(): void {
    this.fileUpload.clear();
    this.visibleChange.emit(false);
    this.form.patchValue({
      name: '',
      date: '',
      type: '',
      description: '',
      additionalInfo: {
        product: '',
        typeInformation: '',
      },
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  get url() {
    return this.#url;
  }
}
