import { DatePipe } from '@angular/common';
import {
  Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable } from 'rxjs';

import { SEARCH_DELAY } from '~app/config/constants/search';
import { ChatLog, ChatLogListResponse, SearchChatLogDto } from '~app/models/chat-log';
import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { TextOverlayFilter } from '~app/models/overlay-filter';
import { ApiService } from '~app/services/api/api.service';
import { ChatLogService } from '~app/services/chat-logs/chat-log.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-chat-log',
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.scss'],
})
export class ChatLogComponent implements OnInit {
  @ViewChild('customFilter') customFilter: ElementRef | undefined;

  showChatModal = false;

  showSourcesModal = false;

  selectedChatLog: ChatLog | undefined;

  filters: SearchChatLogDto = {
    skip: 0,
    limit: 10,
    sortOrder: -1,
  };

  dateOptionsSelected: Date[] = [];

  page = 0;

  loading = false;

  typingTimer: any;

  searchDelay = SEARCH_DELAY;

  searchLoading = false;

  chatLogsObservable$: Observable<ChatLogListResponse>;

  chatLogs: ChatLog[] = [];

  total = 0;

  isApplyEmitterTriggered = false;

  isRemoveEmitterTriggered = false;

  sortType: any[] = [{ label: '', value: 1 }, { label: '', value: -1 }];

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private chatLogService: ChatLogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.chatLogsObservable$ = this.chatLogService.chatLogs$;
    this.translateService.stream('ADMIN.CHAT_LOG.SORT_ASC')
      .subscribe((res: any) => {
        this.sortType[0].label = res;
      });
    this.translateService.stream('ADMIN.CHAT_LOG.SORT_DESC')
      .subscribe((res: any) => {
        this.sortType[1].label = res;
      });
  }

  ngOnInit(): void {
    this.chatLogsObservable$.subscribe((res) => {
      if (!res) return;
      this.chatLogs = res.chatlog;
      this.total = res.total;
      this.loading = false;
      this.searchLoading = false;
    });
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    if (this.isApplyEmitterTriggered) {
      this.isApplyEmitterTriggered = false;
      return;
    }
    this.filters.skip = event.first || 0;
    if (event.sortField) {
      this.filters.sortField = event.sortField as string;
    }
    if (event.sortOrder && !this.isRemoveEmitterTriggered) {
      this.filters.sortOrder = event.sortOrder;
    } else {
      this.isRemoveEmitterTriggered = false;
    }
    if (event.rows) this.filters.limit = event.rows;
    if (this.filters.sortField !== 'creationDate'
      && this.customFilter !== undefined
      && this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.filters.dateFrom = undefined;
      this.filters.dateTo = undefined;
      this.dateOptionsSelected = [];
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getChatLogs();
  }

  getChatLogs(): void {
    this.loading = true;
    this.chatLogService.getChatLogList(this.filters);
  }

  onExportChatLogs(): void {
    this.chatLogService.exportDocument(this.filters.searchString).pipe().subscribe((res) => {
      const blob = new Blob([res], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `chatlogs${this.filters.searchString ? '_filtered' : ''}.csv`;
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    });
  }

  onSearch(): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchLoading = true;
      this.removeFilter(true);
      this.page = 0;
      this.getChatLogs();
    }, this.searchDelay);
  }

  removeFilter(isSearch: boolean) {
    if (!isSearch) this.filters.searchString = '';
    this.filters.skip = 0;
    if (!isSearch) this.filters.sortField = '';
    if (!isSearch) this.filters.sortOrder = -1;
    if (!isSearch) this.filters.dateFrom = undefined;
    if (!isSearch) this.filters.dateTo = undefined;
    this.dateOptionsSelected = [];
    if (this.customFilter !== undefined
      && this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate([`/chat/${conversation._id}`]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }

  showChatInfo(chat: ChatLog): void {
    this.showChatModal = true;
    this.selectedChatLog = chat;
  }

  showSourcesInfo(chat: ChatLog): void {
    this.showSourcesModal = true;
    this.selectedChatLog = chat;
  }

  visibleChange() {
    this.showChatModal = false;
    this.showSourcesModal = false;
    this.selectedChatLog = undefined;
  }

  applyFilter(chatLogTable: any) {
    const { sortOrder } = this.filters;
    chatLogTable.clear();
    this.filters.sortField = 'creationDate';
    this.filters.searchString = '';
    this.filters.sortOrder = sortOrder;
    if (this.customFilter !== undefined
      && !this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getChatLogs();
  }

  removeEmmiter(event: any, filterPanel: any, chatLogTable: any) {
    this.isRemoveEmitterTriggered = true;
    this.removeFilter(false);
    filterPanel.toggle(new Event('click'));
    chatLogTable.clear();
  }

  applyEmitter(event: any, filterPanel: any, chatLogTable: any) {
    this.isApplyEmitterTriggered = true;
    this.dateOptionsSelected = event.dateOptionsSelected;
    const formattedDates = this.formatDate(this.dateOptionsSelected);
    if (formattedDates.every((date) => date !== '')) {
      [this.filters.dateFrom, this.filters.dateTo] = formattedDates;
    }
    this.filters.sortOrder = event.sortValue;
    this.applyFilter(chatLogTable);
    filterPanel.toggle(new Event('click'));
  }

  private formatDate(dates: Date[]) {
    return dates.map((date) => this.datePipe.transform(date, 'yyyy-MM-dd') || '');
  }
}
