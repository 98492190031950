<div class="selector-container">
  <form
    class="form"
    [formGroup]="form"
    (ngSubmit)="submitForm()">
    <div
      *ngFor="let textOption of textOptions; index as i"
      class="pointer option-container"
      [ngClass]="{ disabled: isDisabled }">
      <p-checkbox
        inputId="{{ messageId }}-option-{{ i }}"
        [value]="textOption.text"
        formControlName="selection"
        [formControl]="$any(form.controls['selection'])"
        group="selection"></p-checkbox>
      <label
        class="pointer label"
        for="{{ messageId }}-option-{{ i }}"
        [innerHtml]="textOption.html || textOption.text"></label>
    </div>
    <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
    <button
      pButton
      [disabled]="!form.valid || isDisabled"
      label="{{ 'COMMON.SELECT' | translate }}"></button>
  </form>
</div>
