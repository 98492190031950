<p-dialog
  [header]="title"
  [(visible)]="visible"
  (onHide)="onClose()"
  [modal]="true"
  [style]="{ minWidth: '450px' }"
  [draggable]="false"
  [resizable]="false">
  <form
    class="form padding-form"
    [formGroup]="form"
    (ngSubmit)="onCreate()"
    *ngIf="form">
    <div class="margin-buttom">
      <label
        htmlFor="question"
        class="font-weight-bold">
        {{ "ADMIN.FAQ.QUESTION" | translate }}
      </label>
    </div>
    <div>
      <input
        pInputText
        id="question"
        aria-describedby="question"
        formControlName="question"
        class="input-form form-input" />
    </div>
    <div class="margin-buttom">
      <label
        htmlFor="answer"
        class="font-weight-bold">
        {{ "ADMIN.FAQ.ANSWER" | translate }}
      </label>
    </div>
    <div>
      <textarea
        pInputTextarea
        id="answer"
        aria-describedby="answer"
        formControlName="answer"
        rows="5"
        cols="30"
        class="input-form form-input"></textarea>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="footer-content">
      <div>
        <button
          pButton
          label="{{ 'COMMON.CANCEL' | translate }}"
          class="p-button-rounded p-button-outlined large-btn"
          (click)="onClose()"
          [disabled]="loading"></button>
      </div>
      <div>
        <button
          pButton
          [disabled]="!form.valid"
          class="buttom-btn large-btn"
          (click)="onCreate()"
          label="{{ 'ADMIN.FAQ.UPLOAD' | translate }}"
          [disabled]="loading || !form.valid"
          *ngIf="!isUpdate"></button>
        <button
          pButton
          [disabled]="!form.valid"
          class="buttom-btn large-btn"
          (click)="onUpdate()"
          label="{{ 'ADMIN.FAQ.EDIT' | translate }}"
          [disabled]="loading || !form.valid"
          *ngIf="isUpdate"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
