<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.PRODUCTS.TITLE" | translate }}
    </ng-container>
    <ng-container content>
      <p-card class="product-card">
        <div class="table-content">
          <div class="header-container">
            <div class="header-content">
              <span
                class="p-input-icon-left search-product"
                [ngClass]="{ 'p-input-icon-right': searchLoading }">
                <i class="pi pi-search"></i>
                <input
                  class="search-table-input"
                  pInputText
                  type="text"
                  [placeholder]="'ADMIN.PRODUCTS.SEARCH_MEMBERS' | translate"
                  [(ngModel)]="productFilter.searchString"
                  (ngModelChange)="onSearch()" />
                <i
                  *ngIf="searchLoading"
                  class="pi pi-spin pi-spinner"></i>
              </span>
            </div>
          </div>
          <div class="">
            <div class="card">
              <p-table
                #productsTable
                [lazy]="true"
                [loading]="loading"
                [value]="products"
                [tableStyle]="{ 'min-width': '60rem' }"
                [paginator]="true"
                [(first)]="page"
                (onLazyLoad)="onLazyLoadProducts($event)"
                [rows]="productFilter.limit"
                [totalRecords]="totalRecords">
                <ng-template pTemplate="header">
                  <tr>
                    <th
                      pSortableColumn="name"
                      class="column-name">
                      {{ "ADMIN.PRODUCTS.NAME" | translate }} <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th
                      pSortableColumn="isin"
                      class="column-isin">
                      {{ "ADMIN.PRODUCTS.ISIN" | translate }} <p-sortIcon field="isin"></p-sortIcon>
                    </th>
                    <th
                      style="width: 20%"
                      class="custom-filter column-category"
                      #customFilter>
                      <div class="filter-row">
                        {{ "ADMIN.PRODUCTS.CATEGORY" | translate }}
                        <p-overlayPanel #filterPanel>
                          <ng-template pTemplate="content">
                            <gpta-overlay-filter
                              [text]="textOverlayFilter"
                              [sortValue]="productFilter.sortOrder"
                              [sortOptions]="sortCategory"
                              [options]="categoryOptions"
                              [optionsSelected]="categoriesSelected"
                              (removeEmitter)="removeEmmiter($event, filterPanel, productsTable)"
                              (applyEmitter)="applyEmitter($event, filterPanel, productsTable)"></gpta-overlay-filter>
                          </ng-template>
                        </p-overlayPanel>
                        <button
                          class="p-ripple p-element p-button p-component p-button-secondary p-button-icon-only p-button-rounded p-button-text filter-btn"
                          pButton
                          type="button"
                          (click)="filterPanel.toggle($event)">
                          <i class="pi pi-filter"></i>
                        </button>
                      </div>
                    </th>
                    <th
                      pSortableColumn="isFavourite"
                      class="column-favourite">
                      {{ "ADMIN.PRODUCTS.FAVORITE" | translate }} <p-sortIcon field="isFavourite"></p-sortIcon>
                    </th>
                    <th
                      pSortableColumn="isFollowed"
                      class="column-followed">
                      {{ "ADMIN.PRODUCTS.FOLLOWING" | translate }} <p-sortIcon field="isFollowed"></p-sortIcon>
                    </th>
                    <th
                      class="column-settings"
                      *ngIf="false"></th>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="body"
                  let-product>
                  <tr>
                    <td class="bold">{{ product.name }}</td>
                    <td>{{ product.isin }}</td>
                    <td>{{ product.category }}</td>
                    <td>
                      <div style="display: grid; justify-content: space-around">
                        <p-checkbox
                          class="product-table-checkbox"
                          [(ngModel)]="product.isFavourite"
                          [binary]="true"
                          (click)="onCheckChanges(product)"></p-checkbox>
                      </div>
                    </td>
                    <td>
                      <div style="display: grid; justify-content: space-around">
                        <p-checkbox
                          class="product-table-checkbox"
                          [(ngModel)]="product.isFollowed"
                          [binary]="true"
                          (click)="onCheckChanges(product)"></p-checkbox>
                      </div>
                    </td>
                    <td *ngIf="false">
                      <button
                        pButton
                        class="lang-nav-btn p-button-text p-button-sm settings-btn"
                        [disabled]="true">
                        <i class="pi pi-ellipsis-v"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </p-card>
    </ng-container>
  </gpta-admin-container>
  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
</div>
