<div class="sources-container">
  <span class="sources-title">{{ "CHAT.SOURCES.TITLE" | translate }}</span>
  <div
    *ngFor="let source of sources"
    class="source">
    <div
      class="pdf"
      *ngIf="source.filePath">
      <!-- eslint-disable @angular-eslint/template/interactive-supports-focus-->
      <!-- eslint-disable @angular-eslint/template/click-events-have-key-events-->
      <span (click)="onCLickSource(source)"
        >{{ source.name }}{{ source.name && source.filename ? " - " : "" }}{{ source.filename | truncateLength: 35
        }}<ng-container *ngIf="source.filename?.length > 35">.pdf</ng-container></span
      >
    </div>
    <div
      class="faq"
      *ngIf="!source.filePath && source.name">
      <span>{{ source.name }}</span>
    </div>
  </div>
</div>
