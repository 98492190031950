import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Suggestion } from '~app/models/suggestion';
import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  #url: string;

  containerScroll$ = new Subject<Event>();

  private followUpList: Suggestion[] = [];

  constructor(private http: HttpClient) {
    this.#url = environment.APP_API_URL;
  }

  getFollowUpById(conversationId: string) {
    this.http.get<string[]>(`${this.#url}/api/conversations/${conversationId}/follow-up`).subscribe({
      next: (suggestions) => {
        this.followUpList = suggestions.map((suggestion, index) => ({ id: index, text: suggestion }));
      },
      error: () => {
        this.followUpList = [];
      },
    });
  }

  getInitialFollowUp() {
    this.http.get<string[]>(`${this.#url}/api/conversations/starting-follow-up`).subscribe({
      next: (suggestions) => {
        this.followUpList = suggestions.map((suggestion, index) => ({ id: index, text: suggestion }));
      },
      error: () => {
        this.followUpList = [];
      },
    });
  }

  getFollowUpList(): Suggestion[] {
    return this.followUpList;
  }

  setFollowUpList(followUpList: Suggestion[]): void {
    this.followUpList = followUpList;
  }
}
