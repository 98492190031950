import { Component } from '@angular/core';

@Component({
  selector: 'gpta-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {

}
