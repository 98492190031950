// detect scren size helper
import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import WindowProperties from '~app/models/window-prop';
import config from '~config/constants';

@Injectable({
  providedIn: 'root',
})

export class WindowSizeHelper {
  private windowProperties: BehaviorSubject<WindowProperties>;

  constructor() {
    this.windowProperties = new BehaviorSubject<WindowProperties>({
      windowWidth: window.innerWidth,
      isMobile: false,
    });

    this.windowProperties.next({
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < config.appConfig.MOBILE_WIDTH,
    });

    window.onresize = () => {
      this.windowProperties.next({
        windowWidth: window.innerWidth,
        isMobile: window.innerWidth < config.appConfig.MOBILE_WIDTH,
      });
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowProperties.next({
      windowWidth: event.target.innerWidth,
      isMobile: event.target.innerWidth < config.appConfig.MOBILE_WIDTH,
    });
  }

  public isMobile() {
    return this.windowProperties.pipe(map((properties) => properties.isMobile));
  }

  public getWindowProperties(): Observable<WindowProperties> {
    return this.windowProperties.asObservable();
  }
}
