import {
  Colors, Fonts, Images, Sizes, Styles,
} from '~app/models/organization';

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : {};
}

function applyOpacityToColor(color: string, opacity: number) {
  const { r, g, b } = hexToRgb(color);
  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  return rgba;
}
function applyCustomColors(colors: Colors) {
  const styleElement = document.documentElement.style;
  if (colors.primaryColor) {
    styleElement.setProperty('--primary-color', colors.primaryColor);
    styleElement.setProperty('--primary-color-10', applyOpacityToColor(colors.primaryColor, 0.1));
  }
  if (colors.primaryColorDark) styleElement.setProperty('--primary-color-dark', colors.primaryColorDark);
  if (colors.secondaryColor) styleElement.setProperty('--secondary-color', colors.secondaryColor);
  if (colors.grey) styleElement.setProperty('--grey', colors.grey);
  if (colors.lightGrey) styleElement.setProperty('--light-grey', colors.lightGrey);
  if (colors.lighterGrey) styleElement.setProperty('--lighter-grey', colors.lighterGrey);
  if (colors.shadowsColor) styleElement.setProperty('--shadows-color', colors.shadowsColor);
  if (colors.fontColor) styleElement.setProperty('--font-color', colors.fontColor);
  if (colors.linkColor) styleElement.setProperty('--link-color', colors.linkColor);
  if (colors.suggestionsFontColor) styleElement.setProperty('--suggestions-font-color', colors.suggestionsFontColor);
  if (colors.loadingColor) styleElement.setProperty('--loading-color', colors.loadingColor);
  if (colors.leftMessageColor) styleElement.setProperty('--left-message-color', colors.leftMessageColor);
  if (colors.rightMessageColor) styleElement.setProperty('--right-message-color', colors.rightMessageColor);
  if (colors.userNavBtnColor) styleElement.setProperty('--user-nav-btn-color', colors.userNavBtnColor);
  if (colors.conversationNewBorderColor) {
    styleElement.setProperty('--conversation-new-border-color', colors.conversationNewBorderColor);
  }

  if (colors.conversationMenuMainColor) {
    styleElement.setProperty('--conversation-menu-main-color', colors.conversationMenuMainColor);
  }

  if (colors.conversationMenuBtnBorderColor) {
    styleElement.setProperty('--conversation-menu-btn-border-color', colors.conversationMenuBtnBorderColor);
  }

  if (colors.conversationMenuBtnColor) {
    styleElement.setProperty('--conversation-menu-btn-color', colors.conversationMenuBtnColor);
  }
}

function applyCustomImages(images: Images) {
  const styleElement = document.documentElement.style;
  if (images.bodyBg) styleElement.setProperty('--body-bg', images.bodyBg);
  if (images.topBarBg) styleElement.setProperty('--top-bar-bg', images.topBarBg);
  if (images.conversationBg) styleElement.setProperty('--conversation-bg', images.conversationBg);
  if (images.conversationMenuBg) styleElement.setProperty('--conversation-menu-bg', images.conversationMenuBg);
  if (images.conversationNewBg) styleElement.setProperty('--conversation-new-bg', images.conversationNewBg);
  if (images.loginLogo) styleElement.setProperty('--login-logo', images.loginLogo);
  if (images.leftMessageBg) styleElement.setProperty('--left-message-bg', images.leftMessageBg);
  if (images.rightMessageBg) styleElement.setProperty('--right-message-bg', images.rightMessageBg);
  if (images.rightMessageLogo) styleElement.setProperty('--right-message-logo', images.rightMessageLogo);
  if (images.backdropBg) styleElement.setProperty('--backdrop-bg', images.backdropBg);
  if (images.welcomeImageUrl) styleElement.setProperty('--welcome-image-url', images.welcomeImageUrl);
  if (images.entityLogoUrl) styleElement.setProperty('--entity-logo-url', images.entityLogoUrl);
  if (images.entityIframeLogoUrl) styleElement.setProperty('--entity-iframe-logo-url', images.entityIframeLogoUrl);
  if (images.conversationMenuBtnBg) {
    styleElement.setProperty('--conversation-menu-btn-bg', images.conversationMenuBtnBg);
  }
  if (images.suggestionsBg) styleElement.setProperty('--suggestions-bg', images.suggestionsBg);
  if (images.tableHeaderBgActive) styleElement.setProperty('--table-header-bg-active', images.tableHeaderBgActive);
  if (images.selectBgActive) styleElement.setProperty('--select-bg-active', images.selectBgActive);
  if (images.settingsDropdownBtnBg) {
    styleElement.setProperty('--settings-dropdown-btn-bg', images.settingsDropdownBtnBg);
  }
  if (images.tablePaginatorBgActive) {
    styleElement.setProperty('--table-paginator-bg-active', images.tablePaginatorBgActive);
  }
  if (images.outlineBtnBg) styleElement.setProperty('--outline-btn-bg', images.outlineBtnBg);
}

function applyCustomFonts(fonts: Fonts) {
  const styleElement = document.documentElement.style;
  if (fonts.fontFamilyRegular) styleElement.setProperty('--font-family-regular', fonts.fontFamilyRegular);
  if (fonts.fontFamilyLight) styleElement.setProperty('--font-family-light', fonts.fontFamilyLight);
  if (fonts.fontFamilyBold) styleElement.setProperty('--font-family-bold', fonts.fontFamilyBold);
  if (fonts.fontFamilyRegularUrl) styleElement.setProperty('--font-family-regular-url', fonts.fontFamilyRegularUrl);
  if (fonts.fontFamilyLightUrl) styleElement.setProperty('--font-family-light-url', fonts.fontFamilyLightUrl);
  if (fonts.fontFamilyBoldUrl) styleElement.setProperty('--font-family-bold-url', fonts.fontFamilyBoldUrl);
  if (fonts.fontSize) styleElement.setProperty('--font-size', fonts.fontSize);
}

function applyCustomSizes(sizes: Sizes) {
  const styleElement = document.documentElement.style;
  if (sizes.mainTopMargin) styleElement.setProperty('--main-top-margin', sizes.mainTopMargin);
  if (sizes.mainContainerWidth) styleElement.setProperty('--main-container-width', sizes.mainContainerWidth);
  if (sizes.conversationTopBarHeight) {
    styleElement.setProperty('--conversation-top-bar-height', sizes.conversationTopBarHeight);
  }
}

export function applyCustomStyles(styles: Styles) {
  const {
    colors, images, sizes, fonts,
  } = styles;
  if (colors) applyCustomColors(colors);
  if (images) applyCustomImages(images);
  if (sizes) applyCustomSizes(sizes);
  if (fonts) applyCustomFonts(fonts);
}

function removeCustomColors(colors: Colors) {
  const styleElement = document.documentElement.style;
  if (colors.primaryColor) {
    styleElement.removeProperty('--primary-color');
    styleElement.removeProperty('--primary-color-10');
  }
  if (colors.primaryColorDark) styleElement.removeProperty('--primary-color-dark');
  if (colors.secondaryColor) styleElement.removeProperty('--secondary-color');
  if (colors.grey) styleElement.removeProperty('--grey');
  if (colors.lightGrey) styleElement.removeProperty('--light-grey');
  if (colors.lighterGrey) styleElement.removeProperty('--lighter-grey');
  if (colors.shadowsColor) styleElement.removeProperty('--shadows-color');
  if (colors.fontColor) styleElement.removeProperty('--font-color');
  if (colors.linkColor) styleElement.removeProperty('--link-color');
  if (colors.suggestionsFontColor) styleElement.removeProperty('--suggestions-font-color');
  if (colors.loadingColor) styleElement.removeProperty('--loading-color');
  if (colors.leftMessageColor) styleElement.removeProperty('--left-message-color');
  if (colors.rightMessageColor) styleElement.removeProperty('--right-message-color');
  if (colors.userNavBtnColor) styleElement.removeProperty('--user-nav-btn-color');
  if (colors.conversationNewBorderColor) {
    styleElement.removeProperty('--conversation-new-border-color');
  }

  if (colors.conversationMenuMainColor) {
    styleElement.removeProperty('--conversation-menu-main-color');
  }

  if (colors.conversationMenuBtnBorderColor) {
    styleElement.removeProperty('--conversation-menu-btn-border-color');
  }

  if (colors.conversationMenuBtnColor) {
    styleElement.removeProperty('--conversation-menu-btn-color');
  }
}

function removeCustomImages(images: Images) {
  const styleElement = document.documentElement.style;
  if (images.bodyBg) styleElement.removeProperty('--body-bg');
  if (images.topBarBg) styleElement.removeProperty('--top-bar-bg');
  if (images.conversationBg) styleElement.removeProperty('--conversation-bg');
  if (images.conversationMenuBg) styleElement.removeProperty('--conversation-menu-bg');
  if (images.conversationNewBg) styleElement.removeProperty('--conversation-new-bg');
  if (images.loginLogo) styleElement.removeProperty('--login-logo');
  if (images.leftMessageBg) styleElement.removeProperty('--left-message-bg');
  if (images.rightMessageBg) styleElement.removeProperty('--right-message-bg');
  if (images.rightMessageLogo) styleElement.removeProperty('--right-message-logo');
  if (images.backdropBg) styleElement.removeProperty('--backdrop-bg');
  if (images.welcomeImageUrl) styleElement.removeProperty('--welcome-image-url');
  if (images.entityLogoUrl) styleElement.removeProperty('--entity-logo-url');
  if (images.entityIframeLogoUrl) styleElement.removeProperty('--entity-iframe-logo-url');
  if (images.conversationMenuBtnBg) {
    styleElement.removeProperty('--conversation-menu-btn-bg');
  }
  if (images.suggestionsBg) styleElement.removeProperty('--suggestions-bg');
  if (images.tableHeaderBgActive) styleElement.removeProperty('--table-header-bg-active');
  if (images.selectBgActive) styleElement.removeProperty('--select-bg-active');
  if (images.settingsDropdownBtnBg) {
    styleElement.removeProperty('--settings-dropdown-btn-bg');
  }
  if (images.tablePaginatorBgActive) {
    styleElement.removeProperty('--table-paginator-bg-active');
  }
  if (images.outlineBtnBg) styleElement.removeProperty('--outline-btn-bg');
}

function removeCustomFonts(fonts: Fonts) {
  const styleElement = document.documentElement.style;
  if (fonts.fontFamilyRegular) styleElement.removeProperty('--font-family-regular');
  if (fonts.fontFamilyLight) styleElement.removeProperty('--font-family-light');
  if (fonts.fontFamilyBold) styleElement.removeProperty('--font-family-bold');
  if (fonts.fontFamilyRegularUrl) styleElement.removeProperty('--font-family-regular-url');
  if (fonts.fontFamilyLightUrl) styleElement.removeProperty('--font-family-light-url');
  if (fonts.fontFamilyBoldUrl) styleElement.removeProperty('--font-family-bold-url');
  if (fonts.fontSize) styleElement.removeProperty('--font-size');
}

function removeCustomSizes(sizes: Sizes) {
  const styleElement = document.documentElement.style;
  if (sizes.mainTopMargin) styleElement.removeProperty('--main-top-margin');
  if (sizes.mainContainerWidth) styleElement.removeProperty('--main-container-width');
  if (sizes.conversationTopBarHeight) {
    styleElement.removeProperty('--conversation-top-bar-height');
  }
}

export function removeCustomStyles(styles: Styles) {
  const {
    colors, images, sizes, fonts,
  } = styles;
  if (colors) removeCustomColors(colors);
  if (images) removeCustomImages(images);
  if (sizes) removeCustomSizes(sizes);
  if (fonts) removeCustomFonts(fonts);
}
