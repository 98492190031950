import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';

import { USER_ROLES } from '~app/models/roles.enum';
import { IframeHelper } from '~helpers/iframe.helper';
import { WindowSizeHelper } from '~helpers/screen-size.helper';
import { PartialComponentAction } from '~models/component-action';
import WindowProperties from '~models/window-prop';
import { ApiService } from '~services/api/api.service';

// avoid selecteion on firts open
const emptyMenuItem: MenuItem = {
  label: '',
  visible: false,
};

@Component({
  selector: 'gpta-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  @Input() isAdminPage = false;

  iconName = 'search.svg';

  userMenuItems: MenuItem[] | undefined;

  langMenuItems: MenuItem[] | undefined;

  langSelected: string | undefined;

  languageSubscription: Subscription;

  isAdmin = false;

  manageItems: MenuItem[] = [];

  public windowProperties!: Observable<WindowProperties>;

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    public windowSizeHelper: WindowSizeHelper,
    private iframeHelper:IframeHelper,
    private router: Router,
  ) {
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.setMenuItems();
    });

    this.windowProperties = this.windowSizeHelper.getWindowProperties();
  }

  async ngOnInit() {
    const userData = await this.apiService.getUserData();
    this.isAdmin = userData.role === USER_ROLES.ADMIN;
    this.langSelected = userData.language;
    this.setMenuItems();
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }

  entityImageHandler() {
    this.iframeHelper.sendMessageToParent({
      name: 'entityImageClicked',
      data: null,
    } as PartialComponentAction);
  }

  private setMenuItems() {
    this.userMenuItems = [
      emptyMenuItem,
      // {
      //   label: this.translateService.instant('MENU.USER.MY_PROFILE'),
      //   routerLink: ['/me'],
      //   routerLinkActiveOptions: { exact: true },
      // },
      {
        label: this.translateService.instant('MENU.USER.SIGN_OUT'),
        icon: 'pi pi-fw pi-sign-out',
        routerLink: ['/login'],
        command: () => {
          this.logout();
        },
      },
    ];

    this.manageItems = [
      {
        icon: '../../../assets/images/administration-panel/group.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.USERS'),
        url: 'admin/users',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/file.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.DOCUMENTS'),
        url: 'admin/docs',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/suitcase.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.PRODUCTS'),
        url: 'admin/products',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/donut-chart.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.ANALYTICS'),
        url: 'admin/analytics',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/database.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.DATA_PROVIDER'),
        url: '/data-provider',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/technology.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.AI_SETTINGS'),
        url: '/ai-settings',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/palette.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.STYLES'),
        url: 'admin/styles',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/trend.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.RISK_PROFILES'),
        url: 'risk-profiles',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/list.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.CHAT_LOG'),
        url: 'admin/chat-log',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/domain.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.BROWSING'),
        url: '/browsing',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/compliance.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.COMPLIANCE'),
        url: 'admin/compliance',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/green-earth.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.ESG'),
        url: '/ESG',
        disabled: false,
      },
      {
        icon: '../../../assets/images/administration-panel/faq.svg',
        label: this.translateService.instant('MENU.ADMINISTRATION.FAQ'),
        url: 'admin/faqs',
        disabled: false,
      },
    ];

    this.langMenuItems = [
      emptyMenuItem,
      {
        label: 'en',
        tabindex: '2',
        command: (e) => {
          const label = e?.item?.label as string;
          this.changeLang(label);
        },
      },
      {
        label: 'es',
        tabindex: '0',
        command: (e) => {
          const label = e?.item?.label as string;
          this.changeLang(label);
        },
      },
    ];
  }

  goToUrl(url = ''): void {
    this.router.navigate([url]);
  }

  private logout() {
    this.apiService.signOut();
  }

  private changeLang(isoCode: string) {
    this.langSelected = isoCode;
    this.translateService.use(isoCode);
    this.apiService.udpateUser({ language: isoCode });
  }
}
