<div class="color-picker-input">
  <button
    pButton
    [id]="idx ? 'colorDisplay' + idx : null"
    style="width: 32px; height: 32px; border-radius: 6px; cursor: pointer"
    [ngStyle]="{ 'background-color': color.value }"
    (click)="op.toggle($event)">
    <div></div>
  </button>
  <input
    type="text"
    pInputText
    class="color-input"
    [placeholder]="placeHolder"
    [(ngModel)]="color.value" />
  <p-overlayPanel #op>
    <ng-template pTemplate="content">
      <div class="color-picker-panel">
        <label for="">{{ "COMMON.COLOR_PICKER.COLOR" | translate }}</label>
        <input
          type="color"
          id="colorPicker"
          class="color-picker-btn"
          [ngStyle]="{ 'background-color': getHexColor(color.value) }"
          [value]="getHexColor(color.value)"
          (input)="changeColorInput($event, 'color', color)" />
        <label for="">{{ "COMMON.COLOR_PICKER.ALPHA" | translate }}</label>
        <input
          type="range"
          id="alphaPicker"
          class="alpha-picker-btn"
          min="0"
          max="1"
          step="0.01"
          [value]="getAlphaColor(color.value)"
          (input)="changeColorInput($event, 'alpha', color)" />
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
