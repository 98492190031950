import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';

import { ComponentsModule } from '~app/components/components.module';

import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { DocumentsComponent } from './documents.component';
import { DocumentsRoutingModule } from './documents-routing.module';

@NgModule({
  declarations: [
    DocumentsComponent,
    FileUploadModalComponent,
  ],
  imports: [
    ButtonModule,
    CardModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    FormsModule,
    FileUploadModule,
    CommonModule,
    ComponentsModule,
    MultiSelectModule,
    SelectButtonModule,
    DocumentsRoutingModule,
    OverlayPanelModule,
    TableModule,
    DialogModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class DocumentsModule { }
