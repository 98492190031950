import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { hasUserDataGuard } from '~guards/has-user-data.guard';
import { isLoggedGuard } from '~guards/is-logged.guard';

import { ChatComponent } from './chat.component';

const routes: Routes = [
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [isLoggedGuard, hasUserDataGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'chat/:conversationId',
    component: ChatComponent,
    canActivate: [isLoggedGuard, hasUserDataGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ChatRoutingModule { }
