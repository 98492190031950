import {
  Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable } from 'rxjs';

import { SEARCH_DELAY } from '~app/config/constants/search';
import {
  Category,
  ProductCategoryOptions,
  ProductCateogrySort,
} from '~app/models/category';
import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { TextOverlayFilter } from '~app/models/overlay-filter';
import {
  OrganizationProductElement,
  ProductUpdate,
  SearchProductsDto,
} from '~app/models/products';
import { ApiService } from '~app/services/api/api.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';
import { ProductsService } from '~app/services/products/products.service';

@Component({
  selector: 'gpta-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @ViewChild('customFilter') customFilter: ElementRef | undefined;

  public products$: Observable<any>;

  public products: OrganizationProductElement[] = [];

  public productFilter: SearchProductsDto = {
    searchString: '', categories: [], skip: 0, limit: 10, sortField: '', sortOrder: 1,
  };

  public categoryOptions$: Observable<any>;

  public categoryOptions: ProductCategoryOptions[] = [];

  public categoriesSelected: string[] = [];

  public sortCategory: ProductCateogrySort[] = [{ label: '', value: 1 }, { label: '', value: -1 }];

  public buttonValue = 1;

  public totalRecords = 0;

  public page = 0;

  public loading = false;

  public typingTimer: any;

  public searchDelay = SEARCH_DELAY;

  public searchLoading = false;

  public textOverlayFilter: TextOverlayFilter = {
    sortTtile: 'ADMIN.PRODUCTS.SORT',
    selectTitle: 'ADMIN.PRODUCTS.FILTER',
    selectPlaceholder: 'ADMIN.PRODUCTS.FILTER_ALL',
    clearBtn: 'ADMIN.PRODUCTS.CLEAR',
    applyBtn: 'ADMIN.PRODUCTS.APPLY',
  };

  constructor(
    private productsService: ProductsService,
    private translateService: TranslateService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.products$ = this.productsService.productList$;
    this.categoryOptions$ = this.productsService.categoryList$;
    this.translateService.stream('ADMIN.PRODUCTS.SORT_ASC')
      .subscribe((res: any) => {
        this.sortCategory[0].label = res;
      });
    this.translateService.stream('ADMIN.PRODUCTS.SORT_DESC')
      .subscribe((res: any) => {
        this.sortCategory[1].label = res;
      });
  }

  ngOnInit() {
    this.getProductsList();
    this.getCategoryList();
    this.products$.subscribe(
      (
        res: {
          organizationProducts: OrganizationProductElement[],
          total: number
        },
      ) => {
        if (res?.organizationProducts) this.products = res.organizationProducts;
        if (res?.total) this.totalRecords = res.total;
        this.loading = false;
        this.searchLoading = false;
      },
    );
    this.categoryOptions$.subscribe(
      (
        res: {
          categories: Category[],
          total: number
        },
      ) => {
        if (res?.categories) this.categoryOptions = res.categories.map(({ name }) => ({ name, value: name }));
      },
    );
  }

  getProductsList() {
    this.loading = true;
    this.productsService.getProductList(this.productFilter);
  }

  getCategoryList() {
    this.loading = true;
    this.productsService.getCategoryList();
  }

  onLazyLoadProducts(event: TableLazyLoadEvent) {
    this.productFilter.skip = event.first ? event.first : 0;
    if (event.sortField) this.productFilter.sortField = event.sortField as string;
    if (event.sortOrder) this.productFilter.sortOrder = event.sortOrder;
    if (this.productFilter.sortField !== 'category'
      && this.customFilter !== undefined
      && this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getProductsList();
  }

  onSearch() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchLoading = true;
      this.removeFilter(true);
      this.page = 0;
      this.getProductsList();
    }, this.searchDelay);
  }

  onCheckChanges(product: OrganizationProductElement) {
    this.setProductById(
      // eslint-disable-next-line no-underscore-dangle
      product._id,
      {
        isFavourite: product.isFavourite,
        isFollowed: product.isFollowed,
      },
    );
  }

  setProductById(id: string, data: ProductUpdate) {
    this.productsService.patchProductById(id, data).subscribe({
      next: (res) => {
        // eslint-disable-next-line no-underscore-dangle
        const index = this.products.findIndex((product) => product._id === res._id);
        this.products[index] = res;
      },
      error: () => {
        // eslint-disable-next-line no-underscore-dangle
        const index = this.products.findIndex((product) => product._id === id);
        this.products[index].isFavourite = !data.isFavourite;
        this.products[index].isFollowed = !data.isFollowed;
      },
    });
  }

  setCategoryFilter(event:any) {
    const categoriesList = event.map(({ name }:any) => name);
    this.productFilter.categories = categoriesList;
  }

  removeFilter(isSearch: boolean) {
    if (!isSearch) this.productFilter.searchString = '';
    this.productFilter.categories = [];
    this.productFilter.skip = 0;
    if (!isSearch) this.productFilter.sortField = '';
    if (!isSearch) this.productFilter.sortOrder = 1;
    this.categoriesSelected = [];
    if (this.customFilter !== undefined
      && this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getProductsList();
  }

  applyFilter(productTable: any) {
    const { sortOrder } = this.productFilter;
    productTable.clear();
    this.productFilter.sortField = 'category';
    this.productFilter.searchString = '';
    this.productFilter.sortOrder = sortOrder;
    if (this.customFilter !== undefined
      && !this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getProductsList();
  }

  removeEmmiter(event: any, filterPanel: any, productsTable: any) {
    this.removeFilter(false);
    filterPanel.toggle(new Event('click'));
    productsTable.clear();
  }

  applyEmitter(event: any, filterPanel: any, productsTable: any) {
    this.categoriesSelected = event.typesSelected;
    this.productFilter.categories = event.optionsSelected;
    this.productFilter.sortOrder = event.sortValue;
    this.applyFilter(productsTable);
    filterPanel.toggle(new Event('click'));
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.apiService.setNextConversation({});
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate(['/chat', conversation._id]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
