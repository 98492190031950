import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { ComponentAction } from '~app/models/component-action';
import { createDoAction } from '~app/services/helpers/componentAction.helper';
import { Conversation } from '~models/conversation';
import { CONVERSATION_CONTEXT_ACTIONS } from '~models/conversation-action.enum';

const emptyMenuItem: MenuItem = {
  label: '',
  visible: false,
};
@Component({
  selector: 'gpta-chat-list-item-context-menu',
  templateUrl: './chat-list-item-context-menu.component.html',
  styleUrls: ['./chat-list-item-context-menu.component.scss'],
})
export class ChatListItemContextMenuComponent implements OnInit {
  @Input() chat: Conversation | null = null;

  @Input() isLoading = false;

  @Output() doAction = new EventEmitter();

  private addFavoriteMenuOption: MenuItem = {
    label: this.translateService.instant('CHAT.SIDE_BAR.CONTEXT_MENU.ADD_TO_FAVORITES'),
    command: this.emmitType(CONVERSATION_CONTEXT_ACTIONS.ADD_TO_FAVORITES),
  };

  private removeFavoriteMenuOption: MenuItem = {
    label: this.translateService.instant('CHAT.SIDE_BAR.CONTEXT_MENU.REMOVE_FROM_FAVORITES'),
    command: this.emmitType(CONVERSATION_CONTEXT_ACTIONS.REMOVE_FROM_FAVORITES),
  };

  menuOptions: MenuItem[] = [];

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const favoriteMenuOption = this.chat?.isFavourite ? this.removeFavoriteMenuOption : this.addFavoriteMenuOption;
    const renameMenuOption: MenuItem = {
      label: this.translateService.instant('CHAT.SIDE_BAR.CONTEXT_MENU.RENAME'),
      command: this.emmitType(CONVERSATION_CONTEXT_ACTIONS.RENAME),
    };
    const deleteMenuOption: MenuItem = {
      label: this.translateService.instant('CHAT.SIDE_BAR.CONTEXT_MENU.DELETE'),
      command: this.emmitType(CONVERSATION_CONTEXT_ACTIONS.DELETE),
    };
    this.menuOptions = [
      emptyMenuItem,
      renameMenuOption,
      favoriteMenuOption,
      deleteMenuOption,
    ];
  }

  private emmitType(emmitType:CONVERSATION_CONTEXT_ACTIONS): (event: any) => void {
    return () => {
      if (this.isLoading) return;
      const action: ComponentAction = createDoAction({
        name: 'context-menu-action',
        data: {
          type: emmitType,
          chat: this.chat,
        },
      });
      this.doAction.emit(action);
    };
  }
}
