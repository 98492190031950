import { CONVERSATION_DATE_FIELDS } from '~models/conversation';

export default {
  MUST_AUTOGENERATE_TITLE: false,
  TITTLE_MAX_LENGTH: 100,
  TITTLE_MIN_LENGTH: 3,
  LIST_GROUP_DATE_FIELD: CONVERSATION_DATE_FIELDS.MODIFICATION_DATE,
  NEW_MESSAGE_ANIMATION_TIMES: {
    DEFAULT: 500,
    BY_CHAR: 5,
    MAX_BY_MATCH: 1000,
    CHART: 800,
    TABLE: 800,
    CARD: 800,
  },
  CURRENCY: {
    EUR: 'EUR',
    USD: 'USD',
  },
};
