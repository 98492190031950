<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.DOCUMENTS.TITLE" | translate }}
    </ng-container>
    <ng-container side>
      <button
        pButton
        class="p-button-rounded p-button-sm large-btn"
        (click)="createNewDocument()">
        {{ "ADMIN.DOCUMENTS.ADD" | translate }}
      </button>
    </ng-container>
    <ng-container content>
      <p-card class="document-card">
        <div class="table-content">
          <div class="header-container">
            <div class="header-search-container">
              <div>
                <span
                  class="p-input-icon-left search-document"
                  [ngClass]="{ 'p-input-icon-right': searchLoading }">
                  <i class="pi pi-search"></i>
                  <input
                    class="search-table-input"
                    pInputText
                    type="text"
                    [placeholder]="'ADMIN.DOCUMENTS.SEARCH_DOCUMENTS' | translate"
                    [(ngModel)]="documentFilter.searchString"
                    (ngModelChange)="onSearch()" />
                  <i
                    *ngIf="searchLoading"
                    class="pi pi-spin pi-spinner"></i>
                </span>
              </div>
            </div>
            <div class="header-checkbox">
              <button
                class="p-button-rounded p-button-outlined"
                type="button"
                (click)="toggleActiveStateFilteredDocments()"
                pButton>
                {{
                  areAllActive
                    ? ("ADMIN.DOCUMENTS.DEACTIVE_ALL" | translate)
                    : ("ADMIN.DOCUMENTS.ACTIVE_ALL" | translate)
                }}
              </button>
            </div>
          </div>
          <div class="">
            <div class="card">
              <p-table
                #documentsTable
                [lazy]="true"
                [loading]="loading"
                [value]="documents"
                [tableStyle]="{ 'min-width': '60rem' }"
                [paginator]="true"
                [(first)]="page"
                (onLazyLoad)="onLazyLoadDocuments($event)"
                [rows]="documentFilter.limit"
                [totalRecords]="totalRecords">
                <ng-template pTemplate="header">
                  <tr>
                    <th
                      pSortableColumn="active"
                      class="column-active">
                      {{ "ADMIN.DOCUMENTS.ACTIVE" | translate }} <p-sortIcon field="active"></p-sortIcon>
                    </th>
                    <th
                      pSortableColumn="name"
                      class="column-name">
                      {{ "ADMIN.DOCUMENTS.NAME" | translate }} <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th
                      class="custom-filter column-type"
                      #customFilter>
                      <div class="filter-row">
                        {{ "ADMIN.DOCUMENTS.TYPE" | translate }}
                        <p-overlayPanel #filterPanel>
                          <ng-template pTemplate="content">
                            <gpta-overlay-filter
                              [text]="textOverlayFilter"
                              [sortValue]="documentFilter.sortOrder"
                              [sortOptions]="sortType"
                              [options]="typesOptions"
                              [optionsSelected]="typesSelected"
                              (removeEmitter)="removeEmmiter($event, filterPanel, documentsTable)"
                              (applyEmitter)="applyEmitter($event, filterPanel, documentsTable)"></gpta-overlay-filter>
                          </ng-template>
                        </p-overlayPanel>
                        <button
                          class="p-ripple p-element p-button p-component p-button-secondary p-button-icon-only p-button-rounded p-button-text filter-btn"
                          pButton
                          type="button"
                          (click)="filterPanel.toggle($event)">
                          <i class="pi pi-filter"></i>
                        </button>
                      </div>
                    </th>
                    <th
                      pSortableColumn="productCodes"
                      class="column-codes">
                      {{ "ADMIN.DOCUMENTS.CODE" | translate }} <p-sortIcon field="productCodes"></p-sortIcon>
                    </th>
                    <th
                      pSortableColumn="creationDate"
                      class="column-date">
                      {{ "ADMIN.DOCUMENTS.UPLOAD" | translate }} <p-sortIcon field="creationDate"></p-sortIcon>
                    </th>
                    <th class="column-settings"></th>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="body"
                  let-document>
                  <tr>
                    <td>
                      <div>
                        <p-checkbox
                          class="document-table-checkbox"
                          [(ngModel)]="document.isActive"
                          [binary]="true"
                          (click)="onCheckChanges(document)"></p-checkbox>
                      </div>
                    </td>
                    <td class="bold">
                      <a
                        class="link"
                        href="{{ document.fileUrl || API_URL + '/' + document.path }}"
                        target="_blank"
                        >{{ document.name ? document.name : document.filename }}</a
                      >
                    </td>
                    <td>
                      {{
                        document.category
                          ? ("ADMIN.DOCUMENTS.MODAL.FILE_CATEGORY." + document.category | translate)
                          : ("ADMIN.DOCUMENTS.MODAL.FILE_CATEGORY.OTHER" | translate)
                      }}
                    </td>
                    <td>
                      <div class="product-codes-container">
                        <ng-container *ngIf="document.productCodes; else noProducCode">
                          <ng-container *ngFor="let productCode of document.productCodes">
                            <div>{{ productCode }}</div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <ng-template #noProducCode>-</ng-template>
                    </td>
                    <td>{{ document.creationDate | date: "dd/MM/yyyy" }}</td>
                    <td>
                      <p-overlayPanel
                        #op
                        [style]="{ width: '125px' }">
                        <ng-template pTemplate="content">
                          <div
                            class="options-container"
                            (click)="onEditDocument(document)">
                            <span> {{ "TABLE.OPTIONS.EDIT" | translate }} </span>
                          </div>
                          <div
                            class="options-container"
                            (click)="onRemoveDocument(document)">
                            <span> {{ "TABLE.OPTIONS.REMOVE" | translate }} </span>
                          </div>
                        </ng-template>
                      </p-overlayPanel>
                      <button
                        pButton
                        class="p-button-text p-button-sm settings-btn ellipsis-settings-btn"
                        (click)="op.toggle($event)">
                        <i class="pi pi-ellipsis-v"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </p-card>
    </ng-container>
  </gpta-admin-container>
  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
  <gpta-file-upload-modal
    [(visible)]="showModal"
    [document]="document"
    (visibleChange)="visibleChange()"
    (uploadFinish)="uploadFinish()"></gpta-file-upload-modal>
</div>
