import { TitleCasePipe } from '@angular/common';
import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ManageUser } from '~app/models/manage-users';
import { USER_ROLES } from '~app/models/roles.enum';
import { ApiService } from '~app/services/api/api.service';
import { UsersService } from '~app/services/users/users.service';

@Component({
  selector: 'gpta-manage-users-modal',
  templateUrl: './manage-users-modal.component.html',
  styleUrls: ['./manage-users-modal.component.scss'],
})
export class ManageUsersModalComponent implements OnInit, OnChanges {
  @Input() visible = false;

  @Input() user: ManageUser | null = null;

  public title = '';

  public form!: FormGroup;

  public roles: string[] = [];

  public langSelected!: string;

  public selectedRol = 0;

  public languageList = ['en', 'es'];

  isUpdate = false;

  loading = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() update = new EventEmitter<null>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private apiService: ApiService,
    private usersService: UsersService,
    private translateService: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.getRoles();
    this.form = this.formBuilder.group({
      name: [''],
      email: ['', [Validators.required, Validators.email]],
      password: [null],
      role: ['', Validators.required],
      language: [''],
    });
    this.usersService.needReloadTable$.subscribe((res) => { // subscribe update data
      this.loading = false;
      if (res) {
        this.onClose();// reset form and close modal
      }
    });
    const userData = await this.apiService.getUserData();
    this.langSelected = userData.language;
  }

  ngOnChanges(): void {
    if (this.visible) {
      if (this.user) {
        this.title = this.translateService.instant('MENU.USER.EDIT_TITLE');
        this.isUpdate = true;
        this.loadFormWithUserData();
      } else {
        this.title = this.translateService.instant('ADMIN.USERS.ADD');
        this.isUpdate = false;
        this.form.get('password')?.setValidators([Validators.required]);
        this.form.controls['password'].setErrors({ incorrect: true });
      }
    }
  }

  loadFormWithUserData() {
    if (this.user) {
      this.form.get('password')?.setValidators(null);
      this.form.controls['name'].setValue(this.user.name);
      this.form.controls['email'].setValue(this.user.email);
      this.form.controls['password'].setValue(this.user.password);
      this.form.controls['role'].setValue(this.titleCasePipe.transform(this.user.role));
      this.form.controls['language'].setValue(this.user.language);
    }
  }

  getRoles(): void {
    const firstRol = '';
    this.roles.push(firstRol);
    const stringRoles = Object.values(USER_ROLES);
    stringRoles.forEach((rol: string) => {
      this.roles.push(this.titleCasePipe.transform(rol));
    });
  }

  showDialog() {
    this.visible = true;
  }

  onClose(): void {
    this.form.reset();
    this.isUpdate = false;
    if (this.isUpdate) this.update.emit();
    this.visibleChange.emit(false);
  }

  onActivate(): void {
    this.loading = true;
    const parsedUser: any = {
      name: this.form.controls['name'].value,
      email: this.form.controls['email'].value,
      role: this.form.controls['role'].value?.toUpperCase(),
      password: this.form.controls['password'].value,
    };
    this.usersService.createUser(parsedUser);
  }

  onUpdate(): void {
    this.loading = true;
    const parsedUser: any = {
      name: this.form.controls['name'].value,
      email: this.form.controls['email'].value,
      role: this.form.controls['role'].value?.toUpperCase(),
      password: this.form.controls['password'].value,
      language: this.form.controls['language'].value,
    };
    // eslint-disable-next-line no-underscore-dangle
    this.usersService.updateUser(parsedUser, this.user?._id);
  }
}
