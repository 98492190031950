import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable } from 'rxjs';

import { FILE_CATEGORIES } from '~app/config/constants/file-keys';
import { SEARCH_DELAY } from '~app/config/constants/search';
import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { OrganizationDocumentElement, SearchDocumentsDto } from '~app/models/document';
import { TextOverlayFilter } from '~app/models/overlay-filter';
import { ApiService } from '~app/services/api/api.service';
import { DocumentsService } from '~app/services/documents/documents.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';
import { environment } from '~environments/environment';

@Component({
  selector: 'gpta-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  @ViewChild('customFilter') customFilter: ElementRef | undefined;

  API_URL = environment.APP_API_URL;

  showModal = false;

  documentFilter: SearchDocumentsDto = {
    searchString: '', type: [], skip: 0, limit: 10, sortField: '', sortOrder: 1,
  };

  typesOptions: any[] = FILE_CATEGORIES;

  typesSelected: string[] = [];

  documents$: Observable<any>;

  documents: OrganizationDocumentElement[] = [];

  document: OrganizationDocumentElement | undefined;

  sortType: any[] = [{ label: '', value: 1 }, { label: '', value: -1 }];

  totalRecords = 0;

  page = 0;

  loading = false;

  areAllActive = false;

  typingTimer: any;

  typeFilterTimer: any;

  searchDelay = SEARCH_DELAY;

  searchLoading = false;

  textOverlayFilter: TextOverlayFilter = {
    sortTtile: 'ADMIN.DOCUMENTS.SORT',
    selectTitle: 'ADMIN.DOCUMENTS.FILTER',
    selectPlaceholder: 'ADMIN.DOCUMENTS.TYPE',
    clearBtn: 'ADMIN.DOCUMENTS.CLEAR',
    applyBtn: 'ADMIN.DOCUMENTS.APPLY',
  };

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private documentsService: DocumentsService,
    private confirmationService: ConfirmationService,
  ) {
    this.documents$ = this.documentsService.documentsList$;
    this.translateService.stream('ADMIN.DOCUMENTS.MODAL.FILE_CATEGORY')
      .subscribe((res: any) => {
        for (let i = 0; i < this.typesOptions.length; i += 1) {
          this.typesOptions[i].name = res[this.typesOptions[i].value];
        }
      });
    this.translateService.stream('ADMIN.DOCUMENTS.SORT_ASC')
      .subscribe((res: any) => {
        this.sortType[0].label = res;
      });
    this.translateService.stream('ADMIN.DOCUMENTS.SORT_DESC')
      .subscribe((res: any) => {
        this.sortType[1].label = res;
      });
  }

  ngOnInit(): void {
    this.documents$.subscribe(
      (
        res: {
          files: OrganizationDocumentElement[],
          total: number,
          areAllActive: boolean
        },
      ) => {
        if (res?.files) this.documents = res.files;
        if (res?.total) this.totalRecords = res.total;
        if (res && res.areAllActive !== null && res.areAllActive !== undefined) this.areAllActive = res.areAllActive;
        this.loading = false;
        this.searchLoading = false;
      },
    );
  }

  getDocumentsList() {
    this.loading = true;
    this.documentsService.getDocumentList(this.documentFilter);
  }

  createNewDocument(): void {
    this.showModal = true;
  }

  visibleChange() {
    this.showModal = false;
    this.document = undefined;
  }

  uploadFinish() {
    this.getDocumentsList();
  }

  onEditDocument(document: any): void {
    this.document = document;
    this.showModal = true;
  }

  onRemoveDocument(document: any): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('ADMIN.DOCUMENTS.REMOVE_TITLE'),
      message: this.translateService.instant('ADMIN.DOCUMENTS.ARE_YOU_SURE'),
      rejectLabel: this.translateService.instant('COMMON.CANCEL'),
      acceptLabel: this.translateService.instant('COMMON.DELETE'),
      acceptButtonStyleClass: 'p-button-rounded large-btn',
      rejectButtonStyleClass: 'p-button-rounded p-button-outlined large-btn admin-outline-btn',
      accept: () => {
        // eslint-disable-next-line no-underscore-dangle
        this.documentsService.deleteDocumentById(document._id).subscribe({
          next: (res) => {
            if (res.isDeleted) this.getDocumentsList();
          },
          error: () => {
            // TODO error message
          },
        });
      },
    });
  }

  onSearch() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchLoading = true;
      this.removeFilter(true);
      this.page = 0;
      this.getDocumentsList();
    }, this.searchDelay);
  }

  onLazyLoadDocuments(event: TableLazyLoadEvent) {
    this.documentFilter.skip = event.first ? event.first : 0;
    if (event.sortField) this.documentFilter.sortField = event.sortField as string;
    if (event.sortOrder) this.documentFilter.sortOrder = event.sortOrder;
    if (this.documentFilter.sortField !== 'category'
      && this.customFilter !== undefined
      && this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getDocumentsList();
  }

  onCheckChanges(document: any) {
    if (!document) return;
    const data = {
      isActive: document.isActive,
    };
    // eslint-disable-next-line no-underscore-dangle
    this.documentsService.patchDocumentById(document._id, data).subscribe({
      next: () => {
        this.getDocumentsList();
      },
      error: () => {
        // TODO error message
      },
    });
  }

  toggleActiveStateFilteredDocments() {
    this.documentsService.activeAllFilteredDocuments(this.documentFilter, this.areAllActive).subscribe({
      next: () => {
        this.getDocumentsList();
      },
      error: () => {
        // TODO error message
      },
    });
  }

  setTypeFilter(event:any) {
    const typeList = event.map(({ value }:any) => value);
    this.documentFilter.type = typeList;
  }

  applyFilter(documentsTable: any) {
    const { sortOrder } = this.documentFilter;
    documentsTable.clear();
    this.documentFilter.sortField = 'category';
    this.documentFilter.searchString = '';
    this.documentFilter.sortOrder = sortOrder;
    if (this.customFilter !== undefined
      && !this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
    this.getDocumentsList();
  }

  removeFilter(isSearch: boolean) {
    if (!isSearch) this.documentFilter.searchString = '';
    this.documentFilter.type = [];
    this.documentFilter.skip = 0;
    if (!isSearch) this.documentFilter.sortField = '';
    if (!isSearch) this.documentFilter.sortOrder = 1;
    this.typesSelected = [];
    if (this.customFilter !== undefined
      && this.customFilter.nativeElement.classList.contains('custom-filter-active')) {
      this.customFilter.nativeElement.classList.toggle('custom-filter-active');
    }
  }

  removeEmmiter(event: any, filterPanel: any, documentsTable: any) {
    this.removeFilter(false);
    filterPanel.toggle(new Event('click'));
    documentsTable.clear();
  }

  applyEmitter(event: any, filterPanel: any, documentsTable: any) {
    this.typesSelected = event.typesSelected;
    this.documentFilter.type = event.optionsSelected;
    this.documentFilter.sortOrder = event.sortValue;
    this.applyFilter(documentsTable);
    filterPanel.toggle(new Event('click'));
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.apiService.setNextConversation({});
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate(['/chat', conversation._id]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
