// iframe check and comunicate with parent helper
// Path: src/app/services/helpers/iframe.helper.ts
import { Injectable } from '@angular/core';

import { PartialComponentAction } from '~models/component-action';

@Injectable({
  providedIn: 'root',
})
export class IframeHelper {
  isIframe = IframeHelper.checkIsIframe();

  private parent = window.parent;

  public sendMessageToParent(message: PartialComponentAction | string): void {
    if (!this.isIframe) return;
    this.parent.postMessage(message, '*');
  }

  static checkIsIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
