<div class="gpta-signin">
  <div class="logo"></div>
  <div class="title">{{ "SIGNUP.SIGNUP" | translate }}</div>
  <form
    class="form"
    [formGroup]="form"
    (ngSubmit)="submitSignupForm()">
    <div class="wrapper">
      <div class="input-content">
        <label
          class="label"
          for="email"
          >{{ "COMMON.EMAIL" | translate }}</label
        >
        <input
          pInputText
          type="email"
          id="email"
          formControlName="email"
          required />
      </div>
      <div class="input-content">
        <label
          class="label"
          for="password"
          >{{ "COMMON.PASSWORD" | translate }}</label
        >
        <p-password
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          formControlName="password"
          [toggleMask]="true"
          [feedback]="false"
          required></p-password>
      </div>
      <div class="input-content terms">
        <p-checkbox
          inputId="terms-id"
          formControlName="hasAcceptedTerms"
          [required]="true"
          [binary]="true"></p-checkbox>
        <label for="terms-id">
          {{ "SIGNUP.READ_TERMS" | translate }}
          <a
            class="privacy-link"
            target="_blank"
            [routerLink]="['privacy']">
            {{ "SIGNUP.PRIVACY_POLICY" | translate }} </a
          >.
        </label>
      </div>
      <div class="button-content">
        <a [routerLink]="['/login']">{{ "COMMON.SIGNIN_HERE" | translate }}</a>
        <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
        <button
          pButton
          [disabled]="!form.valid"
          label="{{ 'SIGNUP.SIGNUP_BUTTON' | translate }}"></button>
      </div>
    </div>
  </form>
</div>
