import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { take } from 'rxjs';

import { ApiService } from '~app/services/api/api.service';

@Component({
  selector: 'gpta-chat-attachments',
  templateUrl: './chat-attachments.component.html',
  styleUrls: ['./chat-attachments.component.scss'],
})
export class ChatAttachmentsComponent {
  @Input() attachments: any;

  @Output() pdfSource: EventEmitter<any> = new EventEmitter<any>();

  constructor(public apiService: ApiService) {}

  async onCLickSource(attachment: any): Promise<void> {
    const existSource = await this.checkAttachment(attachment);
    if (existSource) {
      if (attachment.url) {
        window.open(attachment.url, '_blank');
        return;
      }

      this.pdfSource.emit(attachment);
    }
  }

  checkAttachment(source: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (source.url) {
        resolve(true);
        return;
      }

      this.apiService.getSource(source.filePath).pipe(take(1)).subscribe({
        next: () => resolve(true),
        error: (err: any) => {
          resolve(false);
          reject(err);
        },
      });
    });
  }
}
