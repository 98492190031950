import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { STYLES_CUSTOM_COLORS, STYLES_CUSTOM_IMAGES } from '~app/config/constants/custom-styles-conf';
import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { Colors, Images } from '~app/models/organization';
import { User } from '~app/models/user';
import { ApiService } from '~app/services/api/api.service';
import { CustomStylesService } from '~app/services/custom-styles/custom-styles.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-custom-styles',
  templateUrl: './custom-styles.component.html',
  styleUrls: ['./custom-styles.component.scss'],
})
export class CustomStylesComponent implements OnInit {
  userData?: User;

  welcomeMessage = '';

  stylesCustomColors = STYLES_CUSTOM_COLORS;

  stylesCustomImages = STYLES_CUSTOM_IMAGES;

  selectedImages: any[] = [];

  constructor(
    private apiService: ApiService,
    private customStyles: CustomStylesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.stylesCustomColors = this.stylesCustomColors.map((style) => {
      const customStyle = { ...style };
      if (this.apiService.styles?.colors && this.apiService.styles.colors[customStyle.key as keyof Colors]) {
        customStyle.value = this.apiService.styles.colors[customStyle.key as keyof Colors];
        if (customStyle.value === '#fff') {
          customStyle.value = '#ffffff';
        }
      } else if (this.apiService.styles?.images && this.apiService.styles.images[customStyle.key as keyof Images]) {
        customStyle.value = this.apiService.styles.images[customStyle.key as keyof Images] ?? '';
        if (customStyle.value === '#fff') {
          customStyle.value = '#ffffff';
        }
      }
      return customStyle;
    });

    this.stylesCustomImages = this.stylesCustomImages.map((style) => {
      const customStyle = { ...style };
      if (this.apiService.styles?.images && this.apiService.styles.images[customStyle.key as keyof Images]) {
        customStyle.value = this.apiService.styles.images[customStyle.key as keyof Images] ?? '';
        if (customStyle.value.includes('url')) {
          customStyle.value = customStyle.value.replace(/url\((['"])?(.*?)\1\)/gi, '$2');
        }
      }
      return customStyle;
    });
  }

  async ngOnInit() {
    this.userData = await this.apiService.getUserData();
    this.welcomeMessage = this.userData?.organization?.customContent?.texts?.welcomeTitle ?? '';
  }

  // eslint-disable-next-line class-methods-use-this
  getTextKey(name: string) {
    return name.split(/(?=[A-Z])/).join('_').toUpperCase();
  }

  colorChange(event: any) {
    const color = this.stylesCustomColors.find((style) => style.name === event.name);
    if (color) {
      color.value = event.value;
    }
  }

  onSaveColous() {
    const body = {
      customContent: {
        styles: {
          colors: {
            primaryColor: this.stylesCustomColors[0].value,
            primaryColorDark: this.stylesCustomColors[1].value,
            primaryColorLight: this.stylesCustomColors[2].value,
          },
        },
      },
    };
    this.customStyles.updateColors(this.apiService.organizationId ?? '', body)
      .subscribe(
        {
          next: (res) => {
            console.log(res);
            window.location.reload();
          },
          error: (err) => {
            console.log(err);
          },
        },
      );
  }

  onFileSelected(event: any, item: { name: string; key: string; editable: boolean; value: string; }) {
    const image = this.stylesCustomImages.find((img) => img.name === item.name);
    if (image) {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          image.value = reader.result as string;
          const index = this.selectedImages.findIndex((img) => img.key === item.key);
          if (index !== -1) {
            this.selectedImages[index] = {
              name: item.name,
              key: item.key,
              image: reader.result as string,
              file,
            };
          } else {
            this.selectedImages.push(
              {
                name: item.name,
                key: item.key,
                image: reader.result as string,
                file,
              },
            );
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onSaveImages() {
    const isEmpty = this.selectedImages.length === 0;
    if (!isEmpty) {
      const formData = new FormData();
      formData.append('image', this.selectedImages[0].key);
      formData.append('ORGANIZATION_ASSETS', this.selectedImages[0].file);
      this.customStyles.updateImage(this.apiService.organizationId ?? '', formData).subscribe(
        {
          next: (res) => {
            this.selectedImages.shift();
            this.onSaveImages();
          },
          error: (err) => {
            console.log(err);
          },
        },
      );
    } else {
      window.location.reload();
    }
  }

  onSavewelcomeMessage() {
    const body = {
      customContent: {
        texts: {
          welcomeTitle: this.welcomeMessage,
        },
      },
    };
    this.customStyles.updateColors(this.apiService.organizationId ?? '', body)
      .subscribe(
        {
          next: (res) => {
            console.log(res);
            window.location.reload();
          },
          error: (err) => {
            console.log(err);
          },
        },
      );
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.apiService.setNextConversation({});
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      this.router.navigate(['/chat', conversation._id]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
