import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import config from '~config/constants';
import { IframeHelper } from '~helpers/iframe.helper';
import { PartialComponentAction } from '~models/component-action';
import { Styles } from '~models/organization';
import { User } from '~models/user';
import { ApiService } from '~services/api/api.service';
import { DataService } from '~services/data/data.service';
import { applyCustomStyles } from '~services/helpers/custom-styles.helper';

const { ORGANIZATION_CUSTOM_STYLES_KEY, SESSION_VIEW_MODE_KEY } = config.dataKeys;

@Component({
  selector: 'gpta-chat',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'gpt-advisor-webapp';

  constructor(
    private dataService: DataService,
    private router: Router,
    private apiService: ApiService,
    private iframeHelper:IframeHelper,
  ) {}

  ngOnInit(): void {
    const customStyles: Styles = this.dataService.localGet(ORGANIZATION_CUSTOM_STYLES_KEY);
    if (customStyles) applyCustomStyles(customStyles);
    this.setViewMode();
    this.loginByUrlParams();
  }

  private setViewMode(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const urlViewMode = urlParams.get('viewMode');
    if (urlViewMode) {
      this.dataService.sessionSet(SESSION_VIEW_MODE_KEY, urlViewMode);
    }
    const currentViewMode = this.dataService.sessionGet(SESSION_VIEW_MODE_KEY);
    if (currentViewMode) {
      document.body.classList.add(`viewMode-${currentViewMode}`);
    }
  }

  private loginByAuthToken(urlParams:URLSearchParams): boolean {
    const authToken = urlParams.get('authToken');
    if (!authToken) return false;
    this.apiService.setSessionFromToken(authToken)?.subscribe((response: User | null) => {
      if (response) {
        this.router.navigateByUrl('/chat');
      }
    });
    return true;
  }

  private loginBySSO(urlParams:URLSearchParams): boolean {
    const ssoToken = urlParams.get('ssoToken');
    if (!ssoToken) return false;
    this.apiService.loginBySSO(ssoToken).subscribe((response: User) => {
      this.iframeHelper.sendMessageToParent({
        name: 'ssoLoginDone',
        data: null,
      } as PartialComponentAction);
      if (response) {
        this.router.navigateByUrl('/chat');
      }
    }, (error: any) => {
      // eslint-disable-next-line no-console
      console.error('ssoError', error);
      this.iframeHelper.sendMessageToParent({
        name: 'wrongSSOToken',
        data: null,
      } as PartialComponentAction);
    });
    return true;
  }

  loginByUrlParams(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.loginBySSO(urlParams);
    this.loginByAuthToken(urlParams);
  }
}
