<div class="card-info-container">
  <div class="card-info-title">
    <h4 class="card-info-title-text bold">{{ data.name }}</h4>
    <div class="card-info-subtitle-container">
      <span class="light">{{ data.isin }}</span>
      <span class="light">{{ data.currency }}</span>
      <span class="light">{{ data.type }}</span>
    </div>
  </div>
  <div class="card-info-content">
    <ul class="card-info-content-list">
      <li>
        {{ "CHAT.CARD_INFO.ACCUMULATED_PROFITABILITY" | translate }} ({{ data.period }}):
        <span class="bold">{{ data.performance }}% </span>
      </li>
      <li>
        {{ "CHAT.CARD_INFO.MIN_INVESTMENT" | translate }}:
        <span class="bold">{{ data.minimalInvestment | currency: data.currency : "symbol-narrow" : "1.0-0" }} </span>
      </li>
      <li>
        {{ "CHAT.CARD_INFO.ONGOING_CHARGES" | translate }}: <span class="bold">{{ data.ongoingChargesFee }} </span>
      </li>
    </ul>
  </div>
  <div class="card-info-btn-container">
    <button
      pButton
      class="card-info-btn p-button-rounded p-button-sm small-btn"
      (click)="submitOption(data.name, data.isin)">
      {{ "COMMON.MORE_INFO" | translate }}
    </button>
  </div>
</div>
