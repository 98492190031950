<div class="chat-rename-form-container">
  <form
    class="chat-rename-form"
    [formGroup]="formGroup">
    <div class="form-group">
      <input
        pInputText
        type="text"
        id="title"
        formControlName="title"
        placeholder="{{ 'CHAT.FORM.FIELDS.TITLE' | translate }}" />
      <small
        class="error"
        *ngIf="formGroup.controls?.['title']?.errors?.['minlength']">
        {{
          "CHAT.FORM.RENAME.ERROR.MIN_LENGTH"
            | translate: { min: formGroup.controls["title"].errors?.["minlength"].requiredLength }
        }}
      </small>
      <small
        class="error"
        *ngIf="formGroup.controls?.['title']?.errors?.['maxlength']">
        {{
          "CHAT.FORM.RENAME.ERROR.MAX_LENGTH"
            | translate: { max: formGroup.controls["title"].errors?.["maxlength"].requiredLength }
        }}
      </small>
    </div>
  </form>
  <!-- eslint-disable @angular-eslint/template/elements-content-->
  <div class="chat-rename-form-actions">
    <button
      pButton
      icon="pi pi-times"
      class="p-button-secondary p-button-text"
      (click)="cancel()"
      [disabled]="isLoading"
      type="button"></button>
    <button
      pButton
      icon="pi pi-check"
      type="submit"
      class="accept-btn p-button-text"
      [ngClass]="{ blink: isLoading }"
      (click)="rename()"
      [disabled]="isLoading || !formGroup.valid"></button>
  </div>
</div>
