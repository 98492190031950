<p-card class="tile-card">
  <div class="tile-card-container">
    <div
      class="card-title"
      *ngIf="title">
      {{ title | translate }}
    </div>
    <svg-icon
      *ngIf="icon"
      [src]="icon"
      class="card-icon"></svg-icon>
    <div class="card-total">
      {{ total }}
    </div>
    <div class="card-secondary-value">
      {{ secondaryValue }} <span *ngIf="secondaryText">{{ secondaryText | translate }}</span>
    </div>
  </div>
</p-card>
