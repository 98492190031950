import {
  Component, EventEmitter, HostListener, Input, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';

import { ComponentAction } from '~models/component-action';
import WindowProperties from '~models/window-prop';
import { createDoAction } from '~services/helpers/componentAction.helper';
import { WindowSizeHelper } from '~services/helpers/screen-size.helper';

const TOOLTIPS_HIDE_WIDTH = 1080;

@Component({
  selector: 'gpta-chat-side-menu',
  templateUrl: './chat-side-menu.component.html',
  styleUrls: ['./chat-side-menu.component.scss'],
})
export class ChatSideMenuComponent {
  @Input() currentChatId: string | null = null;

  @Output() chatChanged = new EventEmitter();

  @Output() startNewChat = new EventEmitter();

  public isVisible = false;

  public openFavs = false;

  public isLoading = false;

  public hideTooltips = true;

  public windowProperties: Observable<WindowProperties>;

  public menuItems: MenuItem[] = [
    {
      label: '',
      icon: 'pi pi-times close-icon',
      style: { 'justify-content': 'right', display: 'flex' },
      command: () => { this.isVisible = false; },
    },
    {
      label: this.translateService.instant('CHAT.FLOATING_MENU.NEW_CHAT'),
      icon: 'pi pi-plus',
      command: () => this.newChatHandler(),
    },
    {
      label: this.translateService.instant('CHAT.FLOATING_MENU.OPEN_ARCHIVE'),
      icon: 'pi chat-history',
      command: () => { this.openFavs = false; this.isVisible = true; },
    },
    {
      label: this.translateService.instant('CHAT.FLOATING_MENU.OPEN_FAVORITES'),
      icon: 'pi pi-star-fill',
      command: () => { this.openFavs = true; this.isVisible = true; },
    },
  ];

  constructor(public windowSizeHelper: WindowSizeHelper, private translateService: TranslateService) {
    this.checkTooltipsVisibility();

    this.windowProperties = this.windowSizeHelper.getWindowProperties();
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.checkTooltipsVisibility();
  }

  chatChangedHandler(event: ComponentAction) {
    if (!event.data.chat) {
      this.newChatHandler(false);
      return;
    }
    this.chatChanged.next(event);
    this.isVisible = false;
  }

  newChatHandler(closeOnFinish = true) {
    const action: ComponentAction = createDoAction({
      name: 'new-chat-action',
    });

    this.startNewChat.next(action);
    if (closeOnFinish) {
      this.isVisible = false;
    }
  }

  private checkTooltipsVisibility() {
    this.hideTooltips = window.innerWidth > TOOLTIPS_HIDE_WIDTH;
  }
}
