import { COMPONENT_ACTION_TYPE, ComponentAction, PartialComponentAction } from '~app/models/component-action';

export const createDoAction = (actionData: PartialComponentAction): ComponentAction => ({
  action: COMPONENT_ACTION_TYPE.DO,
  ...actionData,
});
export const createErrorAction = (actionData: PartialComponentAction): ComponentAction => ({
  action: COMPONENT_ACTION_TYPE.ERROR,
  ...actionData,
});
export const createCancelAction = (actionData: PartialComponentAction): ComponentAction => ({
  action: COMPONENT_ACTION_TYPE.CANCEL,
  ...actionData,
});

export const isDoAction = (action: ComponentAction): boolean => action.action === COMPONENT_ACTION_TYPE.DO;
export const isErrorAction = (action: ComponentAction): boolean => action.action === COMPONENT_ACTION_TYPE.ERROR;
export const isCancelAction = (action: ComponentAction): boolean => action.action === COMPONENT_ACTION_TYPE.CANCEL;
