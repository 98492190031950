import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { User } from '~app/models/user';
import { ApiService } from '~app/services/api/api.service';

@Component({
  selector: 'gpta-chat-placeholder',
  templateUrl: './chat-placeholder-component.html',
  styleUrls: ['./chat-placeholder-component.scss'],
})
export class ChatPlaceholderComponent implements OnInit, OnDestroy {
  welcomeTitle = '';

  welcomeDisclaimers: string[] = [];

  companyName = '';

  userData?: User;

  languageSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
  ) {
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.setTranslations();
    });
  }

  async ngOnInit() {
    this.userData = await this.apiService.getUserData();
    this.setTranslations();
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) this.languageSubscription.unsubscribe();
  }

  setTranslations() {
    this.welcomeDisclaimers = [];
    const { welcomeTitle, companyName, welcomeDisclaimers } = this.userData?.organization?.customContent?.texts || {};
    if (welcomeTitle) this.welcomeTitle = welcomeTitle;
    else this.welcomeTitle = this.translateService.instant('CHAT.WELCOME_TITLE');
    if (companyName) this.companyName = companyName;
    else this.companyName = this.translateService.instant('CHAT.WELCOME_COMPANY_NAME');
    if (welcomeDisclaimers?.length) this.welcomeDisclaimers = [...welcomeDisclaimers];
    this.welcomeDisclaimers.push(this.translateService.instant('CHAT.WELCOME_DESCRIPTION'));
  }
}
