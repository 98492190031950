import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { SignupComponent } from './signup.component';
import { SignupRoutingModule } from './signup-routing.module';

@NgModule({
  declarations: [
    SignupComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    PasswordModule,
    CheckboxModule,
    InputTextModule,
    SignupRoutingModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class SignupModule { }
