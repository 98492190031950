import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { customStyleConf } from '~app/models/custom-style';

@Component({
  selector: 'gpta-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  @Input() idx = null;

  @Input() placeHolder = '';

  @Input() color: customStyleConf = {
    value: '',
    name: '',
    key: '',
    editable: false,
  };

  @Input() colorsList: customStyleConf[] = [];

  @Output() colorChange = new EventEmitter();

  constructor(public translateService: TranslateService) {}

  // eslint-disable-next-line class-methods-use-this
  hexToRgb(hex: string) {
    const bigint = parseInt(hex.replace('#', ''), 16);
    // eslint-disable-next-line no-bitwise
    const r = (bigint >> 16) & 255;
    // eslint-disable-next-line no-bitwise
    const g = (bigint >> 8) & 255;
    // eslint-disable-next-line no-bitwise
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  // eslint-disable-next-line class-methods-use-this
  rgbToHex(rgb: string) {
    const rgbArray = rgb.split(', ');
    // eslint-disable-next-line radix
    const r = parseInt(rgbArray[0]).toString(16);
    // eslint-disable-next-line radix
    const g = parseInt(rgbArray[1]).toString(16);
    // eslint-disable-next-line radix
    const b = parseInt(rgbArray[2]).toString(16);
    return `#${r}${g}${b}`;
  }

  setRgbaColor(value: string, item: customStyleConf) {
    const color = this.colorsList.find((style) => style.name === item.name);
    if (color) {
      if (item.value.includes('rgb')) {
        const rgb = item.value.replace(/rgb\(|\)/g, '');
        color.value = `rgba(${rgb}, ${value})`;
      } else {
        const rgbColor = this.hexToRgb(item.value);
        const colorWithAlpha = `rgba(${rgbColor}, ${value})`;
        color.value = colorWithAlpha;
      }
    }
  }

  getHexColor(value: string | undefined) {
    if (value) {
      if (value.includes('rgba')) {
        const rgb = value.replace(/\s/g, '').split(',').slice(0, 3).join(', ')
          .replace('rgba(', '');
        const hex = this.rgbToHex(rgb);
        return hex;
      }
      if (value.includes('rgb')) {
        const rgb = value.replace(/\s/g, '').split(',').slice(0, 3).join(', ')
          .replace('rgb(', '');
        const hex = this.rgbToHex(rgb);
        return hex;
      }
    }
    return value;
  }

  // eslint-disable-next-line class-methods-use-this
  getAlphaColor(value: string | undefined) {
    if (value) {
      if (value.includes('rgba')) {
        const alpha = value.split(', ')[3].replace(')', '');
        return alpha;
      }
    }
    return 1;
  }

  changeColorInput(event: any, type: string, item: customStyleConf) {
    const color = this.colorsList.find((style) => style.name === item.name);
    if (color) {
      switch (type) {
        case 'color':
          if (item.value.includes('rgba')) {
            const rgb = this.hexToRgb(event.target.value);
            const alpha = item.value.split(', ')[3].replace(')', '');
            color.value = `rgba(${rgb}, ${alpha})`;
          } else {
            color.value = event.target.value;
          }
          break;
        case 'alpha':
          if (item.value.includes('rgba')) {
            const rgb = item.value.split(', ').slice(0, 3).join(', ').replace('rgba(', '');
            const alpha = event.target.value;
            color.value = `rgba(${rgb}, ${alpha})`;
          } else {
            this.setRgbaColor(event.target.value, item);
          }
          break;
        default:
          break;
      }
      this.colorChange.next(color);
    }
  }
}
