import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { isAdminGuard } from '~app/services/guard/is-admin.guard';
import { hasUserDataGuard } from '~guards/has-user-data.guard';
import { isLoggedGuard } from '~guards/is-logged.guard';

import { ChatLogComponent } from './chat-log.component';

const routes: Routes = [
  {
    path: 'admin/chat-log',
    component: ChatLogComponent,
    canActivate: [isLoggedGuard, hasUserDataGuard, isAdminGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ChatLogRoutingModule { }
