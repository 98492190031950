import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';

import { ComponentsModule } from '~app/components/components.module';

import { ChatLogComponent } from './chat-log.component';
import { ChatLogRoutingModule } from './chat-log-routing.module';

@NgModule({
  declarations: [
    ChatLogComponent,
  ],
  imports: [
    CommonModule,
    ChatLogRoutingModule,
    ComponentsModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    TranslateModule,
    OverlayPanelModule,
  ],
  providers: [DatePipe],
})
export class ChatLogModule { }
