<div
  [ngClass]="{ 'is-mobile': (windowProperties | async)?.isMobile, 'entity-image': !isAdminPage }"
  (click)="entityImageHandler()"></div>
<div
  [ngClass]="{ 'is-mobile': (windowProperties | async)?.isMobile }"
  class="header-logo"
  *ngIf="isAdminPage">
  <div class="manage-image"></div>
  <div>
    <h3>{{ "COMMON.ADMINISTRATION" | translate }}</h3>
  </div>
</div>

<div class="options-align">
  <p-overlayPanel
    #op
    [style]="{ 'max-width': '305px', 'min-width': '205px' }">
    <ng-template pTemplate="content">
      <div class="panel-administrator-content">
        <ng-container *ngFor="let item of manageItems">
          <div
            class="card-container"
            (click)="goToUrl(item.url)"
            *ngIf="!item.disabled">
            <div *ngIf="item.icon">
              <svg-icon
                class="panel-administrator-icon"
                [src]="item.icon"></svg-icon>
            </div>
            <div>
              <span class="menu-label">
                {{ item.label }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </p-overlayPanel>
  <button
    pButton
    class="lang-nav-btn p-button-text p-button-sm settings-btn line-height-1"
    (click)="op.toggle($event)"
    *ngIf="isAdmin">
    <svg-icon
      src="../../../assets/images/administration-panel/setting.svg"
      class="justify-items-center"></svg-icon>
  </button>
  <button
    pButton
    class="user-nav-btn p-button-rounded p-button-sm line-height-1"
    (click)="userMenu.toggle($event)">
    <svg-icon src="../../../assets/images/user.svg"></svg-icon>
  </button>
  <p-menu
    #userMenu
    [model]="userMenuItems"
    [popup]="true"
    [styleClass]="'nav-menu user-menu'"></p-menu>
  <p-menu
    #langMenu
    [model]="langMenuItems"
    [popup]="true"
    [styleClass]="'nav-menu lang-menu'"></p-menu>
  <button
    pButton
    class="lang-nav-btn p-button-text p-button-sm"
    (click)="langMenu.toggle($event)">
    {{ langSelected }} <i class="pi pi-chevron-down"></i>
  </button>
</div>
