import { Component, Input } from '@angular/core';

@Component({
  selector: 'gpta-value-tile-card',
  templateUrl: './value-tile-card.component.html',
  styleUrls: ['./value-tile-card.component.scss'],
})
export class ValueTileCardComponent {
  @Input() title: string | undefined;

  @Input() total: number | undefined;

  @Input() icon: string | undefined;

  @Input() secondaryValue: number | undefined;

  @Input() secondaryText: string | undefined;
}
