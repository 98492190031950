// API model use underscore case for properties
/* eslint-disable no-underscore-dangle */
import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

import { ComponentAction } from '~app/models/component-action';
import { createDoAction, isCancelAction, isDoAction } from '~app/services/helpers/componentAction.helper';
import config from '~config/constants';
import { Conversation } from '~models/conversation';
import { CONVERSATION_CONTEXT_ACTIONS } from '~models/conversation-action.enum';
import { ApiService } from '~services/api/api.service';

const CHAT_LIST_SORTING_DATE_FIELD = config.chat.LIST_GROUP_DATE_FIELD;
interface ConversationItem extends Conversation {
  isLoading: boolean;
}
interface ChatGroup {
  label: string;
  value: any;
  isToday: boolean;
  items: ConversationItem[];
}

@Component({
  selector: 'gpta-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit, OnChanges {
  @Input() openFavs = false;

  @Input() currentChatId: string | null = null;

  @Output() chatChanged = new EventEmitter();

  public isRenameChatModalVisible = false;

  public isRenameChatModalLoading = false;

  public chatList: Conversation[] | null = null;

  public searchString:string | null = null;

  public groupedChatList: ChatGroup[] = [];

  public selectedChat: Conversation | null = null;

  public actionChat: ConversationItem | null = null;

  private allTab: MenuItem = { id: 'all', label: this.translateService.instant('CHAT.SIDE_BAR.TABS.ALL') };

  private favTab: MenuItem = { id: 'fav', label: this.translateService.instant('CHAT.SIDE_BAR.TABS.FAVORITES') };

  public tabs: MenuItem[] = [
    this.allTab,
    this.favTab,
  ];

  public activeTab = this.allTab;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.searchChats();
  }

  ngOnChanges() {
    if (this.currentChatId !== this.selectedChat?._id && this.chatList) {
      this.searchChats();
    }

    this.tabChangeHandler(this.openFavs ? this.favTab : this.allTab);
  }

  async searchChats() {
    const filter = { title: this.searchString };
    this.selectChat(null);
    const conversations:any = await firstValueFrom(this.apiService.searchConversations(filter));
    this.chatList = conversations.conversations;
    this.groupedChatList = this.parseChatList();
    this.selectChat(this.currentChatId);
  }

  changeHandler(event: { originalEvent: any, value: Conversation }) {
    const isContextMenu = Boolean(event.originalEvent.target.closest('gpta-chat-list-item-context-menu'));
    if (isContextMenu) return;
    const selectedChat = event?.value;
    this.currentChatId = selectedChat?._id;
    const action: ComponentAction = createDoAction({
      name: 'chatChanged',
      data: {
        chat: selectedChat,
      },
    });
    this.chatChanged.next(action);
  }

  async contextActionHandler(action: ComponentAction) {
    if (!isDoAction(action)) return;
    const { type, chat }: { type: CONVERSATION_CONTEXT_ACTIONS, chat: ConversationItem } = action.data;
    let avoidReloadChat = false;
    ChatListComponent.setChatLoadingState(chat, true);
    switch (type) {
      case CONVERSATION_CONTEXT_ACTIONS.ADD_TO_FAVORITES:
        await this.setFavoriteState(chat, true);
        ChatListComponent.setChatLoadingState(chat, false);
        break;
      case CONVERSATION_CONTEXT_ACTIONS.REMOVE_FROM_FAVORITES:
        await this.setFavoriteState(chat, false);
        ChatListComponent.setChatLoadingState(chat, false);
        break;
      case CONVERSATION_CONTEXT_ACTIONS.RENAME:
        ChatListComponent.setChatLoadingState(chat, false);
        avoidReloadChat = true;
        this.isRenameChatModalVisible = true;
        this.actionChat = chat;
        break;
      case CONVERSATION_CONTEXT_ACTIONS.DELETE:
        avoidReloadChat = true;
        this.actionChat = chat;
        this.confirmationService.confirm({
          header: this.translateService.instant('CHAT.FORM.DELETE.TITLE'),
          message: `${
            this.translateService.instant('CHAT.FORM.DELETE.TITLE')
          } "${chat?.title}". ${
            this.translateService.instant('CHAT.FORM.DELETE.CONFIRMATION')
          }`,
          rejectLabel: this.translateService.instant('COMMON.CANCEL'),
          acceptLabel: this.translateService.instant('COMMON.DELETE'),
          acceptButtonStyleClass: 'p-button-rounded',
          rejectButtonStyleClass: 'p-button-rounded',
          accept: () => {
            this.deleteChatHandler();
          },
          reject: () => {
            this.actionChat = null;
            ChatListComponent.setChatLoadingState(chat, false);
          },
        });

        this.actionChat = chat;
        break;
      default:
        break;
    }
    if (!avoidReloadChat) {
      await this.searchChats();
    }
  }

  tabChangeHandler(event: MenuItem) {
    if (this.activeTab === event) return;
    this.activeTab = event;
    const groupedChatList = this.parseChatList();
    this.lazyRenderList(groupedChatList);
    this.actionChat = null;
  }

  async renameChatHandler(action:ComponentAction) {
    if (isCancelAction(action)) {
      this.isRenameChatModalVisible = false;
      this.actionChat = null;
      return;
    }
    if (isDoAction(action)) {
      this.isRenameChatModalLoading = true;
      if (action.data) {
        const title = action.data?.title as string;
        const chat = action.data?.chat as ConversationItem;
        await this.setChatTitle(chat, title);
        await this.searchChats();
      }
      this.isRenameChatModalVisible = false;
      this.isRenameChatModalLoading = false;
      if (this.actionChat) {
        ChatListComponent.setChatLoadingState(this.actionChat, false);
      }
    }
  }

  private lazyRenderList(groupedChatList: ChatGroup[]) {
    // avoid blinking on long lists redering
    const NUMBER_OF_CHATS_RENDER_ON_FIRST_LOAD = 20;
    let numberOfItemsDisplayed = 0;
    const partialGroupedChatList:ChatGroup[] = [];
    let groupIndex = 0;
    while (numberOfItemsDisplayed < NUMBER_OF_CHATS_RENDER_ON_FIRST_LOAD && groupIndex < groupedChatList.length) {
      const group = groupedChatList[groupIndex];
      partialGroupedChatList.push(group);
      numberOfItemsDisplayed += group.items.length;
      groupIndex += 1;
    }
    this.groupedChatList = partialGroupedChatList;
    setTimeout(() => {
      this.groupedChatList = groupedChatList;
    });
  }

  private selectChat(chatId:string | null) {
    if (!chatId) {
      this.selectedChat = null;
      return;
    }
    this.selectedChat = this.chatList?.find((chat) => chat._id === chatId) || null;
  }

  private parseChatList() : ChatGroup[] {
    if (!this.chatList) return [];
    const today = new Date();
    const parsedList = this.chatList.reduce((acc: ChatGroup[], chat: Conversation) => {
      const isFavTabactive = this.activeTab === this.favTab;
      if (isFavTabactive && !chat.isFavourite) return acc;
      const sortingDate = new Date(chat[CHAT_LIST_SORTING_DATE_FIELD]).setHours(0, 0, 0, 0);
      const chatGroup = acc.find((group) => group.value === sortingDate);
      if (chatGroup) {
        chatGroup.items.push(chat as ConversationItem);
      } else {
        acc.push({
          label: chat.creationDate,
          value: sortingDate,
          isToday: sortingDate === today.setHours(0, 0, 0, 0),
          items: [chat as ConversationItem],
        });
      }
      return acc;
    }, []);
    return parsedList;
  }

  private async setFavoriteState(chat: ConversationItem, isFavourite: boolean) {
    return this.updateChat(chat._id, { title: chat.title, isFavourite });
  }

  private async setChatTitle(chat: ConversationItem, title: string) {
    return this.updateChat(chat._id, { title, isFavourite: chat.isFavourite });
  }

  private async updateChat(chatId: string, params: { title: string, isFavourite: boolean | undefined }) {
    try {
      await firstValueFrom(this.apiService.updateConversation(chatId, params));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: this.translateService.instant('CHAT.ERROR.UPDATE_CONVERSATION'),
      });
    }
  }

  static setChatLoadingState(chat: ConversationItem, isLoading: boolean) {
    // Need to reassing global chat item
    // eslint-disable-next-line no-param-reassign
    chat.isLoading = isLoading;
  }

  async deleteChatHandler() {
    if (!this.actionChat) {
      return;
    }
    await this.deleteChat(this.actionChat._id);
    await this.searchChats();
    ChatListComponent.setChatLoadingState(this.actionChat, false);
    if (this.actionChat._id === this.currentChatId) {
      this.chatChanged.next(createDoAction({
        name: 'currentChatremoved',
        data: {
          chat: null,
        },
      }));
    }
    this.actionChat = null;
  }

  private async deleteChat(chatId: string) {
    try {
      await firstValueFrom(this.apiService.deleteConversation(chatId));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: this.translateService.instant('CHAT.ERROR.DELETE_CONVERSATION'),
      });
    }
  }
}
