import { Component } from '@angular/core';

@Component({
  selector: 'gpta-admin-container',
  templateUrl: './admin-container.component.html',
  styleUrls: ['./admin-container.component.scss'],
})
export class AdminContainerComponent {

}
