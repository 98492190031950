import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, Observable, Subject } from 'rxjs';

import config from '~config/constants';
import { environment } from '~environments/environment';
import { buildQuery } from '~helpers/query-builder.helper';
import { Conversation } from '~models/conversation';
import { CreateConversation } from '~models/create-conversation-request';
import { Message } from '~models/message';
import { USER_ROLES } from '~models/roles.enum';
import { SENDER_TYPE } from '~models/sender.enum';
import { User } from '~models/user';
import { DataService } from '~services/data/data.service';

import { applyCustomStyles } from '../helpers/custom-styles.helper';

const { ORGANIZATION_CUSTOM_STYLES_KEY } = config.dataKeys;

interface JWTResponse {
  userId: string;
  exp: number;
  iat: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url: string;

  TKN_KEY : string;

  SENDER_TYPE = SENDER_TYPE;

  USER_ROLES = USER_ROLES;

  conversationSubject: Subject<any>;

  conversation: any;

  private userDataCache: User | null = null;

  constructor(private jwtHelper: JwtHelperService, public dataService: DataService, public http: HttpClient) {
    this.url = environment.APP_API_URL;
    this.TKN_KEY = config.dataKeys.TKN_KEY;
    this.conversationSubject = new Subject();
    this.conversationSubject.subscribe((conversation: any) => this.onConvesationChange(conversation));
  }

  private onUserChange(user: User) {
    this.userDataCache = user;
    const customStyles = user?.organization?.customContent?.styles;
    if (customStyles) {
      applyCustomStyles(customStyles);
      this.dataService.localSet(ORGANIZATION_CUSTOM_STYLES_KEY, customStyles);
    }
  }

  public signup(authData: {
    email: string;
    password: string;
    hasAcceptedTerms:boolean
  }): Observable<any> {
    return this.http.post<User>(`${this.url}/auth/signup`, authData).pipe(
      map(this.setUserSession.bind(this)),
    );
  }

  public signin(authData: {
    email: string;
    password: string; }): Observable<any> {
    return this.http.post<User>(`${this.url}/auth/signin`, authData).pipe(
      map(this.setUserSession.bind(this)),
    );
  }

  public signOut(): void {
    this.clearUserSession();
  }

  public loginBySSO(token: string | null): Observable<User> {
    return this.http.post<User>(`${this.url}/auth/sso`, { token }).pipe(
      map((response: User) => {
        if (!response || !response.token) {
          throw new Error('Invalid sso token');
        }
        this.clearUserSession();
        return this.setUserSession(response);
      }),
    );
  }

  public checkSignin() {
    const token = this.dataService.get(this.TKN_KEY);
    return !!token;
  }

  // USERS

  public getUserData():Promise<User> {
    if (this.userDataCache) {
      return Promise.resolve(this.userDataCache);
    }
    return new Promise((resolve) => {
      this.getUserMe().subscribe(
        (response: User) => {
          this.onUserChange(response);
          resolve(response);
        },
      );
    });
  }

  get styles() {
    return this.userDataCache?.organization?.customContent?.styles;
  }

  get organizationId() {
    return this.userDataCache?.organizationId;
  }

  public getUserMe():Observable<User> {
    return this.http.get<User>(`${this.url}/api/users/me`);
  }

  public udpateUser(
    body: { language?: string; },
  ): Promise<User> {
    return new Promise((resolve) => {
      this.http.patch<User>(
        // eslint-disable-next-line no-underscore-dangle
        `${this.url}/api/users/${this.userDataCache?._id}`,
        body,
      ).subscribe(
        (response: User) => {
          this.onUserChange(response);
          resolve(response);
        },
      );
    });
  }

  // CONVERSATIONS

  public getConversation(conversationId: string, includeMessages: boolean): Observable<Conversation> {
    const params: {
      [param: string]: string;
    } = {
      includeMessages: includeMessages ? 'true' : 'false',
    };

    return this.http.get<Conversation>(`${this.url}/api/conversations/${conversationId}`, { params });
  }

  public getConversationMessages(conversationId: string): Observable<{ messages:Message[], total:number }> {
    return this.http.get<{ messages:Message[], total:number }>(
      `${this.url}/api/conversations/${conversationId}/messages`,
    );
  }

  public searchConversations(filter: any): Observable<Conversation> {
    const params: {
      [param: string]: string;
    } = buildQuery(filter);

    return this.http.get<Conversation>(`${this.url}/api/conversations`, { params });
  }

  public createConversation(newConversation: CreateConversation): Observable<Conversation> {
    return this.http.post<Conversation>(`${this.url}/api/conversations`, newConversation);
  }

  public updateConversation(
    conversationId: string,
    params: { title?: string; isFavourite?: boolean },
  ): Observable<Conversation> {
    return this.http.patch<Conversation>(
      `${this.url}/api/conversations/${conversationId}`,
      params,
    );
  }

  public sendMessage(conversationId: string, text: string, metaData: Record<string, any>): Observable<Conversation> {
    const body: any = {
      text,
    };
    if (metaData) body.metadata = metaData;
    return this.http.post<Conversation>(`${this.url}/api/conversations/${conversationId}/messages`, body);
  }

  public updateConversationTitle(conversationId: string, title: string): Observable<Conversation> {
    return this.updateConversation(conversationId, { title });
  }

  public deleteConversation(conversationId: string): Observable<Conversation> {
    return this.http.delete<Conversation>(`${this.url}/api/conversations/${conversationId}`);
  }

  private onConvesationChange(conversation: any) {
    this.conversation = conversation;
  }

  private parseMessage(message: any) {
    if (!message) return {};
    const messageParsed = { ...message, createdAt: new Date(message.createdAt) };
    if (message.senderType === SENDER_TYPE.USER) return messageParsed;
    const sources = message.metadata?.sources || [];
    messageParsed.htmlText = `<span>${message.text}</span>`;
    if (!sources?.length) return messageParsed;
    const totalSources = sources.length;
    for (let i = 0; i < totalSources; i += 1) {
      const {
        fileId, pageNumber, paragraphNumber, filePath,
      } = sources[i];
      if (filePath) {
        const ref1 = `[${fileId}_${pageNumber || ''}_${paragraphNumber || ''}]`;
        const ref2 = `[${fileId}]`;
        const filename = filePath?.split('/').pop();
        const link1 = `</span>
            <a class="link bold" href="${this.url}/${filePath}?#page=${pageNumber || '1'}" target="_blank">
              ${filename}(${pageNumber})
            </a>
          <span>`;
        const link2 = `</span>
          <a class="link bold" href="${this.url}/${filePath}" target="_blank">
            ${filename}
          </a>
        <span>`;
        messageParsed.htmlText = messageParsed.htmlText.replace(ref1, link1);
        messageParsed.htmlText = messageParsed.htmlText.replace(ref2, link2);
      }
    }

    return messageParsed;
  }

  private parseMessages(messages: any) {
    if (!messages?.length) return [];
    const totalMessages = messages.length;
    const messagesParsed = [];
    for (let i = 0; i < totalMessages; i += 1) {
      const message = messages[i];
      const messageParsed = this.parseMessage(message);
      messagesParsed.push(messageParsed);
    }

    return messagesParsed;
  }

  private parseConversationMessages(data: any) {
    const messages = this.parseMessages(data?.messages);
    return { ...data, messages };
  }

  private parseConversation(conversation: any) {
    if (!conversation) return conversation;
    const conversationParsed = { ...conversation };
    const keys = Object.keys(conversation);
    const total = keys.length;
    for (let i = 0; i < total; i += 1) {
      const key = keys[i];
      if (key.includes('Date') && conversation[key]) conversationParsed[key] = new Date(conversation[key]);
      else if (key === 'messages') conversationParsed[key] = this.parseMessages(conversationParsed[key]);
    }

    return conversationParsed;
  }

  public setNextConversation(conversation: any) {
    const conversationParsed = this.parseConversation(conversation);
    this.conversationSubject.next(conversationParsed);
  }

  private parseConversations(data: any) {
    const { conversations } = data;
    const totalConversations = conversations.length;
    const conversationsParsed = [];
    for (let i = 0; i < totalConversations; i += 1) {
      const conversation = conversations[i];
      const conversationParsed = this.parseConversation(conversation);
      conversationsParsed.push(conversationParsed);
    }

    return { ...data, conversations: conversationsParsed };
  }

  private handleSendMessageResponse(data: any) {
    const questionMessage = this.parseMessage(data.questionMessage);
    const answerMessage = this.parseMessage(data.answerMessage);
    return { questionMessage, answerMessage };
  }

  // SOURCES

  public getSource(src: string): Observable<any> {
    return this.http.get(`${this.url}/${src}`, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  private clearUserSession():void {
    this.userDataCache = null;
    this.conversation = {};
    this.dataService.remove(this.TKN_KEY);
    this.dataService.localRemove(ORGANIZATION_CUSTOM_STYLES_KEY);
  }

  private setToken(token: string): void {
    const jwtData = this.jwtHelper.decodeToken<JWTResponse>(
      token,
    );
    // jwtData?.exp to miliseconds
    const expirationDateMs = jwtData?.exp ? Number(jwtData?.exp) * 1000 : 0;
    const expirationDate = new Date(expirationDateMs);
    this.dataService.set(this.TKN_KEY, token, expirationDate);
  }

  private setUserSession(sessionUser: User): User {
    this.setToken(sessionUser.token);
    this.onUserChange(sessionUser);
    return sessionUser;
  }

  public setSessionFromToken(token: string): Subject<User | null> {
    this.clearUserSession();
    const session$: Subject<User | null> = new Subject();
    if (!token) {
      session$.next(null);
    } else {
      this.setToken(token);
      this.getUserMe().subscribe((currentUser: User) => {
        this.setUserSession({
          ...currentUser,
          token,
        });
        session$.next(currentUser);
      });
    }
    return session$;
  }

  public setWelcomeDisclaimers(disclaimers: string[]): void {
    if (this.userDataCache && this.userDataCache.organization?.customContent?.texts) {
      this.userDataCache = {
        ...this.userDataCache,
        organization: {
          ...this.userDataCache?.organization,
          customContent: {
            ...this.userDataCache?.organization?.customContent,
            texts: {
              ...this.userDataCache?.organization?.customContent?.texts,
              welcomeDisclaimers: disclaimers,
            },
          },
        },
      };
    }
  }
}
