import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '~environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomStylesService {
  #url: string;

  constructor(private http: HttpClient) {
    this.#url = environment.APP_API_URL;
  }

  public updateColors(organizationId: string, body: any): Observable<any> {
    return this.http.patch<any>(`${this.#url}/api/organizations/${organizationId}`, body);
  }

  public updateImage(organizationId: string, formData: FormData): Observable<any> {
    return this.http.patch<any>(`${this.#url}/api/organizations/${organizationId}/images`, formData);
  }
}
