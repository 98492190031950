<div class="chat-tools-container">
  <p-button
    icon="pi pi-envelope"
    iconPos="left"
    label="{{ (createEmailButtonLoading ? 'CHAT.SHARE.BY_EMAIL.LOADING' : 'CHAT.SHARE.BY_EMAIL.BUTTON') | translate }}"
    (click)="shareByEmail($event)"
    styleClass="p-button-rounded p-button-outlined">
  </p-button>
  <p-button
    id="copy-confirm-btn"
    ngClass="{blink: copyButtonLoading}"
    icon="pi pi-copy"
    iconPos="left"
    [disabled]="copyButtonLoading"
    label="{{ (copyButtonLoading ? 'CHAT.COPY.LOADING' : 'CHAT.COPY.BUTTON') | translate }}"
    (click)="copyMessage($event)"
    styleClass="p-button-rounded p-button-outlined">
  </p-button>
</div>
