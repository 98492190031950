import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { ColorPickerModule } from 'primeng/colorpicker';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';

import { ComponentsModule } from '~app/components/components.module';

import { CustomStylesComponent } from './custom-styles.component';
import { CustomStylesRoutingModule } from './custom-styles-routing.module';

@NgModule({
  declarations: [
    CustomStylesComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    TabViewModule,
    InputTextModule,
    InputTextareaModule,
    FileUploadModule,
    ColorPickerModule,
    OverlayPanelModule,
    CustomStylesRoutingModule,
    CommonModule,
    ComponentsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CustomStylesModule { }
