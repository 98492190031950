<div class="filter-panel">
  <div class="filter-panel-header">
    <span class="bold">{{ text.sortTtile | translate }}</span>
  </div>
  <p-selectButton
    class="sort-buttons"
    [options]="sortOptions"
    [(ngModel)]="sortValue"
    optionLabel="label"
    optionValue="value"></p-selectButton>
  <div
    class="filter-panel-header last-item"
    *ngIf="options.length">
    <span class="bold">{{ text.selectTitle || "" | translate }}</span>
  </div>
  <div
    class="multi-select-container"
    *ngIf="options.length">
    <p-multiSelect
      class="multi-select filter-select"
      [(ngModel)]="optionsSelected"
      [options]="options"
      [placeholder]="text.selectPlaceholder || '' | translate"
      (onChange)="setTypeFilter($event.value)"
      optionLabel="name">
      <ng-template
        let-option
        pTemplate="item">
        <div class="p-multiselect-representative-option">
          <span class="p-ml-1">{{ option.name }}</span>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>
  <div
    class="filter-panel-header last-item"
    *ngIf="dateInput">
    <span class="bold">{{ text.dateTitle || "" | translate }}</span>
  </div>
  <div
    class="date-container"
    *ngIf="dateInput">
    <p-calendar
      #calendarFilter
      class="form-input"
      [(ngModel)]="dateFilterOptionsSelected"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      [placeholder]="text.datePlaceholder || '' | translate"
      [readonlyInput]="true"
      (onSelect)="onSelect(calendarFilter)">
    </p-calendar>
  </div>
  <div class="filter-footer">
    <p-button
      class="footer-button"
      [label]="text.clearBtn | translate"
      styleClass="p-button-outlined small-btn"
      (onClick)="removeFilter()"></p-button>
    <p-button
      class="footer-button"
      [label]="text.applyBtn | translate"
      styleClass="small-btn"
      (onClick)="applyFilter()"></p-button>
  </div>
</div>
