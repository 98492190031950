import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as saveAs from 'file-saver';
import * as JSZip from 'jszip';
import { take } from 'rxjs/operators';

import { ChatLog } from '~app/models/chat-log';
import { ApiService } from '~app/services/api/api.service';

@Component({
  selector: 'gpta-sources-modal',
  templateUrl: './sources-modal.component.html',
  styleUrls: ['./sources-modal.component.scss'],
})
export class SourcesModalComponent implements OnInit, OnChanges {
  @Input() visible = false;

  @Input() chatLog: ChatLog | undefined;

  @Output() visibleChange = new EventEmitter<boolean>();

  public title = '';

  public urls: any[] = [];

  constructor(private translateService: TranslateService, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.title = this.translateService.instant('ADMIN.CHAT_LOG.SOURCE_MODAL.TITLE');
  }

  ngOnChanges(): void {
    this.title = this.translateService.instant('ADMIN.CHAT_LOG.SOURCE_MODAL.TITLE');
    if (this.chatLog && this.chatLog.answerMessage
      && this.chatLog.answerMessage.metadata
      && this.chatLog.answerMessage.metadata['sources']?.length) {
      this.urls = this.chatLog.answerMessage.metadata['sources'].filter((source: any) => source.filePath);
    }
  }

  onClose(): void {
    this.visibleChange.emit(false);
  }

  downloadSources(): void {
    const zip = new JSZip();

    if (!this.urls.length) {
      this.onClose();
    }

    let completedRequests = 0;

    this.urls.forEach((source: any) => {
      this.apiService.getSource(source.filePath).pipe(take(1)).subscribe(
        (response: any) => {
          zip.file(`sources/${source.filename}`, response.body);
          completedRequests += 1;
          if (completedRequests === this.urls.length) {
            zip.generateAsync({ type: 'blob' }).then((content: any) => {
              saveAs(content, 'sources.zip');
              this.onClose();
            });
          }
        },
      );
    });
  }
}
