import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AnalyticsDto, AnayticsResponse } from '~app/models/analytics';
import { buildQuery } from '~helpers/query-builder.helper';

import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends ApiService {
  chatAnalyticsSubject = new BehaviorSubject<any>(null);

  analytics$ = this.chatAnalyticsSubject.asObservable();

  nextAnalytics(response: AnayticsResponse) {
    this.chatAnalyticsSubject.next(response);
  }

  getAnalytics(searchChatLogDto: AnalyticsDto) {
    const params: {
      [param: string]: string;
    } = buildQuery(searchChatLogDto);
    this.http.get<AnayticsResponse>(`${this.url}/api/analytics`, { params }).subscribe((res) => {
      this.nextAnalytics(res);
    });
  }
}
