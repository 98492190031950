import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';

import { ComponentsModule } from '../../components/components.module';
import { ManagFaqsModalComponent } from './components/manage-faqs-modal/manage-faqs-modal.component';
import { FaqsComponent } from './faqs.component';
import { FaqsRoutingModule } from './faqs-routing.module';

@NgModule({
  declarations: [
    FaqsComponent,
    ManagFaqsModalComponent,
  ],
  imports: [
    CommonModule,
    FaqsRoutingModule,
    TranslateModule,
    ComponentsModule,
    ButtonModule,
    DialogModule,
    OverlayPanelModule,
    CardModule,
    InputTextModule,
    InputTextareaModule,
    FormsModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
})
export class FaqsModule { }
