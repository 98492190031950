import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertPercentage',
})
export class ConvertPercentagePipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: number | undefined): number | undefined {
    if (value && value <= 10) {
      return value * 10;
    }
    return 100;
  }
}
