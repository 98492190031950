import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ApiService } from '~services/api/api.service';

export const hasUserDataGuard: CanActivateFn = async () => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  const translate = inject(TranslateService);
  const userData = await apiService.getUserData();
  if (userData) {
    translate.use(userData.language);
    return true;
  }
  router.navigate(['/login']);
  return false;
};
