<div class="admin-content">
  <div class="admin-content-container">
    <div class="body">
      <div class="container">
        <div class="header-content">
          <div>
            <h1 class="title bold">
              <ng-content select="[title]"></ng-content>
            </h1>
          </div>
          <div>
            <ng-content select="[side]"></ng-content>
          </div>
        </div>
        <div class="content">
          <ng-content select="[content]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
