<p-dialog
  [header]="title"
  (onHide)="onClose()"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '70vw' }"
  [draggable]="false"
  [resizable]="false">
  <div class="info-container">
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.CHAT_MODAL.INFORMATION.USER" | translate }}</div>
      <div class="row-content">{{ chatLog?.user?.name }}</div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.CHAT_MODAL.INFORMATION.USER_ID" | translate }}</div>
      <div class="row-content">{{ chatLog?.user?.reference }}</div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.CHAT_MODAL.INFORMATION.DATE" | translate }}</div>
      <div class="row-content">{{ chatLog?.questionMessage?.creationDate | date: "HH:mm, dd/MM/yyyy" }}</div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.CHAT_MODAL.INFORMATION.QUESTION" | translate }}</div>
      <div class="row-content-container">
        <div
          class="row-content"
          *ngFor="let content of chatLog?.questionMessage?.content">
          {{ content.text ?? "" }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.CHAT_MODAL.INFORMATION.ANSWER" | translate }}</div>
      <div
        class="row-content-container answer"
        *ngIf="chatLog as log">
        <div
          class="row-content"
          *ngIf="!log.answerMessage">
          -
        </div>
        <div
          class="row-content"
          *ngFor="let item of log.answerMessage?.content">
          <div
            *ngIf="item.type === MESSAGE_CONTENT_TYPES.TEXT"
            [innerHtml]="item.html || item.text"></div>
          <div
            *ngIf="item.type === MESSAGE_CONTENT_TYPES.SELECTOR"
            class="selector-container avoid-copy">
            <gpta-chat-selector
              [messageId]="log.answerMessage ? log.answerMessage._id : ''"
              [isDisabled]="true"
              [textOptions]="item.textOptions"
              [minSelections]="item.minSelections"
              [maxSelections]="item.maxSelections"></gpta-chat-selector>
          </div>
          <div
            *ngIf="item.type === MESSAGE_CONTENT_TYPES.CHART && item.chart"
            class="chart-container">
            <gpta-chat-chart
              [chartData]="item.chart"
              [isAnimated]="false"></gpta-chat-chart>
          </div>
          <div
            *ngIf="item.type === MESSAGE_CONTENT_TYPES.WEIGHTS"
            class="chart-container weights-container copy-as-img copy-on-email">
            <gpta-chat-weights-list
              [weightsData]="item.weights"
              [isAnimated]="false"></gpta-chat-weights-list>
          </div>
          <div
            *ngIf="item.type === MESSAGE_CONTENT_TYPES.TABLE"
            class="table-container copy-on-email">
            <gpta-chat-table [tableData]="item.table"></gpta-chat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
