import {
  Component, EventEmitter, Input, OnChanges, Output, ViewChild,
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Observable } from 'rxjs';

import WindowProperties from '~app/models/window-prop';
import { WindowSizeHelper } from '~app/services/helpers/screen-size.helper';
import { environment } from '~environments/environment';

@Component({
  selector: 'gpta-chat-pdf-viewer',
  templateUrl: './chat-pdf-viewer.component.html',
  styleUrls: ['./chat-pdf-viewer.component.scss'],
})
export class ChatPdfViewerComponent implements OnChanges {
  @Input() source: any;

  @Output() closePdf: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(PdfViewerComponent) private pdfComponent!: PdfViewerComponent;

  public src = environment.APP_API_URL;

  public page: number;

  public totalPages = 1;

  public isLoaded = false;

  public windowProperties: Observable<WindowProperties>;

  constructor(private windowSizeHelper: WindowSizeHelper) {
    this.page = Number(this.source?.pageNumber);
    this.windowProperties = this.windowSizeHelper.getWindowProperties();
  }

  ngOnChanges(): void {
    this.src = environment.APP_API_URL;
    this.src = this.source.url || `${this.src}/${this.source.filePath}`;
    this.page = Number(this.source.pageNumber);
  }

  goToPage(event: any): void {
    if (event.pageNumber === Number(this.source.pageNumber)) {
      this.page = event.pageNumber;
    }
  }

  getLastPage(event:any): void {
    this.totalPages = event.numPages;
    this.isLoaded = true;
    setTimeout(() => {
      this.pdfComponent?.pdfViewer?.scrollPageIntoView({
        pageNumber: this.page,
      });
    }, 100);
  }

  prevPage(): void {
    if (this.page > 1) {
      this.page -= 1;
    }
  }

  nextPage(): void {
    if (this.page < this.totalPages) {
      this.page += 1;
    }
  }

  closePdfViewer(): void {
    this.isLoaded = false;
    this.closePdf.emit(true);
  }
}
