<div class="welcome-placeholder">
  <div class="welcome-image"></div>
  <p class="welcome-title">
    {{ welcomeTitle }} <b>{{ companyName }}</b>
  </p>
  <div *ngIf="welcomeDisclaimers?.length">
    <div *ngFor="let disclaimer of welcomeDisclaimers">
      <p
        *ngIf="disclaimer"
        class="welcome-description text-small"
        [innerHTML]="disclaimer"></p>
    </div>
  </div>
</div>
