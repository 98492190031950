import config from '~config/constants';
import { DataService } from '~services/data/data.service';

const { TKN_KEY } = config.dataKeys;

export function jwtOptionsFactory(dataService: DataService) {
  return {
    tokenGetter: () => dataService.get(TKN_KEY),
  };
}
