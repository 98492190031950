<div
  *ngIf="cards?.length"
  class="cards-container"
  [ngClass]="{ 'is-animated': isAnimated }">
  <div
    *ngFor="let card of cards"
    class="card">
    <div class="card-body">
      <div class="card-title">{{ card.title.length > 40 ? (card.title | slice: 0 : 40) + "..." : card.title }}</div>
      <div
        class="card-description"
        class="bold">
        {{ card.description || "" }}
      </div>
    </div>
  </div>
</div>
