import HostConf from '~models/host-conf';

const hostConfs:HostConf = {
  hostName: 'default',
  chat: {
    placeholder: {
      visible: false,
      html: './host-htmls/default.html',
      style: './host-styles/default.scss',
    },
    header: {
      visible: false,
      html: './host-htmls/default.html',
      style: './host-styles/default.scss',
    },
    chart: {
      line: {
        options: {
          responsive: true,
          // maintainAspectRatio: false,
          aspectRatio: 3,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true,
                boxWidth: 2,
                boxHeight: 2,
              },
            },
          },
          scales: {
            y: {
              ticks: {
                maxTicksLimit: 6,
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                align: 'start',
                maxTicksLimit: 7,
                maxRotation: 0,
                minRotation: 0,
              },
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
        },
        mobileOptions: {
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              align: 'start',
              labels: {
                usePointStyle: true,
                boxWidth: 4,
                boxHeight: 4,
                font: {
                  size: 16,
                },
              },
            },
          },
          scales: {
            y: {
              ticks: {
                maxTicksLimit: 6,
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                align: 'start',
                maxTicksLimit: 6,
                maxRotation: 50,
                minRotation: 50,
              },
            },
          },
        },
      },
      doughnut: {
        options: {
          plugins: {
            legend: {
              position: 'right',
              display: false,
            },
            title: {
              display: false,
            },
          },
        },
      },
    },
  },
};
export default hostConfs;
