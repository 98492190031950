import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable, takeWhile } from 'rxjs';

import { SEARCH_DELAY } from '~app/config/constants/search';
import { Conversation } from '~app/models/conversation';
import { ManageUser, ManageUserResponse, SearchUsersDTO } from '~app/models/manage-users';
import { USER_ROLES } from '~app/models/roles.enum';
import { ApiService } from '~app/services/api/api.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';
import { UsersService } from '~app/services/users/users.service';
import { ComponentAction } from '~models/component-action';

@Component({
  selector: 'gpta-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  totalElements = 0;

  users$: Observable<ManageUserResponse | null>;

  users: ManageUser[] = [];

  editUser: ManageUser | null = null;

  userFilter: SearchUsersDTO = {
    searchString: '', role: '', skip: 0, limit: 10, sortField: '',
  };

  totalRecords = 0;

  loading = false;

  showManageUserModal = false;

  isAlive = true;

  roles: { id: number, value: string }[] = [];

  selectedRol = 0;

  typingTimer: any;

  page = 0;

  search_delay: number = SEARCH_DELAY;

  searchLoading = false;

  constructor(
    private usersService: UsersService,
    private titleCasePipe: TitleCasePipe,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
  ) {
    this.users$ = this.usersService.userList$;
  }

  ngOnInit() {
    this.getRoles();
    this.getUserList();
    this.users$.subscribe((res: ManageUserResponse | null) => {
      if (res?.users) this.users = [...res.users];
      if (res?.total) this.totalRecords = res.total;
      this.loading = false;
      this.searchLoading = false;
    });

    this.usersService.needReloadTable$.pipe(takeWhile(() => this.isAlive)).subscribe((res) => {
      if (res) {
        this.getUserList();
      }
    });
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  getRoles(): void {
    const firstRolValue = this.translateService.instant('COMMON.ALL');
    const firstRol = { id: 0, value: firstRolValue };
    this.roles.push(firstRol);
    const stringRoles = Object.values(USER_ROLES);
    stringRoles.forEach((rol, index: number) => {
      const parsedRol = {
        id: index + 1,
        value: this.titleCasePipe.transform(rol),
      };
      this.roles.push(parsedRol);
    });
  }

  updateRol(): void {
    if (this.selectedRol) {
      this.userFilter.role = this.roles[this.selectedRol].value.toUpperCase();
    } else {
      this.userFilter.role = '';
    }

    this.getUserList();
  }

  onLazyLoadUsers(event: TableLazyLoadEvent): void {
    this.userFilter.skip = event.first;
    if (event.sortField) this.userFilter.sortField = event.sortField as string;
    if (event.sortOrder) this.userFilter.sortOrder = event.sortOrder;
    if (event.rows) this.userFilter.limit = event.rows;
    this.getUserList();
  }

  onSearch(): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.searchLoading = true;
      this.page = 0;
      this.getUserList();
    }, this.search_delay);
  }

  getUserList(): void {
    this.loading = true;
    this.usersService.getUserList(this.userFilter);
  }

  createUser(): void {
    this.showManageUserModal = true;
  }

  onEditUser(user: ManageUser): void {
    this.editUser = user;
    this.showManageUserModal = true;
  }

  clearUser(): void {
    this.editUser = null;
  }

  onRemoveUser(user: ManageUser): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('ADMIN.USERS.REMOVE_TITLE'),
      message: this.translateService.instant('ADMIN.USERS.ARE_YOU_SURE'),
      rejectLabel: this.translateService.instant('COMMON.CANCEL'),
      acceptLabel: this.translateService.instant('COMMON.DELETE'),
      acceptButtonStyleClass: 'p-button-rounded large-btn',
      rejectButtonStyleClass: 'p-button-rounded p-button-outlined large-btn admin-outline-btn',
      accept: () => {
        // eslint-disable-next-line no-underscore-dangle
        this.usersService.removeUser(user._id);
      },
    });
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.apiService.setNextConversation({});
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate(['/chat', conversation._id]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
