import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { USER_ROLES } from '~app/models/roles.enum';
import { ApiService } from '~services/api/api.service';

export const isAdminGuard: CanActivateFn = async () => {
  const apiService = inject(ApiService);
  const router = inject(Router);
  const userData = await apiService.getUserData();
  if (userData?.role === USER_ROLES.ADMIN) {
    return true;
  }
  router.navigate(['/chat']);
  return false;
};
