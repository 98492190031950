// API model use underscore
/* eslint-disable no-underscore-dangle */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError, map, Observable, Subject, Subscription,
} from 'rxjs';

import { ChatService } from '~app/services/chat/chat.service';
import config from '~config/constants';
import { ComponentAction } from '~models/component-action';
import { Conversation } from '~models/conversation';
import { Message } from '~models/message';
import { MESSAGE_CONTENT_TYPES } from '~models/message.enum';
import { MessageContent } from '~models/message-content';
import { USER_ROLES } from '~models/roles.enum';
import { SENDER_TYPE } from '~models/sender.enum';
import { User } from '~models/user';
import WindowProperties from '~models/window-prop';
import { ApiService } from '~services/api/api.service';
import { isDoAction } from '~services/helpers/componentAction.helper';
import { WindowSizeHelper } from '~services/helpers/screen-size.helper';

@Component({
  selector: 'gpta-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  public conversationId = '';

  public isNew = true;

  public isEdition = false;

  public isChatLoading = false;

  public loadingMessage: string | undefined;

  public conversation: Conversation = {} as Conversation;

  public messages: Message[] = [];

  public currentAnswer = {} as Message;

  public currentQuestion = {} as Message;

  public text = '';

  public tempText = '';

  public showExamples = false;

  public user: User = {} as User;

  public SENDER_TYPE = SENDER_TYPE;

  public USER_ROLES = USER_ROLES;

  public displayPromptDialog = false;

  public messageSelected: any;

  public MESSAGE_CONTENT_TYPES = MESSAGE_CONTENT_TYPES;

  public showPdfViewer = false;

  public selectedPdfSource: any;

  public lastResponseContentObservable: Subject<MessageContent[]> = new Subject();

  private conversationSubscription: Subscription = new Subscription();

  public windowProperties: Observable<WindowProperties>;

  public showSuggestions = true;

  private isConversationChange = false;

  private metaData: any;

  private isCreatingConversation = false;

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private windowSizeHelper: WindowSizeHelper,
    private chatService: ChatService,
  ) {
    this.conversationSubscription = this.apiService.conversationSubject.subscribe(
      async (conversation: any) => {
        if (this.isConversationChange) {
          this.setConversation(conversation);
          if (this.conversationId) {
            await this.initializeChat();
            this.isConversationChange = false;
          }
        }
      },
    );
    this.windowProperties = this.windowSizeHelper.getWindowProperties();
  }

  async ngOnInit(): Promise<void> {
    this.conversationId = this.activatedRoute.snapshot.params['conversationId'];
    const { initialMessage } = this.activatedRoute.snapshot.queryParams;
    if (initialMessage) {
      this.text = initialMessage;
      await this.createNewChat();
    }

    if (this.conversationId) {
      this.isNew = false;
      await this.initializeChat();
    }
    if (!this.conversationId && !initialMessage) {
      this.chatService.getInitialFollowUp();
    }
    this.setConversation(this.apiService.conversation || {});
  }

  ngOnDestroy(): void {
    this.conversationSubscription.unsubscribe();
    this.chatService.setFollowUpList([]);
  }

  initializeChat(): Promise<any> {
    return new Promise((resolve) => {
      if (this.apiService.conversation?._id === this.conversationId) {
        this.conversation = this.apiService.conversation;
        resolve(this.conversation);
      } else {
        this.apiService.getConversation(this.conversationId, true)
          .pipe()
          .subscribe((response: any) => {
            this.conversation = response;
            this.apiService.setNextConversation(this.conversation);
            resolve(this.conversation);
          });
      }
    });
  }

  resetChat(action:ComponentAction): void {
    if (!isDoAction(action)) return;
    this.conversation = {} as Conversation;
    this.messages = [];
    this.chatService.setFollowUpList([]);
    this.currentAnswer = {} as Message;
    this.currentQuestion = {} as Message;
    this.text = '';
    this.tempText = '';
    this.isNew = true;
    this.isEdition = false;
    this.conversationId = '';
    this.showPdfViewer = false;
    this.apiService.setNextConversation({});
    this.chatService.getInitialFollowUp();
    this.clearUrl();
  }

  createNewChat(force = false): Promise<any> {
    const isEmptyText = !this.text || !this.text.trim();
    if (isEmptyText) {
      this.showPdfViewer = false;
      return Promise.reject(new Error('Empty message'));
    }
    this.showPdfViewer = false;
    if (!force && this.conversationId) {
      return Promise.resolve(this.conversation);
    }
    this.showLoading(
      this.translateService.instant('CHAT.CREATING_CONVERSATION'),
    );
    this.tempText = this.text;
    this.text = '';
    const title = this.generateChatTitle();
    return new Promise((resolve, reject) => {
      const data = { title, text: this.tempText || '', metadata: this.metaData };
      this.apiService
        .createConversation(data)
        .pipe()
        .subscribe(
          (response: any) => {
            this.metaData = undefined;
            this.isConversationChange = true;
            this.isCreatingConversation = true;
            const newConversation = response;
            this.apiService.setNextConversation(newConversation);
            this.text = '';
            this.tempText = '';
            this.conversationId = newConversation._id;
            resolve(newConversation);
            this.hideLoading();
            if (this.conversation) {
              this.clearUrl();
            }
          },
          (error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            this.metaData = undefined;
            this.text = this.tempText;
            this.messages.pop();
            reject(error);
          },
        );
    });
  }

  sendMessage(): void {
    this.chatService.setFollowUpList([]);
    if (!this.text) {
      return;
    }
    this.showSuggestions = false;
    this.tempText = this.text;
    this.text = '';
    this.updateConversationTitle();
    this.apiService
      .sendMessage(this.conversation._id, this.tempText, this.metaData)
      .pipe(
        map((response: any) => {
          this.currentAnswer.loading = false;
          this.messages = this.messages.map((message) => {
            if (message === this.currentQuestion) {
              return response.questionMessage;
            }
            if (message === this.currentAnswer) {
              this.setLastResponseContentObservable(response.answerMessage);
              this.currentAnswer = response.answerMessage;
              return response.answerMessage;
            }
            return message;
          });
          this.text = '';
          this.tempText = '';
          ChatComponent.scrollMessagesToBottom();
          this.setSuggestions();
          this.metaData = undefined;
        }),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.text = this.tempText;
          this.currentAnswer.content = [{
            type: MESSAGE_CONTENT_TYPES.TEXT,
            text: this.translateService.instant('CHAT.ERROR_SENDING_MESSAGE'),
          }];
          this.currentAnswer.loading = false;
          this.currentAnswer.hasError = true;
          this.messages = [...this.messages];
          this.setSuggestions();
          this.metaData = undefined;
          return error;
        }),
      )
      .subscribe();
  }

  handleSendMessage() {
    if (!this.text?.trim()) return;
    this.createNewChat().then(() => {
      this.currentQuestion = {
        content: [{ type: MESSAGE_CONTENT_TYPES.TEXT, text: this.text }],
        senderType: this.SENDER_TYPE.USER,
      };
      this.currentAnswer = {
        content: [{ type: MESSAGE_CONTENT_TYPES.TEXT, text: '' }],
        senderType: this.SENDER_TYPE.AI,
        loading: true,
      };
      this.messages.push(this.currentQuestion);
      this.messages.push(this.currentAnswer);
      if (this.conversation?._id) {
        ChatComponent.scrollMessagesToBottom();
        this.sendMessage();
      }
    });
    this.showPdfViewer = false;
  }

  onOptionSelected(action: ComponentAction) {
    if (!isDoAction(action)) return;
    const { optionText }: { optionText: string } = action.data;
    if (!optionText) return;
    if (action.data.metaData) {
      this.metaData = { ...action.data.metaData };
      if (this.currentAnswer?.metadata?.['isin']) {
        this.metaData.isin = this.currentAnswer.metadata['isin'];
      }
    }
    this.text = optionText;
    this.handleSendMessage();
  }

  onConversationChange(action: ComponentAction) {
    if (isDoAction(action)) {
      this.isConversationChange = true;
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      this.showPdfViewer = false;
    }
  }

  getConversationMessages(conversation: any) {
    this.chatService.setFollowUpList([]);
    if (!conversation._id) {
      this.messages = [];
      return;
    }
    this.showLoading(this.translateService.instant('CHAT.LOADING_MESSAGES'));
    this.apiService
      .getConversationMessages(conversation._id)
      .pipe()
      .subscribe(
        (response: any) => {
          this.hideLoading();
          if (this.isCreatingConversation) {
            this.messages = [];
            this.isCreatingConversation = false;
            this.messages.push(response.messages[0]);
            this.setLastResponseContentObservable(response.messages[1]);
            this.currentAnswer = response.messages[1];
            this.messages.push(response.messages[1]);
          } else {
            this.messages = response.messages;
          }
          this.setSuggestions();
          if (this.messages.length) ChatComponent.scrollMessagesToBottom();
        },
        (error: any) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.messages = [];
        },
      );
  }

  keyDownFunction(event: any) {
    if (event?.keyCode === 13) {
      this.handleSendMessage();
    }
  }

  private setLastResponseContentObservable(message: Message) {
    const TIMES_CONF = config.chat.NEW_MESSAGE_ANIMATION_TIMES;
    let timeSum = 0;
    let currentIndex = 0;
    let letterIndex = 0;

    const postEmitted = () => {
      setTimeout(() => {
        this.showSuggestions = true;
        ChatComponent.scrollMessagesToBottom();
        if (currentIndex === message.content.length - 1) {
          this.lastResponseContentObservable.next(message.content);
          this.currentAnswer = {
            content: [{ type: MESSAGE_CONTENT_TYPES.TEXT, text: '' }],
            senderType: this.SENDER_TYPE.AI,
            loading: false,
            _id: '',
          };
        } else {
          emitContent(message.content[currentIndex]);
        }
      }, 100);
    };

    const emitHtmlLetterByLetter = (html: string) => {
      if (letterIndex < html.length) {
        if (html[letterIndex] === '<') {
          const closingTagIndex = html.indexOf('>', letterIndex);
          letterIndex = closingTagIndex + 1;
        }
        setTimeout(() => {
          this.lastResponseContentObservable.next([
            ...message.content.slice(0, currentIndex),
            {
              ...message.content[currentIndex],
              html: html.slice(0, letterIndex),
            },
          ]);
          timeSum += TIMES_CONF.BY_CHAR;
          letterIndex += 1;
          emitHtmlLetterByLetter(html);
        }, TIMES_CONF.BY_CHAR);
        ChatComponent.scrollMessagesToBottom();
      } else {
        currentIndex += 1;
        if (currentIndex < message.content.length
          && message.content[currentIndex].type === MESSAGE_CONTENT_TYPES.TEXT) {
          letterIndex = 0;
          emitHtmlLetterByLetter(message.content[currentIndex].html ?? '');
        } else if (currentIndex < message.content.length
          && message.content[currentIndex].type !== MESSAGE_CONTENT_TYPES.TEXT) {
          emitContent(message.content[currentIndex]);
        } else {
          postEmitted();
        }
      }
    };
    const emitContent = (content: MessageContent) => {
      if (content.type === MESSAGE_CONTENT_TYPES.TEXT) {
        emitHtmlLetterByLetter(content.html || '');
      } else {
        timeSum = timeSum < TIMES_CONF.MAX_BY_MATCH ? timeSum + TIMES_CONF.DEFAULT : TIMES_CONF.DEFAULT;
        setTimeout(() => {
          this.lastResponseContentObservable.next(message.content.slice(0, currentIndex));
          postEmitted();
        }, timeSum);
        currentIndex += 1;
      }
    };
    emitContent(message.content[currentIndex]);
  }

  private setConversationUrl(conversationId: string) {
    const clearConversationId = conversationId?.trim() || '';
    this.router.navigate([`chat${conversationId ? `/${clearConversationId}` : ''}`]);
  }

  private setConversation(conversation: Conversation) {
    if (!conversation || !conversation._id) {
      this.conversationId = '';
      return;
    }
    if (this.conversation._id !== conversation._id) {
      this.clearUrl();
      this.setConversationUrl(conversation._id);
    }
    this.conversation = conversation;
    this.conversationId = conversation._id;
    this.getConversationMessages(conversation);
  }

  private showLoading(message: any) {
    this.isChatLoading = true;
    this.loadingMessage = message;
  }

  private hideLoading() {
    this.isChatLoading = false;
    this.loadingMessage = undefined;
  }

  private clearUrl() {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }

  private generateChatTitle(returnDefault = false) {
    const mustGenerateTitle = config.chat.MUST_AUTOGENERATE_TITLE && !returnDefault && this.tempText;
    if (mustGenerateTitle) {
      const isTitleTooLong = this.tempText.length > config.chat.TITTLE_MAX_LENGTH;
      return isTitleTooLong ? `${this.tempText.substring(0, 20)}...` : this.tempText;
    }
    return this.translateService.instant('CHAT.DEFAULT_TITLE');
  }

  private forceChatListReload() {
    this.conversationId = '';
    setTimeout(() => {
      this.conversationId = this.conversation._id;
    }, 100);
  }

  private updateConversationTitle(title?:string) {
    let newTitle = title;
    if (!newTitle) {
      if (!config.chat.MUST_AUTOGENERATE_TITLE) {
        return;
      }
      const hasDefaultTitle = this.conversation.title === this.generateChatTitle(true);
      if (hasDefaultTitle) {
        newTitle = this.generateChatTitle();
      }
    }
    if (newTitle) {
      this.apiService.updateConversationTitle(this.conversationId, newTitle).pipe().subscribe(
        (response: any) => {
          this.conversation = response;
          this.forceChatListReload();
        },
      );
    }
  }

  static scrollMessagesToBottom() {
    setTimeout(() => {
      const element = document.getElementById('messages-container');
      if (element) element.scrollTop = element.scrollHeight;
    }, 200);
  }

  onSourceSelected(event: any) {
    this.selectedPdfSource = event;
    this.showPdfViewer = true;
  }

  private setSuggestions() {
    this.chatService.getFollowUpById(this.conversationId);
  }

  get followUpList() {
    return this.chatService.getFollowUpList();
  }

  /* eslint-disable class-methods-use-this */
  scrollToBottom(isLast: boolean) {
    setTimeout(() => {
      if (isLast) {
        const element = document.getElementById('last-message');
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  }

  onMessagesContainerScroll(event: Event) {
    this.chatService.containerScroll$.next(event);
  }
}
