import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { ComponentsModule } from '~components/components.module';
import { ChatModule } from '~pages/chat/chat.module';
import { LoginModule } from '~pages/login/login.module';
import { SignupModule } from '~pages/signup/signup.module';
import { PipesModule } from '~pipes/pipes.module';
import { DataService } from '~services/data/data.service';
import { httpInterceptorProviders } from '~services/interceptors';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { jwtOptionsFactory } from './factories/jwt-options.factory';
import { AnalyticsModule } from './pages/analytics/analytics.module';
import { ChatLogModule } from './pages/chat-log/chat-log.module';
import { ComplianceModule } from './pages/compliance/compliance.module';
import { CustomStylesModule } from './pages/custom-styles/custom-styles.module';
import { DocumentsModule } from './pages/documents/documents.module';
import { FaqsModule } from './pages/faqs/faqs.module';
import { ProductsModule } from './pages/products/products.module';
import { UsersModule } from './pages/users/users.module';

registerLocaleData(localeES);
registerLocaleData(localeEn);
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    DocumentsModule,
    LoginModule,
    ProductsModule,
    FaqsModule,
    UsersModule,
    ChatLogModule,
    ComplianceModule,
    AnalyticsModule,
    SignupModule,
    CustomStylesModule,
    ChatModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [DataService],
      },
    }),
    AngularSvgIconModule.forRoot(),
    ComponentsModule,
    PipesModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        translate.setDefaultLang('es');
        translate.use('es');
        return translate.currentLang || 'Es';
      },
      deps: [TranslateService],
    },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'MMM d, y' } },
    httpInterceptorProviders,
    ComponentsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
