<div
  class="weights-list-container"
  [ngClass]="{ 'is-animated': isAnimated }">
  <div
    class="weights-list-item"
    *ngFor="let weight of weightsData">
    <div class="weights-list-item-label">{{ weight.label }}</div>
    <p-progressBar
      [showValue]="false"
      [value]="weight.value | convertPercentage"></p-progressBar>
    <div class="weights-list-item-value">{{ weight.value }}%</div>
  </div>
</div>
