<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.FAQ.TITLE" | translate }}
    </ng-container>
    <ng-container side>
      <button
        pButton
        class="user-nav-btn p-button-rounded p-button-sm large-btn"
        (click)="createNewQA()">
        {{ "ADMIN.FAQ.ADD" | translate }}
      </button>
    </ng-container>
    <ng-container content>
      <p-card class="card-container">
        <div class="introducction">
          {{ "ADMIN.FAQ.DESCRIPTION" | translate }}
        </div>
        <div class="">
          <span
            class="p-input-icon-left document-searcher"
            [ngClass]="{ 'p-input-icon-right': searchLoading }">
            <i class="pi pi-search"></i>
            <input
              pInputText
              class="search-table-input"
              type="text"
              [(ngModel)]="faqFilter.searchString"
              (ngModelChange)="onSearch()"
              [placeholder]="'ADMIN.FAQ.SEARCH_DOCUMENTS' | translate" />
            <i
              *ngIf="searchLoading"
              class="pi pi-spin pi-spinner"></i>
          </span>
        </div>
        <div class="table">
          <p-table
            [lazy]="true"
            [loading]="loading"
            [value]="faqs"
            [tableStyle]="{ 'min-width': '60rem' }"
            [paginator]="true"
            [rows]="faqFilter.limit"
            [totalRecords]="totalRecords"
            (onLazyLoad)="onLazyLoadFaqs($event)"
            [(first)]="page">
            <ng-template pTemplate="header">
              <tr>
                <th
                  pSortableColumn="question"
                  class="column-question">
                  {{ "ADMIN.FAQ.QUESTION" | translate }}
                  <p-sortIcon field="question"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="answer"
                  class="column-answer">
                  {{ "ADMIN.FAQ.ANSWER" | translate }}
                  <p-sortIcon field="answer"></p-sortIcon>
                </th>

                <th class="column-settings"></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-qa>
              <tr>
                <td class="td-name">
                  {{ qa.question }}
                </td>
                <td class="td-answer">
                  {{ qa.answer }}
                </td>
                <td class="options-btn">
                  <p-overlayPanel
                    #op
                    [style]="{ width: '125px' }">
                    <ng-template pTemplate="content">
                      <div
                        class="options-container"
                        (click)="onEditDocument(qa)">
                        <span> {{ "TABLE.OPTIONS.EDIT" | translate }} </span>
                      </div>
                      <div
                        class="options-container"
                        (click)="onRemoveDocument(qa)">
                        <span> {{ "TABLE.OPTIONS.REMOVE" | translate }} </span>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                  <button
                    pButton
                    class="lang-nav-btn p-button-text p-button-sm settings-btn ellipsis-settings-btn"
                    (click)="op.toggle($event)">
                    <i class="pi pi-ellipsis-v"></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </ng-container>
  </gpta-admin-container>

  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
</div>

<gpta-manage-faqs-modal
  [(visible)]="showManageUserFaq"
  [faq]="editFaq"
  (visibleChange)="clearFaq()"></gpta-manage-faqs-modal>
