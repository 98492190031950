import { Component, Input } from '@angular/core';

import { ChartWeight } from '~app/models/chat-weight';

@Component({
  selector: 'gpta-chat-weights-list',
  templateUrl: './chat-weights-list.component.html',
  styleUrls: ['./chat-weights-list.component.scss'],
})
export class ChatWeightsListComponent {
  @Input() weightsData: ChartWeight[] | undefined;

  @Input() isAnimated = false;
}
