<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.COMPLIANCE.TITLE" | translate }}
    </ng-container>
    <ng-container content>
      <p-card class="compliance-card">
        <div class="compliance-content">
          <div class="p-d-flex compliance-card-header">
            <div class="compliance-subtitle">
              <p>
                {{ "ADMIN.COMPLIANCE.SUBTITLE.TEXT_1" | translate }}
              </p>
              <p>
                {{ "ADMIN.COMPLIANCE.SUBTITLE.TEXT_2" | translate }}
              </p>
            </div>
          </div>
          <div class="compliance-content-container">
            <div *ngFor="let item of disclaimers">
              <div class="card">
                <h4 class="bold">{{ "ADMIN.COMPLIANCE.SECTIONS." + item.type + ".TITLE" | translate }}</h4>
                <p>{{ "ADMIN.COMPLIANCE.SECTIONS." + item.type + ".TEXT" | translate }}</p>
                <span
                  class="p-input-icon-left p-ml-auto search-product"
                  style="width: 100%">
                  <textarea
                    rows="5"
                    cols="30"
                    class="form-input"
                    pInputTextarea
                    [autoResize]="true"
                    style="width: 100%"
                    [(ngModel)]="item.content"
                    [ngClass]="{ 'input-error': errorResponse[item._id] }"
                    [disabled]="savingState[item._id]"
                    (ngModelChange)="onContentChange(item._id)"></textarea>
                </span>
                <button
                  pButton
                  class="user-nav-btn p-button-rounded p-button-sm"
                  style="margin-top: 15px"
                  [disabled]="!isContentChanged(item._id) || savingState[item._id]"
                  (click)="saveDisclaimer(item._id, item.content)">
                  {{ "ADMIN.COMPLIANCE.SAVE" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </ng-container>
  </gpta-admin-container>
  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
</div>
