import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { Disclaimer } from '~app/models/disclaimer';
import { LangSelect } from '~app/models/lang';
import { ApiService } from '~app/services/api/api.service';
import { ComplianceService } from '~app/services/compliance/compliance.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss'],
})
export class ComplianceComponent implements OnInit, OnDestroy {
  public disclaimers$: Observable<any>;

  public disclaimers: Disclaimer[] = [];

  private langChangeSubscription: Subscription;

  public langSelected: LangSelect | undefined;

  public loading = false;

  public savingState: { [key: string]: boolean } = {};

  public contentChanged: { [key: string]: boolean } = {};

  public errorResponse: { [key: string]: boolean } = {};

  constructor(
    private complianceService: ComplianceService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.langSelected = {
      label: this.translateService.currentLang,
      code: this.translateService.currentLang,
    };
    this.getComplianceList();
    this.disclaimers$ = this.complianceService.disclaimerList$;
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langSelected = {
        label: event.lang,
        code: event.lang,
      };
      this.getComplianceList();
    });
  }

  ngOnInit(): void {
    this.disclaimers$.subscribe((res: any) => {
      if (res?.disclaimers) this.disclaimers = res.disclaimers;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  setComplianceLang() {
    this.getComplianceList();
  }

  getComplianceList(): void {
    this.loading = true;
    this.complianceService.getComplianceList(this.langSelected?.code);
  }

  onContentChange(itemId: string) {
    this.contentChanged[itemId] = true;
  }

  isContentChanged(itemId: string): boolean {
    return this.contentChanged[itemId];
  }

  saveDisclaimer(id: string, content: string) {
    this.savingState[id] = true;
    this.contentChanged[id] = false;
    this.errorResponse[id] = false;
    this.complianceService.updateDisclaimer(id, { content })
      .subscribe(
        {
          next: (res) => {
            // eslint-disable-next-line no-underscore-dangle
            const index = this.disclaimers.findIndex((disclaimer) => disclaimer._id === res._id);
            this.disclaimers[index] = res;
            if (res.type === 'GENERAL') {
              this.apiService.setWelcomeDisclaimers([res.content]);
            }
          },
          error: () => {
            this.contentChanged[id] = true;
            this.errorResponse[id] = true;
          },
          complete: () => {
            this.savingState[id] = false;
          },
        },
      );
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.apiService.setNextConversation({});
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate(['/chat', conversation._id]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
