import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  debounceTime,
  filter,
  Observable,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

import { ChatService } from '~app/services/chat/chat.service';
import config from '~config/constants';
import { hostConfig } from '~config/host-confs';
import {
  Chart, ChartData,
  ChartOptions,
  CurrentTooltip,
  LegendItem,
} from '~models/chart';
import WindowProperties from '~models/window-prop';
import { WindowSizeHelper } from '~services/helpers/screen-size.helper';

@Component({
  selector: 'gpta-chat-chart',
  templateUrl: './chat-chart.component.html',
  styleUrls: ['./chat-chart.component.scss'],
})
export class ChatChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() chartData: Chart | undefined;

  @Input() isAnimated = false;

  themedChartData: Chart | undefined;

  legendItems: LegendItem[] | undefined;

  windowProperties: Observable<WindowProperties>;

  @ViewChild('chart') myChart: any;

  @ViewChild('chartTooltipsContainer', { static: false }) chartTooltipsContainer!: ElementRef<HTMLDivElement>;

  currencyCode = config.chat.CURRENCY.EUR;

  currentTooltip?: CurrentTooltip;

  private showTooltip$ = new Subject<Event | undefined>();

  private destroy$ = new Subject<void>();

  constructor(
    private windowSizeHelper: WindowSizeHelper,
    private translateService: TranslateService,
    private chatService: ChatService,
  ) {
    this.windowProperties = this.windowSizeHelper.getWindowProperties();
  }

  ngOnInit() {
    this.showTooltip$.pipe(
      debounceTime(50),
      takeUntil(this.destroy$),
      tap(() => this.deleteTooltips()),
      filter((event) => !!event),
      tap((event) => this.showTooltip(event)),
    ).subscribe();

    this.chatService.containerScroll$.pipe(
      takeUntil(this.destroy$),
      tap(() => this.showTooltip$.next(undefined)),
    ).subscribe();
  }

  ngAfterViewInit() {
    this.windowProperties.pipe(
      debounceTime(100),
      takeUntil(this.destroy$),
    ).subscribe((windowProperties: WindowProperties) => {
      if (this.chartData) {
        this.applyThemeData(this.chartData, windowProperties.isMobile);
      }
    });
  }

  ngOnDestroy() {
    this.deleteTooltips();
    this.destroy$.next();
    this.destroy$.complete();
  }

  static applyChartOptions(chartData: Chart, options: ChartOptions): Chart {
    const newChartData = JSON.parse(JSON.stringify(chartData));
    newChartData.options = options;
    return newChartData;
  }

  private parseChartValue(value: ChartData): string {
    if (this.chartData?.type === 'doughnut') {
      return typeof value === 'object' ? `${value.value} ${value.sufix}` : `${value}`;
    }
    return `${value}`;
  }

  private cretateChartLegendItems(): void {
    this.legendItems = this.chartData?.data?.datasets[0]?.data.map(
      (value: ChartData, index: number):LegendItem => {
        const label = this.chartData?.data?.labels[index] || '';
        const backgroundColor = this.chartData?.data?.datasets[0].backgroundColor[index] || '';
        return {
          label,
          backgroundColor,
          value,
          formattedValue: this.parseChartValue(value),
        };
      },
      [],
    );
  }

  private applyThemeData(chartData: Chart, isMobile?: boolean): void {
    if (chartData.type === 'line') {
      const chartOptions = {
        ...hostConfig.chat.chart.line.options,
        ...(isMobile ? hostConfig.chat.chart.line.mobileOptions : {}),
        onHover: (event:any) => {
          this.showTooltip$.next(event);
        },
        plugins: {
          ...hostConfig.chat.chart.line.options.plugins,
          interpolate: {
            mode: 'x',
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            onHover: () => {
              this.deleteTooltips();
            },
            ...(chartData.options?.['plugins'] as { legend?: any } | undefined)?.legend || {},
            labels: {
              ...(chartData.options?.['plugins'] as { legend?: { labels?: any } } | undefined)?.legend?.labels || {},
              usePointStyle: false,
              pointStyle: 'circle',
              pointStyleWidth: 5,
              useBorderRadius: true,
              borderRadius: 1,
              boxWidth: 3,
              boxHeight: 3,
            },
          },
        },
      };
      this.themedChartData = ChatChartComponent.applyChartOptions(chartData, chartOptions);
    } else if (chartData.type === 'doughnut') {
      this.cretateChartLegendItems();
      this.themedChartData = ChatChartComponent.applyChartOptions(chartData, {
        ...hostConfig.chat.chart.doughnut.options,
        ...chartData.options,
        plugins: {
          ...hostConfig.chat.chart.doughnut.options.plugins,
          tooltip: {
            callbacks: {
              label: (tooltipItem: { label: string; raw: ChartData }) => ` ${this.parseChartValue(tooltipItem.raw)}`,
            },
          },
        },
      });
    } else {
      this.themedChartData = chartData;
    }
  }

  deleteTooltips() {
    this.currentTooltip = undefined;
  }

  private showTooltip(event: any) {
    const activePoints = this.myChart?.chart.getElementsAtEventForMode(event, 'nearest', { intersect: false }, true);

    if (activePoints.length > 0) {
      const index = activePoints?.[0].index || 0;
      const xPosition = activePoints?.[0].element.x || 0;

      const date = new Date(this.chartData?.data?.dates?.[index] || '')
        .toLocaleDateString(this.translateService.currentLang, {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });

      const tooltip: CurrentTooltip = {
        date,
        xPosition,
        points: this.myChart.data.datasets.map((dataset: any, datasetsIndex: number) => {
          const value = dataset.data[index];
          const pixel = this.myChart.chart.scales.y.getPixelForValue(value);

          const isEven = datasetsIndex % 2 === 0;
          const classNames = [isEven ? 'tooltip-even' : 'tooltip-odd'];

          let maxWidth = 180;
          if (isEven && event.native.clientX < maxWidth) {
            classNames.push('tooltip-axis-limit');
            maxWidth = event.native.clientX - 20;
          } else if (!isEven && (window.innerWidth - event.native.clientX) < maxWidth) {
            classNames.push('tooltip-axis-limit');
            maxWidth = window.innerWidth - event.native.clientX - 20;
          }

          return {
            isEven,
            label: dataset.label,
            value,
            color: dataset.backgroundColor,
            yPointPosition: pixel,
            yCardPosition: pixel,
            class: classNames,
            maxWidth,
          };
        }),
      };

      this.currentTooltip = this.fixTooltipsXPositions(tooltip);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private fixTooltipsXPositions(tooltip: CurrentTooltip): CurrentTooltip {
    return {
      ...tooltip,
      points: tooltip.points
        .sort((a, b) => a.yCardPosition - b.yCardPosition)
        .map((point, index, points) => {
          if (index > 0) {
            const sameTypeItems = points.slice(0, index).reverse().find((el) => el.isEven === point.isEven);
            if (sameTypeItems) {
              const distance = point.yCardPosition - sameTypeItems.yCardPosition;
              if (distance < 50) {
                // eslint-disable-next-line no-param-reassign
                point.yCardPosition = sameTypeItems.yCardPosition + 50;
                // eslint-disable-next-line no-param-reassign
                point.class.push('tooltip-stacked');
              }
            }
          }
          return point;
        }),
    };
  }
}
