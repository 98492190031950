<p-dialog
  [header]="title"
  (onHide)="onClose()"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '70vw' }"
  [draggable]="false"
  [resizable]="false">
  <div class="info-container">
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.SOURCE_MODAL.INFORMATION.USER" | translate }}</div>
      <div class="row-content">{{ chatLog?.user?.name }}</div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.SOURCE_MODAL.INFORMATION.USER_ID" | translate }}</div>
      <div class="row-content">{{ chatLog?.user?.reference }}</div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.SOURCE_MODAL.INFORMATION.DATE" | translate }}</div>
      <div class="row-content">{{ chatLog?.questionMessage?.creationDate | date: "HH:mm, dd/MM/yyyy" }}</div>
    </div>
    <div class="row">
      <div class="row-title">{{ "ADMIN.CHAT_LOG.SOURCE_MODAL.INFORMATION.SOURCES" | translate }}</div>
      <div
        class="row-content-container"
        *ngIf="chatLog?.answerMessage as message">
        <div *ngIf="message.metadata && message.metadata['sources']?.length">
          <ul
            class="row-content"
            *ngFor="let source of message.metadata['sources']">
            <li>
              <p>{{ source.name }}{{ source.name && source.filename ? " - " : "" }}{{ source.filename }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      *ngIf="urls.length"
      pButton
      class="download-btn rounded-btn p-button-rounded p-button-outlined p-button-sm"
      [disabled]="!urls.length"
      (click)="downloadSources()">
      {{ "ADMIN.CHAT_LOG.SOURCE_MODAL.DOWNLOAD_BUTTON" | translate }}
    </button>
  </ng-template>
</p-dialog>
