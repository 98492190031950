<p-panel
  #newsPanel
  [toggleable]="true"
  [collapsed]="true"
  class="news-panel">
  <ng-template pTemplate="header">
    <div class="news-header">
      <button
        class="news-header-btn"
        (click)="toggleNews(newsPanel)">
        {{ "CHAT.NEWS" | translate }}
        <i
          class="pi"
          [ngClass]="showNews ? 'pi-angle-up' : 'pi-angle-down'"></i>
      </button>
    </div>
  </ng-template>
  <div class="news-content">
    <div
      *ngFor="let item of news"
      class="new">
      <a
        [href]="item.url"
        target="_blank"
        class="new-link">
        <div>
          <img
            class="new-image"
            [src]="item.imageUrl"
            alt="item.date" />
        </div>
        <div>
          <h4 class="date light">{{ item.date | localizedDate: "d '" + of + "' MMMM '" + of + "' y" }}</h4>
        </div>
        <div>
          <p class="description bold">{{ item.title }}</p>
        </div>
      </a>
    </div>
  </div>
</p-panel>
