import { Component, Input } from '@angular/core';

import { Card } from '~models/card';

@Component({
  selector: 'gpta-chat-cards',
  templateUrl: './chat-cards.component.html',
  styleUrls: ['./chat-cards.component.scss'],
})
export class ChatCardsComponent {
  @Input() cards: Card[] | undefined;

  @Input() isAnimated = false;
}
