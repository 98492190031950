import html2canvas from 'html2canvas';

import { COPY_CLIPBOARD_RESULT } from '~app/models/copy-clipboard-resul.enum';
import { getEmailText } from '~helpers/send-email.helper';

export interface MessageCopyDataObj {
  isSafari: boolean;
  isHtmlSupported: boolean;
  hasImgs: boolean;
  html: string;
  text: string;
}

function copyToClipBoard(clipboardItem: ClipboardItem): Promise<void> {
  const data = [
    clipboardItem,
  ];
  return navigator.clipboard.write(data);
}

function getElementHtml(element: HTMLElement): string {
  const html = element.innerHTML.replace(/<!--[\s\S]*?(?:-->)/g, '');
  return html;
}

function htmlToBlob(html: string): Blob {
  const blob = new Blob([html], {
    type: 'text/html',
  });
  return blob;
}

function textToBlob(text: string): Blob {
  const blob = new Blob([text], {
    type: 'text/plain',
  });
  return blob;
}

async function graphToImage(captureElement: HTMLElement): Promise<(HTMLImageElement | null)> {
  try {
    const canvasEl = await html2canvas(captureElement);
    const dataURL = canvasEl.toDataURL();
    const image = new Image();
    image.src = dataURL;
    return image;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('html2canvas Error:', error);
    return null;
  }
}

async function getReplaceImages(element: HTMLElement): Promise<(HTMLImageElement | null)[]> {
  const imgElements = element.getElementsByClassName('copy-as-img');
  const replaceImages = [];
  for (let i = 0; i < imgElements.length; i += 1) {
    const imgElement = imgElements[i] as HTMLElement;
    // parseProgressBars(imgElement);
    // eslint-disable-next-line no-await-in-loop
    const image = await graphToImage(imgElement);
    replaceImages.push(image);
  }
  return replaceImages;
}

async function parseElement(element: HTMLElement, parseImgs: boolean): Promise<{
  parsedElement: HTMLElement;
  hasImgs: boolean;
}> {
  const cloneElement = element.cloneNode(true) as HTMLElement;
  const nonCopyElements = cloneElement.getElementsByClassName('avoid-copy');
  Array.from(nonCopyElements).forEach((el) => el.remove());
  let replaceImgs: (HTMLImageElement | null)[] = [];
  if (parseImgs) {
    replaceImgs = await getReplaceImages(element);
    const replaceElements = cloneElement.getElementsByClassName('copy-as-img');
    Array.from(replaceElements).forEach((el, index) => {
      const img = replaceImgs[index];
      if (!img) {
        // el.remove();
        return;
      }
      el.replaceWith(img);
    });
  }
  return {
    parsedElement: cloneElement,
    hasImgs: Boolean(replaceImgs?.length),
  };
}

export function checkCopyHtmlSupported(): boolean {
  return typeof ClipboardItem !== 'undefined';
}

export async function getMessageCopyData(
  element: HTMLElement,
  parseImgs: boolean | null = null,
): Promise<MessageCopyDataObj> {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isHtmlSupported = checkCopyHtmlSupported();
  const shouldParseImgs = parseImgs === null ? isHtmlSupported : parseImgs;

  const {
    hasImgs,
    parsedElement,
  } = await parseElement(element, shouldParseImgs);
  const text = getEmailText(element);
  const html = getElementHtml(parsedElement);
  return {
    isSafari,
    isHtmlSupported,
    hasImgs,
    html,
    text,
  };
}
export async function copyMessageData(copyDataObj: MessageCopyDataObj): Promise<COPY_CLIPBOARD_RESULT> {
  try {
    if (copyDataObj.isHtmlSupported) {
      const htmlBlob = htmlToBlob(copyDataObj.html);
      const textBlob = textToBlob(copyDataObj.text);
      const clipboardItem = new ClipboardItem({
        'text/plain': textBlob,
        'text/html': htmlBlob,
      });

      await copyToClipBoard(clipboardItem);
      return COPY_CLIPBOARD_RESULT.SUCCESS;
    }
    await navigator.clipboard.writeText(copyDataObj.text);
    return COPY_CLIPBOARD_RESULT.ONLY_TEXT_SUPPORTED;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Clipboard Error:', error);
    return COPY_CLIPBOARD_RESULT.ERROR;
  }
}
export async function copyMessage(element: HTMLElement): Promise<COPY_CLIPBOARD_RESULT> {
  const copyDataObj = await getMessageCopyData(element);
  const copyResult = await copyMessageData(copyDataObj);
  return copyResult;
}
