import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateLength',
})
export class TruncateLengthPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string | undefined, length: number): string | undefined {
    if (value && value.length > length) {
      return `${value?.substring(0, length)}...`;
    }
    return value;
  }
}
