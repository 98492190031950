<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.STYLES.TITLE" | translate }}
    </ng-container>
    <ng-container content>
      <p-card class="styles-card">
        <p-tabView class="tab-element">
          <p-tabPanel [header]="'ADMIN.STYLES.TABS.COLOURS.TITLE' | translate">
            <div class="colors-container">
              <h2 class="colors-title">
                {{ "ADMIN.STYLES.TABS.COLOURS.TITLE" | translate }}
              </h2>
              <div class="colors-content">
                <ng-container *ngFor="let item of stylesCustomColors; let idx = index">
                  <div
                    class="color-box"
                    *ngIf="item.editable">
                    <h5 class="color-title bold">
                      {{ "ADMIN.STYLES.TABS.COLOURS." + getTextKey(item.name) | translate }}
                    </h5>
                    <gpta-color-picker
                      [id]="idx"
                      [color]="item"
                      [colorsList]="stylesCustomColors"
                      (colorChange)="colorChange($event)"
                      [placeHolder]="'ADMIN.STYLES.TABS.COLOURS.' + getTextKey(item.name) | translate" />
                  </div>
                </ng-container>
              </div>
              <div>
                <button
                  pButton
                  class="p-button-rounded p-button-sm large-btn"
                  (click)="onSaveColous()">
                  {{ "ADMIN.STYLES.SAVE" | translate }}
                </button>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel [header]="'ADMIN.STYLES.TABS.IMAGES.TITLE' | translate">
            <div class="images-container">
              <h2 class="images-title">
                {{ "ADMIN.STYLES.TABS.IMAGES.TITLE" | translate }}
              </h2>
              <div class="images-content">
                <ng-container *ngFor="let item of stylesCustomImages; let idx = index">
                  <div class="image-box">
                    <h5 class="image-title bold">
                      {{ "ADMIN.STYLES.TABS.IMAGES." + getTextKey(item.name) + ".TITLE" | translate }}
                    </h5>
                    <p class="image-sub-title">
                      {{ "ADMIN.STYLES.TABS.IMAGES." + getTextKey(item.name) + ".DESCRIPTION" | translate }}
                    </p>
                    <div class="file-picker">
                      <img
                        *ngIf="item.value"
                        class="image-preview"
                        [src]="item.value"
                        alt="logo" />
                      <label
                        pButton
                        [for]="'file' + idx"
                        class="p-button-rounded p-button-outlined p-button-sm normal-btn image-btn">
                        {{ "ADMIN.STYLES.TABS.IMAGES.SELECT_IMAGE" | translate }}
                      </label>
                      <input
                        type="file"
                        [id]="'file' + idx"
                        style="display: none"
                        class="p-button-rounded p-button-outlined p-button-sm large-btn"
                        (change)="onFileSelected($event, item)" />
                    </div>
                  </div>
                </ng-container>
              </div>
              <div>
                <button
                  pButton
                  class="p-button-rounded p-button-sm large-btn"
                  (click)="onSaveImages()">
                  {{ "ADMIN.COMPLIANCE.SAVE" | translate }}
                </button>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel [header]="'ADMIN.STYLES.TABS.TEXT.TITLE' | translate">
            <div class="text-container">
              <h2 class="text-title">
                {{ "ADMIN.STYLES.TABS.TEXT.TITLE" | translate }}
              </h2>
              <div class="text-content">
                <div class="text-box">
                  <h5 class="text-title bold">{{ "ADMIN.STYLES.TABS.TEXT.WELCOME_MESSAGE" | translate }}</h5>
                  <p class="text-sub-title">{{ "ADMIN.STYLES.TABS.TEXT.DESCRIPTION" | translate }}</p>
                  <div class="text-input">
                    <textarea
                      rows="5"
                      cols="30"
                      class="form-input"
                      pInputTextarea
                      [autoResize]="true"
                      style="width: 100%; min-height: 134px"
                      [(ngModel)]="welcomeMessage"></textarea>
                  </div>
                </div>
              </div>
              <div>
                <button
                  pButton
                  class="p-button-rounded p-button-sm large-btn"
                  (click)="onSavewelcomeMessage()">
                  {{ "ADMIN.COMPLIANCE.SAVE" | translate }}
                </button>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </p-card>
    </ng-container>
  </gpta-admin-container>
  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
</div>
