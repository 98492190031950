import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Table } from '~models/table';
import WindowProperties from '~models/window-prop';
import { WindowSizeHelper } from '~services/helpers/screen-size.helper';

@Component({
  selector: 'gpta-chat-table',
  templateUrl: './chat-table.component.html',
  styleUrls: ['./chat-table.component.scss'],
})
export class ChatTableComponent {
  @Input() tableData: Table | undefined;

  public windowProperties: Observable<WindowProperties>;

  constructor(private windowSizeHelper: WindowSizeHelper) {
    this.windowProperties = this.windowSizeHelper.getWindowProperties();
  }
}
