import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ComponentAction } from '~app/models/component-action';
import { ProductCard } from '~app/models/productCard';
import { createDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-chat-info-card',
  templateUrl: './chat-info-card.component.html',
  styleUrls: ['./chat-info-card.component.scss'],
})
export class ChatInfoCardComponent {
  @Input() data: ProductCard = {} as ProductCard;

  @Output() optionSelected = new EventEmitter();

  text = '';

  constructor(private translateService: TranslateService) {
    this.translateService.stream('CHAT.CARD_INFO.MORE_INFO')
      .subscribe((res: any) => {
        this.text = res;
      });
  }

  submitOption(optionText: string, isin: string) {
    const action: ComponentAction = createDoAction({
      name: 'optionSelected',
      data: {
        optionText: `${this.text} ${optionText} (ISIN: ${isin})`,
      },
    });
    this.optionSelected.next(action);
  }
}
