import {
  AfterViewInit, Component, EventEmitter, Input, OnChanges, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ChatLog } from '~app/models/chat-log';
import { MESSAGE_CONTENT_TYPES } from '~app/models/message.enum';

@Component({
  selector: 'gpta-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
})
export class ChatModalComponent implements OnChanges, AfterViewInit {
  @Input() visible = false;

  @Input() chatLog: ChatLog | undefined;

  @Output() visibleChange = new EventEmitter<boolean>();

  public title = '';

  public MESSAGE_CONTENT_TYPES = MESSAGE_CONTENT_TYPES;

  constructor(private translateService: TranslateService) {

  }

  ngAfterViewInit(): void {
    this.title = this.translateService.instant('ADMIN.CHAT_LOG.CHAT_MODAL.TITLE');
  }

  ngOnChanges(): void {
    this.title = this.translateService.instant('ADMIN.CHAT_LOG.CHAT_MODAL.TITLE');
  }

  onClose(): void {
    this.visibleChange.emit(false);
  }
}
