import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Conversation } from '~app/models/conversation';
import config from '~config/constants';
import { createCancelAction, createDoAction } from '~helpers/componentAction.helper';

@Component({
  selector: 'gpta-chat-rename-form',
  templateUrl: './chat-rename-form.component.html',
  styleUrls: ['./chat-rename-form.component.scss'],
})
export class ChatRenameFormComponent implements OnInit {
  @Input() chat: Conversation | null = null;

  @Input() isLoading = false;

  @Output() doAction = new EventEmitter();

  formGroup!: FormGroup;

  constructor() {
    this.formGroup = new FormGroup({
      title: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(config.chat.TITTLE_MIN_LENGTH),
        Validators.maxLength(config.chat.TITTLE_MAX_LENGTH),
      ]),
    });
  }

  ngOnInit() {
    if (this.chat) {
      this.formGroup.patchValue({
        title: this.chat.title,
      });
    }
  }

  cancel() {
    this.doAction.emit(createCancelAction({}));
  }

  rename() {
    this.doAction.emit(createDoAction({
      name: 'rename',
      data: {
        title: this.formGroup.value.title,
        chat: this.chat,
      },
    }));
  }
}
