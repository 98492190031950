import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import config from '~config/constants';
import { environment } from '~environments/environment';
import { DataService } from '~services/data/data.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  TKN_KEY :string;

  APP_API_URL :string;

  constructor(private dataService:DataService) {
    this.TKN_KEY = config.dataKeys.TKN_KEY;
    this.APP_API_URL = environment.APP_API_URL;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(this.APP_API_URL)) {
      return this.addToken(request).pipe(
        mergeMap((req:HttpRequest<any>) => next.handle(req)),
      );
    }
    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    const token = this.dataService.getValue(this.TKN_KEY);
    let finalRequest = request;
    if (token) {
      finalRequest = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return of(finalRequest);
  }
}
