<p-sidebar
  class="side-bar"
  [(visible)]="isVisible"
  [style]="{ width: '400px', 'max-width': '100vw' }">
  <ng-template pTemplate="header">
    <h3>
      <svg-icon src="../../../../assets/images/chat_history.svg"></svg-icon> {{ "CHAT.SIDE_BAR.TITLE" | translate }}
    </h3>
    <button
      pButton
      class="new-chat-menu-btn rounded-btn p-button-rounded p-button-outlined p-button-sm"
      (click)="newChatHandler()">
      {{ "CHAT.SIDE_BAR.NEW_CHAT" | translate }}
    </button>
  </ng-template>
  <gpta-chat-list
    *ngIf="isVisible"
    [currentChatId]="currentChatId"
    [openFavs]="openFavs"
    (chatChanged)="chatChangedHandler($event)"></gpta-chat-list>
</p-sidebar>
<ng-container *ngIf="(windowProperties | async)?.isMobile; else defaultMenu">
  <div>
    <button
      pButton
      [loading]="isLoading"
      class="rounded-btn p-button-rounded p-button-outlined p-button-sm menu-burger-btn"
      (click)="menu.toggle($event)">
      <i class="icon pi pi-bars"></i>
    </button>
    <p-menu
      #menu
      [popup]="true"
      [model]="menuItems"
      [hideTransitionOptions]="'0ms'"></p-menu></div
></ng-container>

<ng-template #defaultMenu>
  <button
    pButton
    [loading]="isLoading"
    pTooltip="{{ 'CHAT.FLOATING_MENU.NEW_CHAT' | translate }}"
    [tooltipDisabled]="hideTooltips"
    class="new-chat-btn menu-btn rounded-btn p-button-rounded p-button-outlined p-button-sm"
    (click)="newChatHandler()">
    <i class="icon pi pi-plus"></i>
    <span>{{ "CHAT.FLOATING_MENU.NEW_CHAT" | translate }}</span>
  </button>
  <button
    pButton
    [loading]="isLoading"
    pTooltip="{{ 'CHAT.FLOATING_MENU.OPEN_ARCHIVE' | translate }}"
    [tooltipDisabled]="hideTooltips"
    class="show-menu-btn menu-btn rounded-btn p-button-rounded p-button-outlined p-button-sm"
    (click)="isVisible = true; openFavs = false">
    <svg-icon src="../../../../assets/images/chat_history.svg"></svg-icon>
    <span>{{ "CHAT.FLOATING_MENU.OPEN_ARCHIVE" | translate }}</span>
  </button>
  <button
    pButton
    [loading]="isLoading"
    pTooltip="{{ 'CHAT.FLOATING_MENU.OPEN_FAVORITES' | translate }}"
    [tooltipDisabled]="hideTooltips"
    class="show-favs-btn menu-btn rounded-btn p-button-rounded p-button-outlined p-button-sm"
    (click)="isVisible = true; openFavs = true">
    <i class="icon pi pi-star-fill"></i>
    <span>{{ "CHAT.FLOATING_MENU.OPEN_FAVORITES" | translate }}</span>
  </button>
</ng-template>
