import { Pipe, PipeTransform } from '@angular/core';

import { MESSAGE_CONTENT_TYPES } from '~app/models/message.enum';
import { Message } from '~models/message';
import { SENDER_TYPE } from '~models/sender.enum';

@Pipe({
  name: 'isCopyableMsg',
})
export class IsCopyableMsgPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(message: Message): boolean {
    if (message.senderType !== SENDER_TYPE.AI) {
      return false;
    }
    if (message.loading) {
      return false;
    }
    if (message.hasError) {
      return false;
    }
    const hasSelectors = message.content.filter(
      (content) => content.type === MESSAGE_CONTENT_TYPES.SELECTOR,
    ).length > 0;
    return !hasSelectors;
  }
}
