import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Observable } from 'rxjs';

import {
  AccumulatedAnaytic, AnalyticsDto, AnayticsResponse, UserAnaytic,
} from '~app/models/analytics';
import { ComponentAction } from '~app/models/component-action';
import { Conversation } from '~app/models/conversation';
import { AnalyticsService } from '~app/services/api/analytics/analytics.service';
import { ApiService } from '~app/services/api/api.service';
import { isDoAction } from '~app/services/helpers/componentAction.helper';

@Component({
  selector: 'gpta-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  public dateInit: Date = new Date(new Date().setDate(new Date().getDate() - 1));

  public dateEnd: Date = new Date();

  public analyticsObservable$: Observable<AnayticsResponse>;

  public userAnalytics: UserAnaytic[] = [];

  public accumulatedAnalytics: AccumulatedAnaytic = {} as AccumulatedAnaytic;

  public page = 0;

  public total = 0;

  public loading = false;

  public filters: AnalyticsDto = {
    skip: 0,
    limit: 10,
    dateFrom: this.dateInit.toISOString(),
    dateTo: this.dateEnd.toISOString(),
  };

  public sortType: any[] = [{ label: '', value: 1 }, { label: '', value: -1 }];

  constructor(
    private apiService: ApiService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.analyticsObservable$ = this.analyticsService.analytics$;
    this.translateService.stream('ADMIN.ANALYTICS.SORT_ASC')
      .subscribe((res: any) => {
        this.sortType[0].label = res;
      });
    this.translateService.stream('ADMIN.ANALYTICS.SORT_DESC')
      .subscribe((res: any) => {
        this.sortType[1].label = res;
      });
  }

  ngOnInit(): void {
    this.analyticsObservable$.subscribe((res: AnayticsResponse) => {
      if (!res) return;
      this.userAnalytics = res.data;
      this.accumulatedAnalytics = res.accumulated;
      this.total = res.total ?? 10;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  onFilter(): void {
    this.page = 0;
    this.filters = {
      ...this.filters,
      skip: 0,
      sortField: '',
      sortOrder: 1,
      dateFrom: this.dateInit.toISOString(),
      dateTo: this.dateEnd.toISOString(),
    };
    this.getAnalytics();
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.filters.skip = event.first || 0;
    if (event.sortField) {
      this.filters.sortField = event.sortField as string;
    }
    if (event.sortOrder) {
      this.filters.sortOrder = event.sortOrder;
    }
    if (event.rows) {
      this.filters.limit = event.rows;
    }
    this.getAnalytics();
  }

  private getAnalytics(): void {
    this.loading = true;
    this.cdr.detectChanges();
    this.analyticsService.getAnalytics(this.filters);
  }

  resetChat(action: any): void {
    if (!isDoAction(action)) return;
    this.router.navigate(['/chat']);
    this.clearUrl();
  }

  onConversationChange(action: ComponentAction): void {
    if (isDoAction(action)) {
      const conversation: Conversation = action?.data?.chat;
      this.apiService.setNextConversation(conversation);
      // eslint-disable-next-line no-underscore-dangle
      this.router.navigate([`/chat/${conversation._id}`]);
    }
  }

  private clearUrl(): void {
    const currentUrl = this.activatedRoute?.snapshot?.url;
    const currentPath = currentUrl && currentUrl[0] && currentUrl[0]?.path;
    window.history.replaceState({}, '', currentPath);
  }
}
