<p-dialog
  [header]="title"
  [(visible)]="visible"
  (onHide)="onClose()"
  [modal]="true"
  [style]="{ width: '527px' }"
  [draggable]="false"
  [resizable]="false"
  class="dialog-content">
  <div class="document-modal">
    <div class="save-document-upload">
      <p-fileUpload
        #fileUpload
        class="file-upload-component"
        [ngClass]="{ active: document !== undefined || fileUpload.files.length, edit: document !== undefined }"
        name="FILE"
        [url]="url + '/api/files/upload'"
        (onUpload)="onUpload(fileUpload)"
        accept=".pdf"
        [maxFileSize]="10000000"
        (onBeforeUpload)="setUpForm($event)">
        <ng-template pTemplate="content">
          <div
            *ngIf="document === undefined && !fileUpload.files.length"
            (click)="fileUpload.choose()"
            class="file-upload-content">
            <i
              class="pi pi-image"
              style="font-size: 2.5rem"></i>
            <p>{{ "ADMIN.DOCUMENTS.MODAL.FILE_UPLOAD.TEXT_1" | translate }}</p>
            <p>{{ "ADMIN.DOCUMENTS.MODAL.FILE_UPLOAD.TEXT_2" | translate }}</p>
          </div>
          <div *ngIf="document !== undefined || fileUpload.files.length">
            <div
              *ngIf="fileUpload.files.length"
              class="files-container">
              <div
                class="file-cols"
                *ngFor="let file of fileUpload.files">
                <div>
                  <i class="pi pi-file-pdf icon"></i>
                </div>
                <div class="file-rows">
                  <div>{{ file.name }}</div>
                  <div>{{ file.size }} KB</div>
                </div>
              </div>
            </div>
            <div class="save-document-form">
              <form
                class="form padding-form"
                [formGroup]="form"
                *ngIf="form">
                <div class="input-form first">
                  <label
                    class="bold"
                    htmlFor="name">
                    {{ "ADMIN.DOCUMENTS.MODAL.FORM.NAME" | translate }}
                  </label>
                  <input
                    class="input-element form-input"
                    pInputText
                    id="name"
                    aria-describedby="username-help"
                    formControlName="name" />
                </div>
                <div class="input-form">
                  <label
                    class="bold"
                    htmlFor="date"
                    >{{ "ADMIN.DOCUMENTS.MODAL.FORM.DATE" | translate }}</label
                  >
                  <p-calendar
                    class="input-element form-input"
                    id="date"
                    appendTo="body"
                    dateFormat="dd/mm/yy"
                    formControlName="date"></p-calendar>
                </div>
                <div class="input-form">
                  <label
                    class="bold"
                    htmlFor="type"
                    >{{ "ADMIN.DOCUMENTS.MODAL.FORM.TYPE" | translate }}</label
                  >
                  <p-dropdown
                    id="type"
                    class="input-element filter-select"
                    [options]="options"
                    formControlName="type"
                    optionLabel="name"
                    [placeholder]="'ADMIN.DOCUMENTS.MODAL.FORM.TYPE' | translate"
                    (onChange)="setValidators()"></p-dropdown>
                </div>
                <div
                  formGroupName="additionalInfo"
                  *ngIf="form.get('type')?.value?.value === 'PRODUCT_INFO'">
                  <div class="input-form">
                    <label
                      class="bold"
                      htmlFor="product"
                      >{{ "ADMIN.DOCUMENTS.MODAL.FORM.PRODUCT" | translate }}</label
                    >
                    <p-multiSelect
                      id="product"
                      class="input-element filter-select"
                      [options]="products"
                      formControlName="product"
                      optionLabel="name"
                      [placeholder]="'ADMIN.DOCUMENTS.MODAL.FORM.PRODUCT' | translate">
                    </p-multiSelect>
                  </div>
                  <div class="input-form">
                    <label
                      class="bold"
                      htmlFor="typeInformation"
                      >{{ "ADMIN.DOCUMENTS.MODAL.FORM.TYPE_INFORMATION" | translate }}</label
                    >
                    <p-dropdown
                      id="typeInformation"
                      class="input-element filter-select"
                      [options]="productsInfo"
                      formControlName="typeInformation"
                      optionLabel="name"
                      [showClear]="true"
                      [placeholder]="'ADMIN.DOCUMENTS.MODAL.FORM.TYPE_INFORMATION' | translate">
                    </p-dropdown>
                  </div>
                </div>
                <div class="input-form">
                  <label
                    class="bold"
                    htmlFor="description"
                    >{{ "ADMIN.DOCUMENTS.MODAL.FORM.DESCRIPTION" | translate }}</label
                  >
                  <textarea
                    id="description"
                    class="input-element form-input"
                    rows="5"
                    cols="30"
                    pInputTextarea
                    formControlName="description">
                  </textarea>
                </div>
                <div class="input-buttons">
                  <button
                    class="p-button-rounded p-button-outlined large-btn"
                    type="button"
                    (click)="fileUpload.clear(); onClose()"
                    pButton>
                    {{ "ADMIN.DOCUMENTS.MODAL.CANCEL" | translate }}
                  </button>
                  <button
                    class="p-button-rounded large-btn"
                    type="button"
                    [disabled]="form.invalid"
                    pButton
                    *ngIf="document !== undefined; else newDocument"
                    (click)="onEdit()">
                    {{ "ADMIN.DOCUMENTS.MODAL.EDIT_DOCUMENT_BUTTON" | translate }}
                  </button>
                  <ng-template #newDocument>
                    <button
                      class="p-button-rounded large-btn"
                      type="button"
                      (click)="fileUpload.upload()"
                      [disabled]="form.invalid"
                      pButton>
                      {{ "ADMIN.DOCUMENTS.MODAL.NEW_DOCUMENT_BUTTON" | translate }}
                    </button>
                  </ng-template>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>
</p-dialog>
