<div>
  <gpta-admin-container>
    <ng-container title>
      {{ "ADMIN.ANALYTICS.TITLE" | translate }}
    </ng-container>
    <ng-container content>
      <div class="analytics-container">
        <div class="filters-container">
          <div class="form-group">
            <label
              for="dateInit"
              class="date-init"
              >{{ "ADMIN.ANALYTICS.FROM" | translate }}</label
            >
            <p-calendar
              [readonlyInput]="true"
              inputId="dateInit"
              [(ngModel)]="dateInit"
              (ngModelChange)="onFilter()"
              dateFormat="dd/mm/yy"></p-calendar>
          </div>
          <div class="form-group">
            <label
              for="dateEnd"
              class="date-end"
              >{{ "ADMIN.ANALYTICS.TO" | translate }}</label
            >
            <p-calendar
              [readonlyInput]="true"
              inputId="dateEnd"
              [(ngModel)]="dateEnd"
              (ngModelChange)="onFilter()"
              dateFormat="dd/mm/yy"></p-calendar>
          </div>
        </div>
        <div class="cards-container">
          <gpta-value-tile-card
            [title]="'ADMIN.ANALYTICS.TILES.USER.TITLE'"
            [total]="(analyticsObservable$ | async)?.accumulated?.users?.total"
            [secondaryValue]="(analyticsObservable$ | async)?.accumulated?.users?.new"
            [secondaryText]="'ADMIN.ANALYTICS.TILES.USER.SECONDARY_TEXT'"
            icon="../../../../assets/images/administration-panel/analytics_user.svg"></gpta-value-tile-card>
          <gpta-value-tile-card
            [title]="'ADMIN.ANALYTICS.TILES.QUESTION.TITLE'"
            [total]="(analyticsObservable$ | async)?.accumulated?.questions?.total"
            [secondaryValue]="(analyticsObservable$ | async)?.accumulated?.questions?.perUser"
            [secondaryText]="'ADMIN.ANALYTICS.TILES.QUESTION.SECONDARY_TEXT'"
            icon="../../../../assets/images/administration-panel/analytics_question.svg"></gpta-value-tile-card>
          <gpta-value-tile-card
            [title]="'ADMIN.ANALYTICS.TILES.CHAT.TITLE'"
            [total]="(analyticsObservable$ | async)?.accumulated?.conversations?.total"
            [secondaryValue]="(analyticsObservable$ | async)?.accumulated?.conversations?.perUser"
            [secondaryText]="'ADMIN.ANALYTICS.TILES.CHAT.SECONDARY_TEXT'"
            icon="../../../../assets/images/administration-panel/analytics_chat.svg"></gpta-value-tile-card>
        </div>
        <div class="table-container">
          <p-card class="analytics-table-card">
            <p-table
              [lazy]="true"
              [loading]="loading"
              [value]="userAnalytics"
              [tableStyle]="{ 'min-width': '60rem' }"
              [paginator]="true"
              [(first)]="page"
              (onLazyLoad)="onLazyLoad($event)"
              [rows]="filters.limit"
              [totalRecords]="total">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="lastConnectionDate">
                    {{ "ADMIN.ANALYTICS.LAST_CONNECTION" | translate }}
                    <p-sortIcon field="lastConnectionDate"></p-sortIcon>
                  </th>
                  <th pSortableColumn="name">
                    {{ "ADMIN.ANALYTICS.NAME" | translate }} <p-sortIcon field="name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="reference">
                    {{ "ADMIN.ANALYTICS.ID" | translate }} <p-sortIcon field="reference"></p-sortIcon>
                  </th>
                  <th pSortableColumn="totalQuestions">
                    {{ "ADMIN.ANALYTICS.QUESTIONS" | translate }} <p-sortIcon field="totalQuestions"></p-sortIcon>
                  </th>
                  <th pSortableColumn="totalConversations">
                    {{ "ADMIN.ANALYTICS.CONVERSATIONS" | translate }}
                    <p-sortIcon field="totalConversations"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-data>
                <tr>
                  <td>{{ data.lastConnectionDate | date: "HH:mm, dd/MM/yyyy" }}</td>
                  <td>{{ data.name }}</td>
                  <td>{{ data.reference }}</td>
                  <td class="number">{{ data.totalQuestions }}</td>
                  <td class="number">{{ data.totalConversations }}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-card>
        </div>
      </div>
    </ng-container>
  </gpta-admin-container>
  <gpta-header-nav [isAdminPage]="true"></gpta-header-nav>
  <gpta-chat-side-menu
    (startNewChat)="resetChat($event)"
    (chatChanged)="onConversationChange($event)"></gpta-chat-side-menu>
</div>
