<p-panel
  #suggestionsPanel
  [toggleable]="true"
  [collapsed]="true"
  class="suggestions-panel">
  <ng-template pTemplate="header">
    <div class="suggestions-header">
      <button
        class="suggestions-header-btn"
        (click)="toggleSuggestions(suggestionsPanel)">
        {{ "CHAT.SUGGESTIONS" | translate }}
        <i
          class="pi"
          [ngClass]="showSuggestions ? 'pi-angle-up' : 'pi-angle-down'"></i>
      </button>
    </div>
  </ng-template>
  <div class="suggestions-content">
    <button
      *ngFor="let suggestion of suggestions"
      class="suggestion"
      (click)="submitSuggestion(suggestion)">
      <svg-icon
        class="svg-image"
        src="./assets/images/double-quotes.svg"></svg-icon>
      {{ suggestion.text }}
    </button>
  </div>
</p-panel>
