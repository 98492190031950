import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DeleteDocumentResponse, OrganizationDocuments, SearchDocumentsDto } from '~app/models/document';
import { environment } from '~environments/environment';

import { buildQuery } from '../helpers/query-builder.helper';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  #url: string;

  documentsListSubject = new BehaviorSubject<any>(null);

  documentsList$ = this.documentsListSubject.asObservable();

  constructor(private http: HttpClient) {
    this.#url = environment.APP_API_URL;
  }

  nextDocumentList(response: any) {
    this.documentsListSubject.next(response);
  }

  getDocumentList(searchDocumentsDto: SearchDocumentsDto) {
    const params: {
      [param: string]: string;
    } = buildQuery(searchDocumentsDto);
    this.http.get<OrganizationDocuments>(`${this.#url}/api/files`, { params }).subscribe((res) => {
      this.nextDocumentList(res);
    });
  }

  createDocument(body: any) {
    return this.http.post<OrganizationDocuments>(`${this.#url}/api/files`, body);
  }

  patchDocumentById(documentId: string, body: any) {
    return this.http.patch<OrganizationDocuments>(`${this.#url}/api/files/${documentId}`, body);
  }

  deleteDocumentById(documentId: string) {
    return this.http.delete<DeleteDocumentResponse>(`${this.#url}/api/files/${documentId}`);
  }

  activeAllFilteredDocuments(searchDocumentsDto: SearchDocumentsDto, areAllActive: boolean) {
    const params: {
      [param: string]: string;
    } = buildQuery({
      searchString: searchDocumentsDto.searchString,
      type: searchDocumentsDto.type,
    });
    const body = {
      setActive: !areAllActive,
    };
    return this.http.patch<OrganizationDocuments>(`${this.#url}/api/files/filtered-status`, body, { params });
  }
}
